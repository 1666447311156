import { ReactComponent as UpdateIcon } from "../../../img/UpdateIcon.svg";
import BaseModal from "../../modals/BaseModal";

export interface RemoteLockUpdateInProgressModalProps {
  closeModalFn: () => void;
}

const RemoteLockUpdateInProgressModal: React.FC<
  RemoteLockUpdateInProgressModalProps
> = ({ closeModalFn }) => {
  return (
    <BaseModal
      actionButtonFn={closeModalFn}
      actionButtonText="OK"
      closeModalFn={closeModalFn}
      headerText="Update In Progress"
    >
      <UpdateIcon title="UpdateIcon" />

      <div className="menu menu-light modal-body-text-container">
        Changes to this unit&#39;s access assignments are still being saved.
        <br />
        <br />
        Once finished, these changes will appear here and in the unit&#39;s
        Brilliant Home.
      </div>
    </BaseModal>
  );
};
export default RemoteLockUpdateInProgressModal;
