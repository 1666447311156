import * as Sentry from "@sentry/browser";
import { AnyAction } from "redux";

import { isSideMenuVisibleVar } from "../../components/apollo/LocalState";

const token = "token";
export const USER_SIGNED_OUT = "USER_SIGNED_OUT";
export const logOutUser = (): AnyAction => {
  window.localStorage.removeItem(token);
  isSideMenuVisibleVar(false);
  Sentry.configureScope((scope) => scope.setUser(null));
  return {
    type: USER_SIGNED_OUT,
  };
};
