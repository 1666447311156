import { Link } from "react-router-dom";

import { ReactComponent as ExternalLink } from "../../img/ExternalLink.svg";

export interface SectionTitleBarButtonProps {
  text: string;
  url: string;
  isExternal: boolean;
}

// This interface is used to help expose properties that should be available for the icon.
interface IconProps {
  title?: string;
}

export interface SectionTitleBarProps {
  icon: React.FC<React.SVGProps<SVGSVGElement> & IconProps>;
  title: string;
  button?: SectionTitleBarButtonProps;
  iconClassName?: string;
}

export const TitleBarButton: React.FC<SectionTitleBarButtonProps> = ({
  text,
  url,
  isExternal,
}) => {
  return isExternal ? (
    <a
      target={isExternal ? "_blank" : "_self"}
      rel="noopener noreferrer"
      href={url}
      className="tertiary-btn-small title-bar-button"
      data-testid="title-bar-button-external-link"
    >
      {text}
      <ExternalLink
        title="External Link"
        className="title-bar-button-link-icon"
        data-testid="title-bar-button-external-link-icon"
      />
    </a>
  ) : (
    <Link
      to={url}
      className="tertiary-btn-small title-bar-button"
      data-testid="title-bar-button-internal-link"
    >
      {text}
    </Link>
  );
};

const SectionTitleBar: React.FC<SectionTitleBarProps> = ({
  icon: Icon,
  title,
  button,
  iconClassName = "",
}) => {
  return (
    <div className="title-bar">
      <div className="title-bar-container">
        <Icon
          title={title}
          className={`title-bar-container-icon ${iconClassName}`}
          data-testid="title-bar-icon"
        />
        <div className="unit-title">{title}</div>
      </div>
      {button ? (
        <TitleBarButton
          text={button.text}
          url={button.url}
          isExternal={button.isExternal}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SectionTitleBar;
