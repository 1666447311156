import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import queryString from "query-string";

import history from "../../history";
import { required } from "../../formValidation/FormValidation";
import { authenticateUser, removeLoginError } from "../../actions";
import SubmitButton from "../buttons/SubmitButton";

import renderInputField from "./renderInputField";

const locationQueryString = queryString.parse(history.location.search);

const TwoFactorAuthenticationForm: React.FC<
  InjectedFormProps<
    {
      validationField: string;
    },
    any
  >
> = ({ handleSubmit, invalid, submitting, pristine }) => {
  const dispatch = useDispatch();
  return (
    <form
      onSubmit={handleSubmit}
      className="right-form"
      aria-describedby="verificationDescription"
    >
      <label>
        Verification Code
        <Field
          name="validationField"
          validate={[required]}
          component={renderInputField}
          type="input"
          onChange={() => dispatch(removeLoginError)}
          autoFocus
        />
      </label>
      <SubmitButton
        disabled={invalid || submitting || pristine}
        name="Validate Code"
        decorator="right-form-btn"
      />
    </form>
  );
};

const onSubmit = (
  value: { validationField: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
) => {
  dispatch(
    authenticateUser(
      value.validationField,
      locationQueryString.auth_code as string
    )
  );
};

const WrappedForm = reduxForm({
  form: "twoFactorAuthentication",
  onSubmit,
})(TwoFactorAuthenticationForm);

export default WrappedForm;
