import { IObjectIndexType } from "../interfaces/interfaces";

export const errorMessage: IObjectIndexType = {
  birthdateFormatInvalid: "The birthdate is invalid.",
  birthdateInvalid: "You must be at least 13 years old to create an account.",
  expiredInvitation:
    "Your invitation has expired. Please contact your admin for a new invitation.",
  familyNameInvalid: "The last name is invalid.",
  givenNameInvalid: "The first name is invalid.",
  isError: "Something went wrong. Please try again later.",
  isInvalidToken: "This request has timed out. Please try again.",
  isLimit:
    "There were too many incorrect attempts. Your account has been locked for 30 minutes. Please try again later.",
  isWrong: "Incorrect email and password.",
  isWrongCode: "This verification code is incorrect.",
  passwordInvalid: "The password must be at least 8 characters long.",
  passwordMismatch: "Passwords do not match.",
  userAlreadyAssociatedWithOrganization:
    "This account is already associated with an organization. Please contact Brilliant Customer Support.",
  verificationRateLimited:
    "There have been too many verification attempts. Please try again in 30 minutes.",
};

/**
 * Validation functions
 * Used to validate input in forms. Returns undefined if the value is valid.
 * Returns the error message if the value is invalid.
 */

export const required = (value: string): string | undefined =>
  value ? undefined : "This field is required";

/**
 * The date must be of 10 digits in length, for "MM/DD/YYYY".
 * @param value Value of date field.
 */
export const dateLength = (value: string): string | undefined =>
  value.length !== 10 ? "Date must be filled out completely" : undefined;

export const emailType = (value: string): string | undefined =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const maxLength = (max: number) => (value: string) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength6 = maxLength(6);
export const maxLength1000 = maxLength(1000);

const minLength = (min: number) => (value: string) =>
  value.length < min ? `Must be ${min} characters or more` : undefined;
export const minLength8 = minLength(8);

/**
 * The phone number must be of 14 digits in length, for "(XXX) XXX-XXXX".
 * @param value Value of phone number field.
 */
export const phoneNumberLength = (value: string): string | undefined =>
  value.length !== 14
    ? "Phone number must be filled out completely"
    : undefined;

/**
 * Normalization functions
 * Used to normalize input in forms.
 */

const normalizeMaxLength = (max: number) => (value?: string) =>
  value && value.length > max ? value.slice(0, 6) : value;
export const normalizeMaxLength6 = normalizeMaxLength(6);

/**
 * Returns the phone number input normalized as (111) 111-1111.
 * @param value Value of mobile number field.
 */
export const normalizePhone = (value: string): string => {
  if (!value) return value;

  const onlyDigits = value.replace(/[^\d]/g, "");
  if (onlyDigits.length <= 3) return onlyDigits;
  if (onlyDigits.length <= 6)
    return `(${onlyDigits.slice(0, 3)}) ${onlyDigits.slice(3)}`;
  return `(${onlyDigits.slice(0, 3)}) ${onlyDigits.slice(
    3,
    6
  )}-${onlyDigits.slice(6, 10)}`;
};

/**
 * Returns the date input normalized as MM/DD/YYYY.
 * @param value Value of date field.
 */
export const normalizeDate = (value: string): string => {
  if (!value) return value;

  const onlyDigits = value.replace(/[^\d]/g, "");
  if (onlyDigits.length <= 2) return onlyDigits;
  if (onlyDigits.length <= 4)
    return `${onlyDigits.slice(0, 2)}/${onlyDigits.slice(2)}`;
  return `${onlyDigits.slice(0, 2)}/${onlyDigits.slice(
    2,
    4
  )}/${onlyDigits.slice(4, 8)}`;
};
