export interface TagProps {
  name: string;
  tagType: string;
}

const Tag: React.FC<TagProps> = ({ name, tagType }) => {
  return (
    <div
      data-testid="tag-container"
      className={`unit-status unit-tag margin-left-very-small ${tagType}`}
    >
      {name}
    </div>
  );
};

export default Tag;
