import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import {
  ERegistrationError,
  IAppState,
  IUserRegistrationData,
} from "../../../interfaces/interfaces";
import ExpiredInvitation from "../ExpiredInvitation";
import { getQueryParams, sendUserVerificationEmail } from "../../../actions";
import InvalidInvitation from "../InvalidInvitation";
import SuccessPage from "../common/SuccessPage";
import EmailVerificationStep from "../common/EmailVerificationStep";
import CompleteAccountStep from "../common/CompleteAccountStep";

import CompleteAccountForm from "./CompleteAccountForm";
import EmailVerificationForm from "./EmailVerificationForm";

const InvitedUserRegistration: React.FC<{
  newUserRegistration: IUserRegistrationData;
}> = ({ newUserRegistration }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQueryParams());
    dispatch(sendUserVerificationEmail({ isTenantRegistrationFlow: false }));
  }, [dispatch]);

  const { configHomeName } = newUserRegistration;
  if (newUserRegistration.error === ERegistrationError.expiredInvitation)
    return <ExpiredInvitation />;
  if (newUserRegistration.error === ERegistrationError.invalidInvitation)
    return <InvalidInvitation />;

  if (!newUserRegistration.emailVerified)
    return (
      <EmailVerificationStep
        form={<EmailVerificationForm />}
        email={newUserRegistration.email}
        isUser
      />
    );
  // The CompleteAccountStep may show for an existing user before the SuccessPage is displayed.
  // To prevent this, we check whether the tenant has a user ID (i.e. is an existing user) before
  // showing the CompleteAccountStep.
  if (!newUserRegistration.accountCompleted && !newUserRegistration.userId)
    return <CompleteAccountStep form={<CompleteAccountForm />} isUser />;
  return (
    <SuccessPage isUser>
      <p>
        You&apos;ve successfully joined <b>{configHomeName}</b>!
      </p>
      <br />
      <p>
        Tap below to install the Brilliant Mobile App and start controlling your
        home.
      </p>
    </SuccessPage>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    newUserRegistration: state.newUserRegistration,
  };
};

export default connect(mapStateToProps)(InvitedUserRegistration);
