import { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import _ from "lodash";
import { useQuery, useReactiveVar } from "@apollo/client";

import { ReactComponent as Close } from "../../img/Close.svg";
import { ReactComponent as InfoIcon } from "../../img/infoIcon.svg";
import {
  IBuildingIdVariable,
  IRemoteLockBuilding,
  IRemoteLockChangedHome,
  IRemoteLockHome,
  IRemoteLockIntegrationDevice,
} from "../../interfaces/interfaces";
import {
  REMOTE_LOCK_LARGE_UPDATE_MODAL_NOT_SHOWING_ARGS,
  conflictUnitsVar,
  currentChangedHomesVar,
  isEditAccessDevicesInProgressVar,
  showRemoteLockLargeUpdateModalVar,
  showRemoteLockUpdateInProgressModalVar,
} from "../apollo/LocalState";
import { GET_REMOTE_LOCK_BUILDING } from "../../api/gqlQueries";
import Spinner from "../Spinner";
import { oneHourInMs, quantityDisplay } from "../../utils/utils";
import {
  remoteLockDeviceType,
  sortPropertiesByName,
} from "../../utils/integrationUtils";
import { ReactComponent as Alert } from "../../img/Alert.svg";
import BaseModal from "../modals/BaseModal";

import RemoteLockTableRow from "./RemoteLockTableRow";
import RemoteLockLargeUpdateModal from "./modals/RemoteLockLargeUpdateModal";
import RemoteLockUpdateInProgressModal from "./modals/RemoteLockUpdateInProgressModal";

export interface RemoteLockTableProps {
  unitAvailableDevices: IRemoteLockIntegrationDevice[];
  availableDoorDevices: IRemoteLockIntegrationDevice[];
  buildingId: string;
  selectedProperties: string[];
  setSelectedProperties: (propertyIds: string[]) => void;
}

interface IRemoteLockBuildingData {
  building: IRemoteLockBuilding;
}

export const SavingChangesTimedOutModal: React.FC<{
  closeModalFn: () => void;
  refetchFn: () => void;
  failedHomes: string[];
}> = ({ closeModalFn, refetchFn, failedHomes }) => {
  const actionButtonFn = () => {
    refetchFn();
    closeModalFn();
  };

  return (
    <BaseModal
      actionButtonFn={actionButtonFn}
      actionButtonText="OK"
      closeModalFn={closeModalFn}
      headerText="Timed Out While Saving Changes"
    >
      <div className="text-center">
        <Alert title="alert" />
        <div className="modal-body-text-container menu menu-light">
          It’s taking too long to update the access device assignments for the
          following units:
          <br />
          <br />
          <div className="menu">{failedHomes.join(", ")}</div>
          <br />
          Please review the selected units’ device assignments to verify whether
          your changes were saved successfully.
          <br />
          <br />
          If your changes were not saved, please try again. If the problem
          persists, contact Brilliant for support.
        </div>
      </div>
    </BaseModal>
  );
};

export const getIsNewDataEqualToExpected = ({
  changedHomes,
  building,
  disabledHomes,
  failedHomes,
}: {
  changedHomes: IRemoteLockChangedHome[];
  building: IRemoteLockBuilding | undefined;
  disabledHomes: string[];
  failedHomes: string[];
}): boolean => {
  if (changedHomes.length === 0) {
    return true;
  }
  let mappedFoundAccessDevices: IRemoteLockIntegrationDevice[] = [];
  _.remove(changedHomes, (changedHome) => {
    disabledHomes.push(changedHome.id);
    failedHomes.push(changedHome.homeName);
    const expectedAccessDevices = changedHome.remotelockAccessDevices;
    const foundHome = _.find(
      building?.homes,
      (home) => home.id === changedHome.id
    );
    if (changedHome.deviceType === remoteLockDeviceType.doorGroup) {
      mappedFoundAccessDevices = _.map(
        foundHome?.remotelockDoorGroups,
        "doorGroup"
      );
    } else {
      mappedFoundAccessDevices = _.map(
        foundHome?.remotelockUnitAccessDevices,
        "device"
      );
    }
    _.remove(expectedAccessDevices, (expectedAccessDevice) => {
      const deviceExists = mappedFoundAccessDevices.some(
        (mappedFoundAccessDevice: IRemoteLockIntegrationDevice) =>
          mappedFoundAccessDevice.id === expectedAccessDevice.id
      );
      if ("remotelockAccessGrantId" in expectedAccessDevice) {
        return !deviceExists;
      }
      return deviceExists;
    });
    return expectedAccessDevices.length === 0;
  });
  return changedHomes.length === 0;
};

let timer: NodeJS.Timeout;

const RemoteLockTable: React.FC<RemoteLockTableProps> = ({
  unitAvailableDevices,
  availableDoorDevices,
  buildingId,
  selectedProperties,
  setSelectedProperties,
}) => {
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [allProperties, setAllProperties] = useState<string[]>([]);
  const [showFailedToSaveChangesModal, setShowFailedToSaveChangesModal] =
    useState(false);
  const [conflictErrorDisplayed, setConflictErrorDisplayed] = useState(false);

  const { loading, data, startPolling, stopPolling, refetch } = useQuery<
    IRemoteLockBuildingData,
    IBuildingIdVariable
  >(GET_REMOTE_LOCK_BUILDING, {
    variables: { buildingId },
  });

  const showRemoteLockLargeUpdateModalArgs = useReactiveVar(
    showRemoteLockLargeUpdateModalVar
  );
  const showRemoteLockUpdateInProgressModal = useReactiveVar(
    showRemoteLockUpdateInProgressModalVar
  );

  const currentChangedHomesMap = useReactiveVar(currentChangedHomesVar);
  const isEditAccessDevicesInProgress = useReactiveVar(
    isEditAccessDevicesInProgressVar
  );

  const changedHomes = currentChangedHomesMap.get(buildingId) || [];

  const conflictUnits = useReactiveVar(conflictUnitsVar);

  let sortedHomes: IRemoteLockHome[] = [];
  if (data) sortedHomes = sortPropertiesByName(data.building.homes);

  const selectionInProgress = selectedProperties.length > 0;
  const halfOpacityClass = selectionInProgress
    ? "remote-lock-table-unit-disabled"
    : "";

  const disabledHomes: string[] = [];
  const failedHomes: string[] = [];

  const isNewDataEqualToExpected = getIsNewDataEqualToExpected({
    building: data?.building,
    changedHomes,
    disabledHomes,
    failedHomes,
  });

  const modalRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    clearTimeout(timer);
    if (changedHomes.length > 0) {
      startPolling(1000);
      timer = setTimeout(() => {
        setShowFailedToSaveChangesModal(true);
        currentChangedHomesVar(new Map());
        stopPolling();
      }, oneHourInMs * 2);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [changedHomes]);

  useEffect(() => {
    if (conflictErrorDisplayed) {
      setConflictErrorDisplayed(false);
    }
    if (conflictUnits.length > 0) {
      setConflictErrorDisplayed(true);
    }
  }, [conflictUnits]);

  useEffect(() => {
    if (isNewDataEqualToExpected) {
      const updatedCurrentChangedHomesMap = currentChangedHomesMap;
      updatedCurrentChangedHomesMap.delete(buildingId);
      currentChangedHomesVar(updatedCurrentChangedHomesMap);
      stopPolling();
    }
    if (data) {
      setAllProperties(data.building.homes.map((home) => home.id).sort());
    }
  }, [data]);

  useEffect(() => {
    refetch();
    return () => {
      conflictUnitsVar([]);
      stopPolling();
    };
  }, []);

  if (loading) return <Spinner />;
  if (!data) throw Error("No data was returned");

  const selectedHomes = _.filter(data.building.homes, (home) => {
    return selectedProperties.includes(home.id);
  });

  const deselectAllHomes = () => {
    if (isSelectedAll) setSelectedAll(!isSelectedAll);
    setSelectedProperties([]);
  };

  const handleSelectAll = () => {
    if (isSelectedAll) setSelectedProperties([]);
    else
      setSelectedProperties(
        data.building.homes
          .filter((home) => !disabledHomes.includes(home.id))
          .map((home) => home.id)
      );
    setSelectedAll(!isSelectedAll);
  };

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target;
    let newSelectedProperties;

    if (!checked) {
      // A selected home property was unselected.
      newSelectedProperties = selectedProperties.filter((item) => item !== id);
      if (isSelectedAll) setSelectedAll(!isSelectedAll);
    } else {
      // An unselected home property was selected.
      newSelectedProperties = [...selectedProperties, id];
      if (_.isEqual(allProperties, newSelectedProperties.sort()))
        setSelectedAll(!isSelectedAll);
    }

    setSelectedProperties(newSelectedProperties);
  };

  return (
    <>
      <div ref={modalRef}>
        {showRemoteLockLargeUpdateModalArgs.isShowing && (
          <RemoteLockLargeUpdateModal
            confirmModalFn={() => {
              showRemoteLockLargeUpdateModalArgs.confirmFn();
              showRemoteLockLargeUpdateModalVar(
                REMOTE_LOCK_LARGE_UPDATE_MODAL_NOT_SHOWING_ARGS
              );
            }}
            closeModalFn={() =>
              showRemoteLockLargeUpdateModalVar(
                REMOTE_LOCK_LARGE_UPDATE_MODAL_NOT_SHOWING_ARGS
              )
            }
          />
        )}
      </div>
      {showRemoteLockUpdateInProgressModal && (
        <RemoteLockUpdateInProgressModal
          closeModalFn={() => {
            showRemoteLockUpdateInProgressModalVar(false);
          }}
        />
      )}
      {showFailedToSaveChangesModal && (
        <SavingChangesTimedOutModal
          closeModalFn={() => {
            setShowFailedToSaveChangesModal(false);
          }}
          refetchFn={refetch}
          failedHomes={failedHomes}
        />
      )}
      <div className="remote-lock-table-container">
        {selectionInProgress && (
          <div className="remote-lock-selected-container">
            <div className="remote-lock-selected-amount">
              <div className="remote-lock-selected-amount-number unit-title">
                {selectedProperties.length}
              </div>
              <div className="remote-lock-selected-amount-text text-label">
                {quantityDisplay(selectedProperties.length, "Unit")} Selected
              </div>
            </div>
            <div className="remote-lock-selected-amount-line" />
            <Close
              title="cancel-selection"
              className="remote-lock-selected-close-icon"
              onClick={() => {
                if (!isEditAccessDevicesInProgress) deselectAllHomes();
              }}
            />
          </div>
        )}
        <StyledEngineProvider injectFirst>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="center-align-as-row">
                      <input
                        id="select-all-checkbox"
                        type="checkbox"
                        className="remote-lock-table-checkbox"
                        onChange={handleSelectAll}
                        checked={isSelectedAll}
                        disabled={isEditAccessDevicesInProgress}
                      />
                      <label
                        htmlFor="select-all-checkbox"
                        className="table-row-header"
                      >
                        UNIT
                      </label>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="table-row-header center-align-as-row text-label">
                      UNIT DOOR ACCESS
                      <div className="tooltip">
                        <InfoIcon className="table-row-header-svg center-align-as-row" />
                        <div className="tooltiptext unit-description">
                          Individual doors in RemoteLock that residents of only
                          a single unit will have access to.
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="table-row-header center-align-as-row text-label">
                      COMMON AREA DOOR GROUP ACCESS
                      <div className="tooltip">
                        <InfoIcon className="table-row-header-svg center-align-as-row" />
                        <div className="tooltiptext unit-description">
                          Groups of doors in RemoteLock that residents of
                          multiple units will share access to.
                        </div>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedHomes.map((home) => {
                  const isRowSelected = selectedProperties.includes(home.id);
                  const isHomeChangeInProgress = disabledHomes.includes(
                    home.id
                  );
                  return (
                    <RemoteLockTableRow
                      unitAvailableDevices={unitAvailableDevices}
                      availableDoorDevices={availableDoorDevices}
                      home={home}
                      handleClick={handleClick}
                      halfOpacityClass={halfOpacityClass}
                      isRowSelected={isRowSelected}
                      isHomeChangeInProgress={isHomeChangeInProgress}
                      selectionInProgress={selectionInProgress}
                      key={home.id}
                      selectedHomes={selectedHomes}
                      startPolling={startPolling}
                      deselectAllHomes={deselectAllHomes}
                      buildingId={buildingId}
                      modalRef={modalRef}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledEngineProvider>
      </div>
    </>
  );
};

export default RemoteLockTable;
