import { Dispatch } from "redux";
import { useEffect } from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { useQuery, useReactiveVar } from "@apollo/client";

import {
  reconfigurationErrorVar,
  userManagementGenericErrorVar,
} from "../apollo/LocalState";
import {
  emailType,
  maxLength1000,
  required,
} from "../../formValidation/FormValidation";
import InputField from "../registration/common/InputField";
import SubmitButton from "../buttons/SubmitButton";
import { inviteTenant } from "../../actions/actionCreators/tenantManagement";
import {
  EReconfigurationError,
  EUserManagementGenericError,
  IPropertyIdVariable,
  IUnitDetailsData,
} from "../../interfaces/interfaces";
import { GET_HOME } from "../../api/gqlQueries";

import {
  renderInstallIncompleteAlert,
  renderTenantModalGenericError,
  renderTenantModalReconfigurationError,
} from "./common/tenant_modal_common_views";

interface IInviteTenantFormCustomProps {
  closeModalFn: () => void;
  increaseCurrentStep: () => void;
  propertyId: string;
}

interface IInviteTenantFormData {
  email: string;
}

const InviteTenantForm: React.FC<
  IInviteTenantFormCustomProps &
    InjectedFormProps<IInviteTenantFormData, IInviteTenantFormCustomProps>
> = ({
  closeModalFn,
  propertyId,
  handleSubmit,
  invalid,
  submitting,
  pristine,
  increaseCurrentStep,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = (value: IInviteTenantFormData, dispatch: Dispatch<any>) => {
    dispatch(
      inviteTenant({
        email: value.email,
        increaseCurrentStep,
        propertyId,
      })
    );
  };
  useEffect(() => {
    return () => {
      reconfigurationErrorVar(EReconfigurationError.noError);
      userManagementGenericErrorVar(EUserManagementGenericError.noError);
    };
  }, []);
  const reconfigurationError = useReactiveVar(reconfigurationErrorVar);
  const genericError = useReactiveVar(userManagementGenericErrorVar);
  const { data } = useQuery<IUnitDetailsData, IPropertyIdVariable>(GET_HOME, {
    variables: { propertyId },
  });
  const isInstallationIncomplete = data?.home.isInstallIncomplete ?? false;
  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="invite-tenant-modal-section">
        <label htmlFor="emailField">Email</label>
        <br />
        <Field
          name="email"
          id="emailField"
          validate={[required, emailType, maxLength1000]}
          component={InputField}
          type="email"
          autoComplete="email"
        />
      </div>
      {isInstallationIncomplete &&
        renderInstallIncompleteAlert({ isMoveIn: true })}
      {reconfigurationError !== EReconfigurationError.noError &&
        renderTenantModalReconfigurationError({ reconfigurationError })}
      {genericError !== EUserManagementGenericError.noError &&
        renderTenantModalGenericError({ genericError })}
      <div className="invite-tenant-modal-actions">
        <button
          type="button"
          className="tertiary-btn-small"
          onClick={closeModalFn}
        >
          Cancel
        </button>
        <SubmitButton
          disabled={
            invalid || submitting || pristine || isInstallationIncomplete
          }
          name="Send Invite"
          decorator="tertiary-btn-small tertiary-btn-small-main invite-tenant-modal-actions-submit"
        />
      </div>
    </form>
  );
};

export default reduxForm<IInviteTenantFormData, IInviteTenantFormCustomProps>({
  form: "inviteTenant",
})(InviteTenantForm);
