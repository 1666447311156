import { useEffect } from "react";

import LoginResultModal from "../modals/LoginResultModal";
import { ReactComponent as Logo } from "../../img/logos/Brilliant.svg";
import { ReactComponent as Multiply } from "../../img/Close.svg";
import { ReactComponent as RemoteLockLogo } from "../../img/logos/RemoteLock.svg";
import { oneSecInMs } from "../../utils/utils";

export const renderLogo = (): JSX.Element => {
  return (
    <div className="center-align-as-row">
      <Logo className="brilliant-logo" />
      <Multiply className="multiply-icon" />
      <RemoteLockLogo className="remotelock-logo" />
    </div>
  );
};

const RemoteLockSuccess: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, oneSecInMs * 10);
  }, []);
  return (
    <LoginResultModal logo={renderLogo()} title="Success!">
      You have successfully linked your RemoteLock account with Brilliant.
      <br />
      <br />
      You can now set up automated management of resident access from the
      Brilliant Command Center under
      <br />
      <b>Configure &rarr; Integrations &rarr; RemoteLock.</b>
    </LoginResultModal>
  );
};

export default RemoteLockSuccess;
