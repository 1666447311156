import { ReactComponent as ExternalLink } from "../../img/ExternalLink.svg";

export interface ILearnMoreButtonProps {
  classNameOverride?: string;
  link: string;
  text?: string;
}

const LearnMoreButton: React.FC<ILearnMoreButtonProps> = ({
  classNameOverride,
  link,
  text,
}) => {
  return (
    <a
      className={classNameOverride ?? "learn-more-button"}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {text ?? "Learn More"}
      <ExternalLink />
    </a>
  );
};

export default LearnMoreButton;
