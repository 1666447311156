import { Dispatch } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import {
  IAppState,
  IVerificationFormCustomProps,
  IVerificationFormData,
} from "../../interfaces/interfaces";
import { verifyPhone } from "../../actions";

import VerificationForm from "./common/VerificationForm";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onSubmit = (values: IVerificationFormData, dispatch: Dispatch<any>) => {
  dispatch(verifyPhone(values));
};

const PhoneVerificationForm = reduxForm<
  IVerificationFormData,
  IVerificationFormCustomProps
>({
  form: "phoneVerification",
  onSubmit,
})(VerificationForm);

// This is being used to pass a custom prop to VerificationForm, as there does
// not seem to be another way to pass a custom prop to Redux Form.
const mapStateToProps = (state: IAppState) => ({
  registrationError: state.newUser.error,
  submitText: "Validate and Enroll",
});

export default connect(mapStateToProps)(PhoneVerificationForm);
