import BadRequest from "../../img/errors/BadRequest.svg";
import Server from "../../img/errors/Server.svg";

export const NotFoundErrorComponent: React.FC = () => {
  return (
    <div className="error-container">
      <div className="error-sign">404</div>
      <div data-testid="error-title" className="error-title">
        Oops! Page Not Found
      </div>
      <div className="error-description">
        That page doesn’t exist or is unavailable.
      </div>
    </div>
  );
};

export const ServerErrorComponent: React.FC = () => {
  return (
    <div className="error-container">
      <img src={Server} alt="Server" />
      <div data-testid="error-title" className="error-title">
        Server Error
      </div>
      <div className="error-description">
        Oops, something went wrong. Try to refresh this page or feel free
      </div>
      <div className="error-description">
        to contact us if the problem persists.
      </div>
    </div>
  );
};

const BadRequestErrorComponent: React.FC = () => {
  return (
    <div className="error-container">
      <img src={BadRequest} alt="Bad Request" />
      <div data-testid="error-title" className="error-title">
        Bad Request
      </div>
      <div className="error-description">
        This page isn’t working at the moment. Try to refresh this page or
      </div>
      <div className="error-description">
        feel free to contact us if the problem persists.
      </div>
    </div>
  );
};

const RateLimitExceeded: React.FC = () => {
  return (
    <div className="error-container">
      <img src={BadRequest} alt="Rate Limit Exceeded" />
      <div data-testid="error-title" className="error-title">
        Oops! Rate Limit Exceeded.
      </div>
      <div className="error-description">
        Please wait a few moments and then try again.
      </div>
    </div>
  );
};

const ErrorsComponent: React.FC<{ match: { params: { status: string } } }> = ({
  match,
}) => {
  const { status } = match.params;
  if (status >= "500" && status <= "599") {
    return <ServerErrorComponent />;
  }
  if (status === "400") {
    return <BadRequestErrorComponent />;
  }
  if (status === "429") {
    return <RateLimitExceeded />;
  }
  return <NotFoundErrorComponent />;
};

export default ErrorsComponent;
