import ExpiredInvitation from "../../img/errors/ExpiredInvitation.svg";

export interface IUserRegistrationErrorProps {
  name: string;
  description: string;
}

const UserRegistrationError: React.FC<IUserRegistrationErrorProps> = ({
  name,
  description,
}) => {
  return (
    <div className="error-container">
      <img src={ExpiredInvitation} alt="Bad Request" />
      <div className="error-title">{name}</div>
      <div className="error-description">{description}</div>
    </div>
  );
};

export default UserRegistrationError;
