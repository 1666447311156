export const AUTHENTICATION_CODE_FAILURE = "AUTHENTICATION_CODE_FAILURE";
export const AUTHENTICATION_USER_RATE_LIMITED =
  "AUTHENTICATION_USER_RATE_LIMITED";
export const INVALID_TOKEN = "INVALID_TOKEN";
export const LOADER_START = "LOADER_START";
export const LOADER_STOP = "LOADER_STOP";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGIN_USER_RATE_LIMITED = "LOGIN_USER_RATE_LIMITED";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOADING_START = "LOADING_START";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FORBIDDEN = "LOGIN_USER_FORBIDDEN";
export const REMOVE_LOGIN_ERROR_ON_CHANGE = "REMOVE_LOGIN_ERROR_ON_CHANGE";
export const USER_SIGNED_OUT = "USER_SIGNED_OUT";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAILURE = "AUTHENTICATION_FAILURE";
export const SAVE_USER_ID = "SAVE_USER_ID";
export const SAVE_EMAIL_ADDRESS = "SAVE_EMAIL_ADDRESS";
export const ENTRATA_EXCHANGE_START = "ENTRATA_EXCHANGE_START";
export const ENTRATA_EXCHANGE_SUCCESS = "ENTRATA_EXCHANGE_SUCCESS";
