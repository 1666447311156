import { IRemoteLockIntegrationDevice } from "../../interfaces/interfaces";
import Tooltip from "../../utils/Tooltip";

export interface IAccessDevicesCell {
  accessDevices: IRemoteLockIntegrationDevice[];
  isCommonAreaDevices: boolean;
  isRowWithUnitDevicesSelected: boolean;
  halfOpacityClass: string;
}

const renderAccessDevices = ({
  propsDevices,
  isRowWithUnitDevicesSelected,
  halfOpacityClass,
  isCommonAreaDevices,
}: {
  propsDevices: IRemoteLockIntegrationDevice[];
  isRowWithUnitDevicesSelected: boolean;
  halfOpacityClass: string;
  isCommonAreaDevices: boolean;
}) => {
  const accessDevicesToShow = propsDevices.slice(0, 3);
  const numRemainingAccessDevices = propsDevices.length - 3;
  const greenPlusClassName = isCommonAreaDevices
    ? "remote-lock-table-cell-add-green"
    : "";
  const halfOpacityForSelectedUnitDevices = isRowWithUnitDevicesSelected
    ? halfOpacityClass
    : "";
  const greenAssignedDeviceClass = isCommonAreaDevices
    ? "remote-lock-table-device-name-container-green"
    : "";
  return (
    <div
      className={`center-align-as-row ${
        isRowWithUnitDevicesSelected ? "selected-unit-access-devices" : ""
      }`}
    >
      <div
        data-testid="remote-lock-table-device-plus"
        className={`remote-lock-table-cell-add ${halfOpacityForSelectedUnitDevices} ${greenPlusClassName}`}
      >
        <div className="sub-heading">+</div>
      </div>
      {accessDevicesToShow.map((device) => {
        return (
          <div
            className={`remote-lock-table-device-name-container tooltip secondary-btn ${greenAssignedDeviceClass} ${
              isRowWithUnitDevicesSelected ? halfOpacityClass : ""
            }`}
            key={device.id}
          >
            <Tooltip tooltipText={device.name}>
              <div className="remote-lock-table-device-name remote-lock-table-device-name-cell">
                {device.name}
              </div>
            </Tooltip>
          </div>
        );
      })}
      {numRemainingAccessDevices > 0 && (
        <div
          className={`secondary-btn remote-lock-table-device-number ${greenAssignedDeviceClass} ${
            isRowWithUnitDevicesSelected ? halfOpacityClass : ""
          }`}
        >
          +{numRemainingAccessDevices}
        </div>
      )}
    </div>
  );
};

const AccessDevice: React.FC<IAccessDevicesCell> = ({
  accessDevices,
  isCommonAreaDevices,
  isRowWithUnitDevicesSelected,
  halfOpacityClass,
}) => {
  const greenAssignButtonClass = isCommonAreaDevices
    ? "remote-lock-table-assign-devices-green"
    : "";
  if (accessDevices.length === 0) {
    return (
      <div
        className={`tertiary-btn-small remote-lock-table-assign-devices ${greenAssignButtonClass} ${
          isRowWithUnitDevicesSelected ? halfOpacityClass : ""
        }`}
      >
        + Assign Devices
      </div>
    );
  }
  return (
    <>
      {renderAccessDevices({
        halfOpacityClass,
        isCommonAreaDevices,
        isRowWithUnitDevicesSelected,
        propsDevices: accessDevices,
      })}
    </>
  );
};

export default AccessDevice;
