import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

import { ServerErrorComponent } from "./components/errors/Error";

type MyState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Record<string, unknown>, MyState> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): MyState {
    return { hasError: true };
  }

  // componentDidCatch requires errorInfo to have a type of ErrorBoundary
  // scope.setExtras requires errorInfo to have a type of Extras
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    Sentry.withScope((scope) => {
      scope.setExtra("componentStack", errorInfo.componentStack);
      Sentry.captureException(error);
      this.setState({ hasError: true });
    });
  }

  render(): any {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) return <ServerErrorComponent />;

    return children;
  }
}

export default ErrorBoundary;
