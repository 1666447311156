import { useQuery } from "@apollo/client";

import { IConfiguration } from "../../interfaces/interfaces";
import Spinner from "../Spinner";
import { GET_CONFIGURATIONS } from "../../api/gqlQueries";

import ConfigurationsCard from "./ConfigurationsCard";

const Configurations: React.FC = () => {
  const { loading, data } = useQuery<IConfiguration>(GET_CONFIGURATIONS);
  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    throw Error("No data was returned");
  }
  return (
    <div>
      <div
        data-testid="configurations-header"
        className="configuration-title menu"
      >
        Configurations
      </div>
      <div className="configuration-list">
        {data.configurations.map((configuration) => {
          return (
            <ConfigurationsCard
              key={configuration.id}
              configuration={configuration}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Configurations;
