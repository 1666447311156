import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Field, InjectedFormProps } from "redux-form";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ReactComponent as ShowPassword } from "../../../img/ShowPassword.svg";
import { ReactComponent as HidePassword } from "../../../img/HidePassword.svg";
import { completeAccountSelectedDateVar } from "../../apollo/LocalState";
import {
  ERegistrationError,
  IRegistrationTenantAccountFormData,
} from "../../../interfaces/interfaces";
import {
  errorMessage,
  maxLength1000,
  minLength8,
  required,
} from "../../../formValidation/FormValidation";
import { clearRegistrationError } from "../../../actions";
import SubmitButton from "../../buttons/SubmitButton";
import { renderTermsAndConditionsAndPrivacyPolicy } from "../../../utils/utils";

import InputField from "./InputField";

export interface ICompleteAccountFormCustomProps {
  registrationError: ERegistrationError;
  birthdate: string;
}

const CompleteUserAccountFormWithoutPhoneNumber: React.FC<
  ICompleteAccountFormCustomProps &
    InjectedFormProps<
      IRegistrationTenantAccountFormData,
      ICompleteAccountFormCustomProps
    >
> = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  registrationError,
  birthdate,
}) => {
  const dispatch = useDispatch();
  const clearError = () => dispatch(clearRegistrationError);
  const completeAccountSelectedDate = useReactiveVar(
    completeAccountSelectedDateVar
  );
  const [selectedDate, setDate] = useState<any>(null);
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  useEffect(() => {
    completeAccountSelectedDateVar(birthdate);
    return () => {
      completeAccountSelectedDateVar("");
    };
  }, [birthdate]);
  const showError = registrationError !== ERegistrationError.noError;

  return (
    <form onSubmit={handleSubmit} className="right-form">
      <div className="right-form-row">
        <div className="right-form-field">
          <label
            className={`${
              registrationError === ERegistrationError.givenNameInvalid
                ? " alert"
                : ""
            }`}
            htmlFor="givenNameField"
          >
            First Name
          </label>
          <Field
            id="givenNameField"
            name="givenName"
            registrationErrorHighlight={[ERegistrationError.givenNameInvalid]}
            validate={[required, maxLength1000]}
            component={InputField}
            type="input"
            onChange={clearError}
          />
        </div>

        <div className="right-form-field">
          <label
            className={`${
              registrationError === ERegistrationError.familyNameInvalid
                ? " alert"
                : ""
            }`}
            htmlFor="familyNameField"
          >
            Last Name
          </label>
          <Field
            id="familyNameField"
            name="familyName"
            registrationErrorHighlight={[ERegistrationError.familyNameInvalid]}
            validate={[required, maxLength1000]}
            component={InputField}
            type="input"
            onChange={clearError}
          />
        </div>
      </div>

      <label htmlFor="emailAddressField">Email</label>
      <Field
        disabled
        id="emailAddressField"
        name="emailAddress"
        validate={[required]}
        component={InputField}
        type="input"
      />

      <label htmlFor="birthdateField">Birthdate</label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={selectedDate}
          minDate={dayjs("1900-01-01")}
          maxDate={dayjs()}
          views={["year", "month", "day"]}
          openTo="year"
          onChange={(date) => {
            setDate(date);
            if (date !== null) {
              completeAccountSelectedDateVar(
                `${date.month() + 1}/${date.date()}/${date.year()}`
              );
            } else {
              completeAccountSelectedDateVar("");
            }
          }}
        />
      </LocalizationProvider>
      <p className="right-field-info right-field-info-birthday">
        This is used to verify your identity when contacting customer support.
      </p>

      <div className="right-form-field-passwords">
        <div className="right-form-field-password-container right-form-field-password-container-left">
          <label htmlFor="newPasswordField">New Password</label>
          <div className="right-form-field-password">
            <Field
              id="newPasswordField"
              name="newPassword"
              autoComplete="new-password"
              registrationErrorHighlight={[
                ERegistrationError.passwordInvalid,
                ERegistrationError.passwordMismatch,
              ]}
              validate={[required, minLength8, maxLength1000]}
              component={InputField}
              type={newPasswordType}
              onChange={clearError}
            />
            <div className="right-form-field-password-icon">
              {newPasswordType === "text" ? (
                <HidePassword onClick={() => setNewPasswordType("password")} />
              ) : (
                <ShowPassword onClick={() => setNewPasswordType("text")} />
              )}
            </div>
          </div>
        </div>
        <div className="right-form-field-password-container">
          <label htmlFor="confirmPasswordField">Confirm Password</label>
          <div className="right-form-field-password">
            <Field
              id="confirmPasswordField"
              name="confirmPassword"
              autoComplete="new-password"
              registrationErrorHighlight={[
                ERegistrationError.passwordInvalid,
                ERegistrationError.passwordMismatch,
              ]}
              validate={[required, minLength8, maxLength1000]}
              component={InputField}
              type={confirmPasswordType}
              onChange={clearError}
            />
            <div className="right-form-field-password-icon">
              {confirmPasswordType === "text" ? (
                <HidePassword
                  onClick={() => setConfirmPasswordType("password")}
                />
              ) : (
                <ShowPassword onClick={() => setConfirmPasswordType("text")} />
              )}
            </div>
          </div>
        </div>
      </div>

      {showError && (
        <p className="right-error">
          {errorMessage[ERegistrationError[registrationError]]}
        </p>
      )}

      {renderTermsAndConditionsAndPrivacyPolicy()}

      <SubmitButton
        disabled={
          invalid ||
          submitting ||
          pristine ||
          completeAccountSelectedDate === ""
        }
        name="Complete Account"
        decorator="right-form-btn"
      />
    </form>
  );
};

export default CompleteUserAccountFormWithoutPhoneNumber;
