import { useState } from "react";

import { ReactComponent as BigRedAlert } from "../../img/Big Red Alert.svg";
import AlertModal from "../alertManagement/AlertModal";
import { alertInfo, renderAlertTitle } from "../../utils/alertUtils";

export interface AlertBannerProps {
  alarmType: string;
  buildingName: string;
  deviceName: string;
  unitName: string;
  ambientTemperatureF?: number;
  reconfiguringDeviceNames?: string[];
  deviceType?: string;
  offlineDeviceNames?: string[];
}

const AlertBanner: React.FC<AlertBannerProps> = ({
  alarmType,
  buildingName,
  deviceName,
  unitName,
  ambientTemperatureF,
  reconfiguringDeviceNames,
  deviceType,
  offlineDeviceNames,
}) => {
  const [showAlertPopupModal, setAlertPopupModal] = useState(false);
  return (
    <>
      {showAlertPopupModal && (
        <AlertModal
          alarmType={alarmType}
          closeAlertModal={() => setAlertPopupModal(false)}
          buildingName={buildingName}
          unitName={unitName}
          deviceName={deviceName}
          ambientTemperatureF={ambientTemperatureF}
          reconfiguringDeviceNames={reconfiguringDeviceNames}
          deviceType={deviceType}
          offlineDeviceNames={offlineDeviceNames}
        />
      )}
      <button
        data-testid="alert-banner-button"
        onClick={() => setAlertPopupModal(true)}
        type="button"
        title="Open Alert"
        className="unit-alert-container center-align center-align-as-row"
      >
        <div className="center-align-as-row margin-left-right-smaller">
          <div className="unit-alert-icon-container center-align-as-row">
            <BigRedAlert className="unit-alert-icon-svg" title="BigRedAlert" />
          </div>
          <div
            className="unit-alert-text tool-tip"
            data-testid="alert-banner-title"
          >
            {renderAlertTitle({ alarmType, ambientTemperatureF })}
            {alertInfo[alarmType] ? ` - ${alertInfo[alarmType]}` : ""}
            {deviceName ? ` - ${deviceName}` : ""}
          </div>
          <div className="unit-alert-call-to-action sub-heading">Open</div>
        </div>
      </button>
    </>
  );
};

export default AlertBanner;
