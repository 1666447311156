import { Dispatch } from "redux";
import { AxiosError } from "axios";
import { StatusCodes } from "http-status-codes";

import { brilliantRequest } from "../../api/api";
import EWebAPIError from "../../api/errors";
import history from "../../history";
import {
  ENTRATA_EXCHANGE_START,
  ENTRATA_EXCHANGE_SUCCESS,
  LOADER_STOP,
} from "../types";
import {
  ENTRATA_EXCHANGE_ERROR,
  ENTRATA_EXCHANGE_UNAUTHORIZED,
  ENTRATA_EXCHANGE_UPSTREAM_ERROR,
} from "../constants";

export const handleError =
  /* eslint-disable camelcase */


    (dispatch: Dispatch) =>
    (error: AxiosError<{ error_id: EWebAPIError }>): boolean => {
      history.push("/entrata/error");
      dispatch({
        type: LOADER_STOP,
      });

      if (
        error.response &&
        error.response.status === StatusCodes.FORBIDDEN &&
        error.response.data.error_id === EWebAPIError.UNAUTHORIZED_LOGIN
      ) {
        dispatch({
          type: ENTRATA_EXCHANGE_UNAUTHORIZED,
        });
        return true;
      }
      if (
        error.response &&
        error.response.status === StatusCodes.BAD_GATEWAY &&
        error.response.data.error_id === EWebAPIError.UPSTREAM_FAILED_ERROR
      ) {
        dispatch({
          type: ENTRATA_EXCHANGE_UPSTREAM_ERROR,
        });
        return true;
      }
      dispatch({
        type: ENTRATA_EXCHANGE_ERROR,
      });
      return true;
    };

export const linkEntrata =
  (authCode: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: ENTRATA_EXCHANGE_START,
    });
    await brilliantRequest(
      "/entrata/code",
      { auth_code: authCode },
      dispatch,
      () => {
        dispatch({ type: ENTRATA_EXCHANGE_SUCCESS });
        history.push("/entrata/success");
        dispatch({
          type: LOADER_STOP,
        });
      },
      handleError(dispatch)
    );
  };
