import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import _ from "lodash";
import { useEffect } from "react";

import { ReactComponent as Bell } from "../../img/Bell.svg";
import { AlertTypes, renderAlertTitle } from "../../utils/alertUtils";
import { GET_ALERTS_FOR_ORGANIZATION } from "../../api/gqlQueries";
import {
  IAlert,
  IAlertData,
  IAppState,
  IOrganizationParams,
} from "../../interfaces/interfaces";
import Spinner from "../Spinner";
import { quantityDisplay } from "../../utils/utils";
import { ReactComponent as Close } from "../../img/Close.svg";
import { isAlertSideMenuVisibleVar } from "../apollo/LocalState";

import AlertCard from "./AlertCard";

export interface AlertsSideBarProps {
  organizationId: string;
}

const AlertsSideBar: React.FC<AlertsSideBarProps> = ({ organizationId }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const { loading, data } = useQuery<IAlertData, IOrganizationParams>(
    GET_ALERTS_FOR_ORGANIZATION,
    {
      variables: { organizationId },
    }
  );
  if (loading) return <Spinner />;
  if (!data) throw Error("No data was returned");

  // Filter out disabled alerts.
  const filteredAlerts = data.organization.alerts.filter((alert) => {
    switch (alert.alarmType) {
      case AlertTypes.ExtremeTemperature:
      case AlertTypes.LeakDetected:
      case AlertTypes.ReconfigurationTakingTooLong:
      case AlertTypes.ResidentInviteFailed:
      case AlertTypes.LowBattery:
      case AlertTypes.OfflineDevices:
        return true;
      default:
        return false;
    }
  });

  const numAlerts = filteredAlerts.length;
  const alertsPresent = numAlerts !== 0;

  const alertsSortedByTitle = _.sortBy(filteredAlerts, (climateAlarm) => {
    return renderAlertTitle({
      alarmType: climateAlarm.alarmType,
      ambientTemperatureF: climateAlarm.ambientTemperatureF,
    });
  });

  const alertsByBuildingName = _.groupBy(
    alertsSortedByTitle,
    (climateAlarm) => {
      return climateAlarm.building.propertyName;
    }
  );

  const alertsSortedAlphabeticallyByBuildingName = Object.keys(
    alertsByBuildingName
  )
    .sort()
    .reduce((acc: _.Dictionary<IAlert[]>, key: string) => {
      acc[key] = alertsByBuildingName[key];
      return acc;
    }, {});
  return (
    <div className="side-bar-container">
      <div className="side-bar-dropdown-arrow" />
      <div className="side-bar-empty center-align-as-column">
        <div className="side-bar-header side-bar-header-close">
          <div
            data-testid="number-of-alerts"
            className="tool-tip side-bar-header-close-title"
          >
            {alertsPresent
              ? `${numAlerts} ${quantityDisplay(numAlerts, "Alert")}`
              : "Alerts"}
          </div>
          <button
            onClick={() => {
              isAlertSideMenuVisibleVar(false);
            }}
            type="button"
            className="side-bar-header-close-btn"
          >
            <Close
              data-testid="header-close"
              className="header-close"
              title="Close"
            />
          </button>
        </div>
        {alertsPresent ? (
          Object.entries(alertsSortedAlphabeticallyByBuildingName).map(
            ([buildingName, alerts]) => {
              const alarmsSortedAlphabeticallyByHomeName = _.sortBy(
                alerts,
                (climateAlarm) => {
                  return climateAlarm.home.propertyName;
                }
              );
              return (
                <div key={buildingName} className="align-as-column">
                  <div className="sub-heading side-bar-building">
                    {buildingName}
                  </div>
                  {alarmsSortedAlphabeticallyByHomeName.map(
                    (climateAlarm: IAlert) => {
                      return (
                        <AlertCard
                          key={climateAlarm.id}
                          alarmType={climateAlarm.alarmType}
                          buildingName={climateAlarm.building.propertyName}
                          unitName={climateAlarm.home.propertyName}
                          deviceName={climateAlarm.deviceName}
                          ambientTemperatureF={climateAlarm.ambientTemperatureF}
                          deviceType={climateAlarm.deviceType}
                        />
                      );
                    }
                  )}
                </div>
              );
            }
          )
        ) : (
          <div className="center-align-as-column side-bar-empty-bell-container">
            <Bell className="side-bar-empty-bell-svg" title="Bell" />
            <div
              data-testid="no-alert-message"
              className="tool-tip side-bar-empty-bell-message"
            >
              There are no alerts.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    organizationId: state.user.organizationId,
  };
};
export default connect(mapStateToProps)(AlertsSideBar);
