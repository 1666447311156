import { IBackupWifiSettingsCard } from "../../interfaces/interfaces";

import InstallationSettingsCard from "./InstallationSettingsCard";

export interface IBackupWifiSettingsCardComponentProps {
  data: IBackupWifiSettingsCard;
  openModalFn: () => void;
}

const BackupWifiSettingsCard: React.FC<
  IBackupWifiSettingsCardComponentProps
> = ({ data, openModalFn }) => {
  const { networkName } = data;
  return (
    <InstallationSettingsCard
      data={{ settingName: "Backup WiFi" }}
      openModalFn={openModalFn}
    >
      <div className="unit-description-small flex-display hidden-name">
        {networkName ? (
          <div className="brilliant-card-description-id">{networkName}</div>
        ) : (
          <div className="brilliant-card-installation-error">NOT SET</div>
        )}
      </div>
    </InstallationSettingsCard>
  );
};

export default BackupWifiSettingsCard;
