import { EShowTenantSteps } from "../../interfaces/interfaces";

export const SHOW_TENANT_STEP = 0;
export const CANCEL_INVITED_TENANT_STEP = 100;
export const REMOVE_REGISTERED_TENANT_STEP_ONE = 200;
export const REMOVE_REGISTERED_TENANT_STEP_TWO = 201;

export const MOVE_IN_STEP = 300;
export const MOVE_IN_STARTED_STEP = 301;
export const NEW_INVITE_RESIDENT_STEP = 302;

export const ShowTenantSteps = new Map<EShowTenantSteps, string>([
  [EShowTenantSteps.ShowTenantGeneralStep, "General"],
  [EShowTenantSteps.ShowTenantAccessCredentialsStep, "Access Credentials"],
]);
