import { ReactComponent as RemoteLock } from "../../../img/logos/RemoteLock.svg";
import BaseModal from "../../modals/BaseModal";

export interface RemoteLockMobileModalProps {
  closeMobileModal: () => void;
}

const RemoteLockMobileModal: React.FC<RemoteLockMobileModalProps> = ({
  closeMobileModal,
}) => {
  return (
    <BaseModal
      actionButtonFn={closeMobileModal}
      actionButtonText="Dismiss"
      closeModalFn={closeMobileModal}
      headerText="Configure Integration"
    >
      <RemoteLock title="Remote Lock" />
      <div className="remote-lock-login-body-text remote-lock-login-body-text">
        <div className="menu menu-light">
          Your RemoteLock integration can only be configured on larger screens.
        </div>
        <br />
        <div className="menu menu-light">
          Please view your Brilliant Command Center account on a larger screen
          to continue.
        </div>
      </div>
    </BaseModal>
  );
};

export default RemoteLockMobileModal;
