const Spinner: React.FC<{
  message?: string;
  submessage?: string;
}> = ({ message, submessage }) => {
  return (
    <div className="spinner-container">
      <div className="lds-ring">
        <div />
        <div />
        <div />
      </div>
      {message ? <div className="menu spinner-message">{message}</div> : <></>}
      {submessage ? (
        <div className="tool-tip spinner-submessage">{submessage}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Spinner;
