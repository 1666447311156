import { connect } from "react-redux";

import { IAppState, ILoginData } from "../../interfaces/interfaces";
import SplitScreenLayout from "../registration/common/SplitScreenLayout";

import TwoFactorAuthenticationForm from "./TwoFactorAuthenticationForm";

const TwoFactorAuthentication: React.FC<{ user: ILoginData }> = ({ user }) => {
  return (
    <SplitScreenLayout
      form={<TwoFactorAuthenticationForm />}
      leftTitle="Let’s verify your account!"
      leftDescription="For security purposes, please verify your account using the mobile device you enrolled during registration."
      rightMessage={`We sent you an SMS to ${user.phoneNumber} with a verification code. Please validate it here.`}
      error={user.error}
    />
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(TwoFactorAuthentication);
