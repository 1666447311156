import { Field, InjectedFormProps, reduxForm } from "redux-form";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { useState } from "react";

import { ReactComponent as ShowPassword } from "../../img/ShowPassword.svg";
import { ReactComponent as HidePassword } from "../../img/HidePassword.svg";
import { loginUser, removeLoginError } from "../../actions";
import { ILoginForm } from "../../interfaces/interfaces";
import {
  emailType,
  maxLength1000,
  required,
} from "../../formValidation/FormValidation";
import SubmitButton from "../buttons/SubmitButton";

import RenderInputField from "./RenderInputField";

const LoginForm: React.FC<InjectedFormProps<ILoginForm, any>> = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
}) => {
  const dispatch = useDispatch();
  const [passwordType, setPasswordType] = useState("password");
  return (
    <form
      onSubmit={handleSubmit}
      data-testid="login-form"
      className="login-form"
    >
      <Field
        name="emailField"
        component={RenderInputField}
        testId="email-field"
        validate={[required, emailType, maxLength1000]}
        type="email"
        placeholder="Email Address"
        autoComplete="email"
        onChange={() => dispatch(removeLoginError)}
      />
      <div className="margin-bottom-small" />
      <div className="login-form-password-container">
        <Field
          name="passwordField"
          testId="password-field"
          component={RenderInputField}
          validate={[required, maxLength1000]}
          type={passwordType}
          placeholder="Password"
          autoComplete="current-password"
          onChange={() => dispatch(removeLoginError)}
        />
        <div className="login-form-password-icon">
          {passwordType === "text" ? (
            <HidePassword onClick={() => setPasswordType("password")} />
          ) : (
            <ShowPassword onClick={() => setPasswordType("text")} />
          )}
        </div>
      </div>
      <div className="margin-bottom-small" />
      <SubmitButton
        disabled={invalid || submitting || pristine}
        name="Log In"
        decorator="login-form-btn"
      />
    </form>
  );
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onSubmit = (values: ILoginForm, dispatch: Dispatch<any>) => {
  dispatch(loginUser(values));
};

const WrappedForm = reduxForm({
  form: "login",
  onSubmit,
})(LoginForm);

export default WrappedForm;
