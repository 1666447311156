import { PrivacyPolicy, TermsOfService } from "../../utils/utils";

import SplitScreenLayout from "./common/SplitScreenLayout";
import TermsAndConditionsForm from "./TermsAndConditionsForm";

const TermsAndConditions: React.FC = () => {
  const rightMessage = (
    <div className="terms-conditions-container">
      <div>
        As a user of the Brilliant Command Center, you acknowledge that this
        product is to be used for management purposes only across the smart home
        devices in your properties.
      </div>
      <br />
      <div>
        Information shown is covered by the&nbsp;
        <a target="_blank" rel="noopener noreferrer" href={PrivacyPolicy}>
          Brilliant Privacy Policy
        </a>
        &nbsp;and&nbsp;
        <a target="_blank" rel="noopener noreferrer" href={TermsOfService}>
          Brilliant Terms of Service
        </a>
        .
      </div>
    </div>
  );

  return (
    <SplitScreenLayout
      form={<TermsAndConditionsForm />}
      leftTitle="Before we begin your registration..."
      leftDescription=""
      rightMessage={rightMessage}
    />
  );
};

export default TermsAndConditions;
