import { Dispatch } from "redux";
import { toast } from "react-toastify";

import { LOADER_START, LOADER_STOP } from "../types";
import {
  deletePortalUserInvitation,
  deleteUserFromOrganization,
} from "../../api/organizations";
import { IAppState } from "../../interfaces/interfaces";

import { logOutUser } from "./userSession";

export const cancelPortalUserInvite =
  (emailAddress: string | null, propertyId: string, refetch: () => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: LOADER_START,
    });
    await deletePortalUserInvitation(
      propertyId,
      emailAddress,
      dispatch,
      () => {
        dispatch({
          type: LOADER_STOP,
        });
        refetch();
      },
      () => {
        dispatch({
          type: LOADER_STOP,
        });
        return false;
      }
    );
  };

export const removePortalUser =
  (propertyId: string, userId: string, refetch: () => void) =>
  async (dispatch: Dispatch, getState: () => IAppState): Promise<void> => {
    const currentUserId = getState().user.userId;
    dispatch({
      type: LOADER_START,
    });
    await deleteUserFromOrganization(
      propertyId,
      userId,
      dispatch,
      () => {
        dispatch({
          type: LOADER_STOP,
        });
        refetch();
        if (userId === currentUserId) {
          dispatch(logOutUser());
        } else {
          toast("Manager Removed");
        }
      },
      () => {
        dispatch({
          type: LOADER_STOP,
        });
        return false;
      }
    );
  };
