import { Link } from "react-router-dom";

import { IBreadcrumbs } from "../../interfaces/interfaces";

const Breadcrumbs: React.FC<{
  crumbs: IBreadcrumbs[];
  rootCrumb: IBreadcrumbs;
}> = ({ crumbs, rootCrumb }) => {
  return (
    <div className="bread-crumbs-container">
      <Link
        to={rootCrumb.path}
        className="bread-crumbs-name bread-crumbs-name-main"
      >
        {rootCrumb.name}
      </Link>
      {crumbs.map((element) => {
        return (
          <div key={element.name} className="bread-crumbs-element">
            <div className="bread-crumbs-arrow" />
            <Link to={element.path} className="bread-crumbs-name">
              {element.name}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
