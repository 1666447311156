import {
  ADD_MFA_DEVICE_SUCCESS,
  ADD_PHONE_NUMBER,
  ADD_USER_TO_ORGANIZATION_SUCCESS,
  BIRTHDATE_FORMAT_INVALID,
  BIRTHDATE_INVALID,
  CLEAR_REGISTRATION_ERROR,
  CREATE_USER_SUCCESS,
  EMAIL_VERIFICATION_SENT,
  EMAIL_VERIFICATION_SUCCESS,
  EXPIRED_INVITATION,
  FAMILY_NAME_INVALID,
  GET_QUERY_PARAMS,
  GIVEN_NAME_INVALID,
  PASSWORD_INVALID,
  PASSWORD_MISMATCH_REGISTRATION_ERROR,
  PHONE_VERIFICATION_SENT,
  PHONE_VERIFICATION_SUCCESS,
  TERMS_AND_CONDITIONS_ACCEPTED,
  USER_ALREADY_ASSOCIATED_WITH_ORGANIZATION,
  VERIFICATION_CODE_FAILURE,
  VERIFICATION_RATE_LIMITED,
} from "../../actions/constants";
import {
  ERegistrationError,
  IActionRegistration,
  IRegistrationAccountData,
  IRegistrationData,
  IRegistrationJWT,
  IRegistrationPhoneNumber,
  IRegistrationQueryParams,
} from "../../interfaces/interfaces";

export default (
  state = {
    accountCompleted: false,
    birthdate: "",
    email: "",
    emailJWT: "",
    emailVerified: false,
    error: ERegistrationError.noError,
    familyName: "",
    givenName: "",
    jwt: "",
    organizationId: "",
    organizationName: "",
    phoneNumber: "",
    phoneVerified: false,
    sendVerificationTextJWT: "",
    termsAccepted: false,
    userId: "",
  },
  action: IActionRegistration
): IRegistrationData => {
  switch (action.type) {
    case TERMS_AND_CONDITIONS_ACCEPTED: {
      return {
        ...state,
        termsAccepted: true,
      };
    }
    case ADD_MFA_DEVICE_SUCCESS: {
      const payload = action.payload as IRegistrationJWT;
      return {
        ...state,
        jwt: payload.jwt,
        phoneVerified: true,
      };
    }
    case ADD_PHONE_NUMBER: {
      const payload = action.payload as IRegistrationPhoneNumber;

      return {
        ...state,
        error: ERegistrationError.noError,
        phoneNumber: payload.phoneNumber,
      };
    }
    case ADD_USER_TO_ORGANIZATION_SUCCESS: {
      const payload = action.payload as IRegistrationJWT;

      return {
        ...state,
        accountCompleted: true,
        error: ERegistrationError.noError,
        jwt: payload.jwt,
      };
    }
    case CLEAR_REGISTRATION_ERROR: {
      return {
        ...state,
        error: ERegistrationError.noError,
      };
    }
    case PASSWORD_MISMATCH_REGISTRATION_ERROR: {
      return {
        ...state,
        error: ERegistrationError.passwordMismatch,
      };
    }
    case CREATE_USER_SUCCESS: {
      const payload = action.payload as IRegistrationAccountData;

      return {
        ...state,
        birthdate: payload.birthdate,
        error: ERegistrationError.noError,
        familyName: payload.familyName,
        givenName: payload.givenName,
        jwt: payload.jwt,
        sendVerificationTextJWT: payload.jwt,
        userId: payload.userId,
      };
    }
    case GET_QUERY_PARAMS: {
      const payload = action.payload as IRegistrationQueryParams;

      return {
        ...state,
        email: payload.email,
        emailJWT: payload.jwt,
      };
    }
    case EMAIL_VERIFICATION_SENT: {
      const payload = action.payload as IRegistrationJWT;

      return {
        ...state,
        jwt: payload.jwt,
      };
    }
    case EMAIL_VERIFICATION_SUCCESS: {
      const payload = action.payload as IRegistrationAccountData;

      return {
        ...state,
        birthdate: payload.birthdate,
        emailVerified: true,
        error: ERegistrationError.noError,
        familyName: payload.familyName,
        givenName: payload.givenName,
        jwt: payload.jwt,
        organizationId: payload.organizationId,
        organizationName: payload.organizationName,
        sendVerificationTextJWT: payload.jwt,
        userId: payload.userId,
      };
    }
    case PHONE_VERIFICATION_SENT: {
      const payload = action.payload as IRegistrationJWT;

      return {
        ...state,
        jwt: payload.jwt,
      };
    }
    case PHONE_VERIFICATION_SUCCESS: {
      const payload = action.payload as IRegistrationJWT;
      return {
        ...state,
        jwt: payload.jwt,
        sendVerificationTextJWT: "",
      };
    }
    case BIRTHDATE_FORMAT_INVALID: {
      return {
        ...state,
        error: ERegistrationError.birthdateFormatInvalid,
      };
    }
    case BIRTHDATE_INVALID: {
      return {
        ...state,
        error: ERegistrationError.birthdateInvalid,
      };
    }
    case EXPIRED_INVITATION: {
      return {
        ...state,
        error: ERegistrationError.expiredInvitation,
      };
    }
    case FAMILY_NAME_INVALID: {
      return {
        ...state,
        error: ERegistrationError.familyNameInvalid,
      };
    }
    case GIVEN_NAME_INVALID: {
      return {
        ...state,
        error: ERegistrationError.givenNameInvalid,
      };
    }
    case PASSWORD_INVALID: {
      return {
        ...state,
        error: ERegistrationError.passwordInvalid,
      };
    }
    case VERIFICATION_RATE_LIMITED: {
      return {
        ...state,
        error: ERegistrationError.verificationRateLimited,
      };
    }
    case USER_ALREADY_ASSOCIATED_WITH_ORGANIZATION: {
      return {
        ...state,
        error: ERegistrationError.userAlreadyAssociatedWithOrganization,
      };
    }
    case VERIFICATION_CODE_FAILURE: {
      return {
        ...state,
        error: ERegistrationError.isWrongCode,
      };
    }
    default:
      return state;
  }
};
