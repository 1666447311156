import { useEffect, useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import _ from "lodash";
import { useReactiveVar } from "@apollo/client";

import { ReactComponent as ClockFilled } from "../../img/ClockFilled.svg";
import {
  IRemoteLockHome,
  IRemoteLockIntegrationDevice,
} from "../../interfaces/interfaces";
import {
  isEditAccessDevicesInProgressVar,
  showRemoteLockUpdateInProgressModalVar,
} from "../apollo/LocalState";
import {
  remoteLockDeviceType,
  sortAccessDevicesByName,
} from "../../utils/integrationUtils";
import Tooltip from "../../utils/Tooltip";

import EditAccessDevicesCell from "./EditAccessDevicesCell";
import { getRemoteLockIntegrationDevice } from "./EditAccessDevicesDropdown";
import { getRemoteLockDoorGroupIntegration } from "./EditDoorGroupsDropdown";

export interface IRemoteLockTableRowProps {
  unitAvailableDevices: IRemoteLockIntegrationDevice[];
  availableDoorDevices: IRemoteLockIntegrationDevice[];
  home: IRemoteLockHome;
  handleClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isRowSelected: boolean;
  isHomeChangeInProgress: boolean;
  halfOpacityClass: string;
  selectionInProgress: boolean;
  selectedHomes: IRemoteLockHome[];
  startPolling: (pollInterval: number) => void;
  deselectAllHomes: () => void;
  buildingId: string;
  modalRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const RemoteLockTableRow: React.FC<IRemoteLockTableRowProps> = ({
  unitAvailableDevices,
  availableDoorDevices,
  home,
  handleClick,
  isRowSelected,
  halfOpacityClass,
  selectionInProgress,
  selectedHomes,
  startPolling,
  isHomeChangeInProgress,
  deselectAllHomes,
  buildingId,
  modalRef,
}) => {
  const remotelockUnitAccessDevices = sortAccessDevicesByName(
    _.map(home.remotelockUnitAccessDevices, (element) => {
      return getRemoteLockIntegrationDevice(element);
    })
  );

  const remotelockDoorGroups = sortAccessDevicesByName(
    _.map(home.remotelockDoorGroups, (element) => {
      return getRemoteLockDoorGroupIntegration(element);
    })
  );

  const isEditAccessDevicesInProgress = useReactiveVar(
    isEditAccessDevicesInProgressVar
  );

  const [
    remotelockUnitAccessDevicesState,
    setRemotelockUnitAccessDevicesState,
  ] = useState<IRemoteLockIntegrationDevice[]>(remotelockUnitAccessDevices);
  const [remotelockDoorGroupsState, setRemotelockDoorGroupsState] =
    useState<IRemoteLockIntegrationDevice[]>(remotelockDoorGroups);

  const unselectedUnitClass =
    !isRowSelected && selectionInProgress ? "unselected-unit-name" : "";
  const [showUnitAccessDevicesDropdown, setShowUnitAccessDevicesDropdown] =
    useState(false);
  const [showCommonAccessDevicesDropdown, setShowCommonAccessDevicesDropdown] =
    useState(false);

  useEffect(() => {
    setRemotelockUnitAccessDevicesState(remotelockUnitAccessDevices);
    setRemotelockDoorGroupsState(remotelockDoorGroups);
  }, [home]);
  // Filter out selected access devices from all access devices to get available access devices
  const availableDevicesForUnits = _.differenceBy(
    unitAvailableDevices,
    remotelockUnitAccessDevices,
    "id"
  );
  const availableDoorGroups = _.differenceBy(
    availableDoorDevices,
    remotelockDoorGroups,
    "id"
  );

  return (
    <TableRow>
      <TableCell
        onClick={() => {
          if (isHomeChangeInProgress) {
            showRemoteLockUpdateInProgressModalVar(true);
          }
        }}
      >
        <div
          className={`center-align-as-row remote-lock-cell-container remote-lock-cell-container-small ${unselectedUnitClass} ${
            isHomeChangeInProgress &&
            "remote-lock-table-unit-disabled remote-lock-table-unit-disabled-changes-in-progress"
          }`}
        >
          {isHomeChangeInProgress ? (
            <ClockFilled
              className="remote-lock-table-clock"
              title="Filled Clock"
            />
          ) : (
            <input
              type="checkbox"
              id={home.id}
              className="remote-lock-table-checkbox"
              onChange={handleClick}
              checked={isRowSelected}
              disabled={isEditAccessDevicesInProgress}
            />
          )}
          <div className="tooltip center-align-tooltip-as-row">
            <Tooltip tooltipText={home.propertyName}>
              <label
                htmlFor={home.id}
                className="placeholder tooltip remote-lock-table-unit"
              >
                {home.propertyName}
              </label>
            </Tooltip>
          </div>
        </div>
      </TableCell>
      <EditAccessDevicesCell
        deviceType={remoteLockDeviceType.unitDevice}
        propertyId={home.id}
        availableDevices={availableDevicesForUnits}
        assignedAccessDevices={remotelockUnitAccessDevicesState}
        selectionInProgress={selectionInProgress}
        isRowSelected={isRowSelected}
        showEditAccessDevicesDropdown={showUnitAccessDevicesDropdown}
        setShowEditAccessDevicesDropdown={setShowUnitAccessDevicesDropdown}
        halfOpacityClass={halfOpacityClass}
        selectedHomes={selectedHomes}
        startPolling={startPolling}
        deselectAllHomes={deselectAllHomes}
        isHomeChangeInProgress={isHomeChangeInProgress}
        buildingId={buildingId}
        propertyName={home.propertyName}
        modalRef={modalRef}
      />
      <EditAccessDevicesCell
        deviceType={remoteLockDeviceType.doorGroup}
        propertyId={home.id}
        availableDevices={availableDoorGroups}
        assignedAccessDevices={remotelockDoorGroupsState}
        selectionInProgress={selectionInProgress}
        isRowSelected={isRowSelected}
        showEditAccessDevicesDropdown={showCommonAccessDevicesDropdown}
        setShowEditAccessDevicesDropdown={setShowCommonAccessDevicesDropdown}
        halfOpacityClass={halfOpacityClass}
        selectedHomes={selectedHomes}
        startPolling={startPolling}
        deselectAllHomes={deselectAllHomes}
        isHomeChangeInProgress={isHomeChangeInProgress}
        buildingId={buildingId}
        propertyName={home.propertyName}
        modalRef={modalRef}
      />
    </TableRow>
  );
};

export default RemoteLockTableRow;
