import { useReactiveVar } from "@apollo/client";
import { useEffect } from "react";

import { ReactComponent as CloseIcon } from "../../../img/Close.svg";
import { ReactComponent as TenantsImg } from "../../../img/Tenants.svg";
import { ReactComponent as Trash } from "../../../img/Trash.svg";
import {
  EUnitDetailsFilter,
  EUserManagementGenericError,
  IIntegrationsNameAndLink,
} from "../../../interfaces/interfaces";
import {
  CANCEL_INVITED_TENANT_STEP,
  REMOVE_REGISTERED_TENANT_STEP_ONE,
  REMOVE_REGISTERED_TENANT_STEP_TWO,
  SHOW_TENANT_STEP,
} from "../TenantManagementSteps";
import EntrataBanner from "../EntrataBanner";
import {
  selectedUserToShowVar,
  userManagementGenericErrorVar,
} from "../../apollo/LocalState";
import { ReactComponent as Gear } from "../../../img/settings.svg";
import { MoveOutConfirmedView } from "../moveOut/MoveOutModalWizard";

import RemoveInvitedTenant from "./RemoveInvitedTenant";
import ShowTenant from "./ShowTenant";
import TenantMoveOut from "./TenantMoveOut";

export interface EditTenantModalWizardProps {
  closeRemoveTenantModal: () => void;
  propertyName: string;
  unitName: string;
  propertyId: string;
  homeId: string;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setUnitDetailsFilter: React.Dispatch<
    React.SetStateAction<EUnitDetailsFilter>
  >;
  hasIntegration: boolean;
  integrationNameLink: IIntegrationsNameAndLink;
  hasRemoteLockIntegration: boolean;
}

const renderHeaderImage = (currentStep: number) => {
  if (currentStep === SHOW_TENANT_STEP) {
    return (
      <Gear
        className="tenants-show-icons tenants-show-icons-gear"
        title="Edit Resident"
      />
    );
  }
  if (
    currentStep === CANCEL_INVITED_TENANT_STEP ||
    currentStep === REMOVE_REGISTERED_TENANT_STEP_ONE
  ) {
    return (
      <Trash className="tenants-show-icons" title="Remove Invited Resident" />
    );
  }
  return <TenantsImg title="Residents" />;
};

const renderHeader = (currentStep: number) => {
  if (currentStep === CANCEL_INVITED_TENANT_STEP) {
    return <b>Cancel Resident Invite</b>;
  }
  if (currentStep === REMOVE_REGISTERED_TENANT_STEP_ONE) {
    return <b>Remove Resident</b>;
  }
  if (currentStep === REMOVE_REGISTERED_TENANT_STEP_TWO) {
    return <b>Move Out Confirmed</b>;
  }
  return <b>Manage Resident</b>;
};

const EditTenantModalWizard: React.FC<EditTenantModalWizardProps> = ({
  closeRemoveTenantModal,
  propertyName,
  unitName,
  propertyId,
  homeId,
  currentStep,
  setCurrentStep,
  setUnitDetailsFilter,
  hasIntegration,
  integrationNameLink,
  hasRemoteLockIntegration,
}) => {
  const selectedUserToShow = useReactiveVar(selectedUserToShowVar);
  useEffect(() => {
    setUnitDetailsFilter(EUnitDetailsFilter.residents);
    setCurrentStep(SHOW_TENANT_STEP);
    return () => {
      userManagementGenericErrorVar(EUserManagementGenericError.noError);
    };
  }, []);
  let isRegisteredUser = false;
  if (selectedUserToShow) {
    isRegisteredUser = "givenName" in selectedUserToShow;
  }
  const hasInvitation = !isRegisteredUser;
  const emailAddress = selectedUserToShow?.emailAddress ?? "";
  const userId = selectedUserToShow?.id ?? "";

  return (
    <div className="popup-background">
      <div className="tenants-show-container align-as-column">
        <div className="tenants-show-header">
          <div className="center-align-as-row">
            {renderHeaderImage(currentStep)}
            <div className="tenants-show-header-title menu">
              {renderHeader(currentStep)}
              <div className="tenants-show-header-title-name">
                : {unitName}, {propertyName}
              </div>
            </div>
          </div>
          <CloseIcon
            onClick={() => {
              closeRemoveTenantModal();
              setCurrentStep(SHOW_TENANT_STEP);
              setUnitDetailsFilter(EUnitDetailsFilter.brilliants);
            }}
            className="changes-header-img"
            title="close"
          />
        </div>
        {currentStep === SHOW_TENANT_STEP && (
          <>
            {hasIntegration && (
              <>
                <EntrataBanner
                  text={
                    <>
                      Please go to <b>{integrationNameLink.name}</b> to manage
                      this resident.
                    </>
                  }
                />
                <br />
              </>
            )}
            <ShowTenant
              increaseCurrentStep={() => {
                if (hasInvitation) {
                  setCurrentStep(CANCEL_INVITED_TENANT_STEP);
                } else {
                  setCurrentStep(REMOVE_REGISTERED_TENANT_STEP_ONE);
                }
              }}
              hasRemoteLockIntegration={hasRemoteLockIntegration}
              hasInvitation={hasInvitation}
              hasIntegration={hasIntegration}
              closeRemoveTenantModal={closeRemoveTenantModal}
              propertyId={propertyId}
              emailAddress={emailAddress}
              userId={userId}
            />
          </>
        )}
        {currentStep === CANCEL_INVITED_TENANT_STEP && (
          <RemoveInvitedTenant
            closeRemoveTenantModal={closeRemoveTenantModal}
            decreaseCurrentStep={() => {
              setCurrentStep(SHOW_TENANT_STEP);
            }}
            propertyId={propertyId}
            emailAddress={emailAddress}
          />
        )}
        {currentStep === REMOVE_REGISTERED_TENANT_STEP_ONE && (
          <TenantMoveOut
            increaseCurrentStep={() => {
              setCurrentStep(REMOVE_REGISTERED_TENANT_STEP_TWO);
            }}
            closeRemoveTenantModal={closeRemoveTenantModal}
            decreaseCurrentStep={() => {
              setCurrentStep(SHOW_TENANT_STEP);
            }}
            propertyId={propertyId}
            homeId={homeId}
          />
        )}
        {currentStep === REMOVE_REGISTERED_TENANT_STEP_TWO && (
          <MoveOutConfirmedView />
        )}
      </div>
    </div>
  );
};

export default EditTenantModalWizard;
