import { useEffect } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import IdleTimer from "react-idle-timer";
import { OptimizelyProvider } from "@optimizely/react-sdk";
import { useReactiveVar } from "@apollo/client";

import history from "../history";
import {
  brilliantUserToken,
  brilliantUserTokenWithLoader,
  logOutUser,
} from "../actions";
import { IAppState, ILoginData } from "../interfaces/interfaces";
import optimizely from "../OptimizelySetUp";
import { oneMinInMs } from "../utils/utils";

import {
  isAlertSideMenuVisibleVar,
  isSideMenuVisibleVar,
} from "./apollo/LocalState";
import Login from "./login/Login";
import Registration from "./registration/Registration";
import Spinner from "./Spinner";
import HeaderHelper from "./header/HeaderHelper";
import Footer from "./footer/Footer";
import Dashboard from "./dashboard/Dashboard";
import PropertyDetails from "./propertyDetails/PropertyDetails";
import UnitDetails from "./unitDetails/UnitDetails";
import Configure from "./configurations/Configure";
import ConfigurationsDetails from "./configurations/ConfigurationsDetails";
import ErrorsComponent, { NotFoundErrorComponent } from "./errors/Error";
import ToastMessages from "./ToastMessages";
import TenantRegistration from "./registration/tenantRegistration/TenantRegistration";
import AccountDetails from "./account/AccountDetails";
import EditManagers from "./adminManagement/EditManagers";
import AlertSideBar from "./alertManagement/AlertsSideBar";
import SideMenu from "./header/SideMenu";
import EntrataDetailsPage from "./integrations/EntrataDetailsPage";
import RealPageDetailsPage from "./integrations/RealPageDetailsPage";
import RemoteLock from "./integrations/RemoteLock";
import EntrataSuccess from "./login/entrata/EntrataSuccess";
import EntrataError from "./login/entrata/EntrataError";
import RemoteLockSuccess from "./integrations/RemoteLockSuccess";
import UserRegistration from "./registration/userRegistration/UserRegistration";
import InvitedUserRegistration from "./registration/userRegistration/InvitedUserRegistration";
import AlertPreferencesLandingPage from "./alertManagement/preferences/AlertPreferencesLandingPage";

const App: React.FC<{
  user: ILoginData;
  loading: boolean;
}> = (props) => {
  const { user, loading } = props;
  const dispatch = useDispatch();

  const isSideMenuVisible = useReactiveVar(isSideMenuVisibleVar);
  const isAlertSideMenuVisible = useReactiveVar(isAlertSideMenuVisibleVar);

  useEffect(() => {
    dispatch(brilliantUserTokenWithLoader());
    setInterval(() => {
      dispatch(brilliantUserToken());
    }, oneMinInMs * 5);
  }, [dispatch, oneMinInMs]);

  // Will replace setAttribute with actual color from backend.
  // const root = document.querySelector(":root") as Element;
  // root.setAttribute("style", "--app-color: red");

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        attributes: {
          customerEmail: user.email,
        },
        id: user.userId,
      }}
    >
      <Router history={history}>
        <div className="main-container">
          {loading && <Spinner />}
          <>
            {isSideMenuVisible && <SideMenu />}
            <Switch>
              <Route path="/entrata/link" component={Login} />
              <Route path="/register" component={Registration} />
              <Route path="/register-tenant" component={TenantRegistration} />
              <Route path="/create-user-account" component={UserRegistration} />
              <Route
                path="/create-invited-user-account"
                component={InvitedUserRegistration}
              />
              <Route path="/entrata/success" component={EntrataSuccess} />
              <Route path="/entrata/error" component={EntrataError} />
              <Route path="/remotelock/success" component={RemoteLockSuccess} />
              {user.isAuthenticated ? (
                <div>
                  <ToastMessages />
                  <IdleTimer
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onIdle={() => dispatch<any>(logOutUser())}
                    timeout={oneMinInMs * 30}
                  />
                  <HeaderHelper />
                  <div className="app-body app-body-side-bar">
                    <div
                      className={`${
                        isAlertSideMenuVisible && "app-body-hidden"
                      }`}
                    >
                      <Switch>
                        <Route path="/" exact>
                          <Dashboard />
                        </Route>
                        <Route path="/configurations" exact>
                          <Configure />
                        </Route>
                        <Route
                          path="/configurations/:configurationId"
                          component={ConfigurationsDetails}
                        />
                        <Route path="/entrata" component={EntrataDetailsPage} />
                        <Route
                          path="/alertPreferences/:buildingId"
                          component={AlertPreferencesLandingPage}
                        />
                        <Route
                          path="/alertPreferences"
                          component={AlertPreferencesLandingPage}
                        />
                        <Route
                          path="/realPage"
                          component={RealPageDetailsPage}
                        />
                        <Route
                          path="/remoteLock/:buildingId"
                          component={RemoteLock}
                        />
                        <Route path="/remoteLock" component={RemoteLock} />
                        <Route
                          path="/buildings/:buildingId"
                          exact
                          component={PropertyDetails}
                        />
                        <Route
                          path="/units/:propertyId"
                          component={UnitDetails}
                        />
                        <Route path="/managers" component={EditManagers} />
                        <Route
                          path="/accountDetails"
                          component={AccountDetails}
                        />
                        <Route
                          path="/errors/:status"
                          component={ErrorsComponent}
                        />
                        <Route path="*" component={NotFoundErrorComponent} />
                      </Switch>
                      <Footer />
                    </div>
                    {isAlertSideMenuVisible && <AlertSideBar />}
                  </div>
                </div>
              ) : (
                <Route path="/" component={Login} />
              )}
            </Switch>
          </>
        </div>
      </Router>
    </OptimizelyProvider>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    loading: state.loading,
    user: state.user,
  };
};

export default connect(mapStateToProps)(App);
