import { ReactComponent as RealPageLogo } from "../../img/logos/RealPageLogo.svg";
import BaseModal from "../modals/BaseModal";
import { OpenRealPageLink } from "../../utils/integrationUtils";
import { LEARN_MORE_REALPAGE } from "../../utils/LearnMoreLinks";

export interface IRealPageLogInModalProps {
  closeLogInModal: () => void;
}

const RealPageLogInModal: React.FC<IRealPageLogInModalProps> = ({
  closeLogInModal,
}) => {
  return (
    <BaseModal
      actionButtonFn={() => window.open(OpenRealPageLink)}
      actionButtonText="Open RealPage"
      closeModalFn={closeLogInModal}
      headerText="Add Integration"
      learnMoreLink={LEARN_MORE_REALPAGE}
    >
      <RealPageLogo title="RealPage" />
      <div className="menu menu-light remote-lock-login-body-text">
        Contact Brilliant to link your properties managed in RealPage.
        <br />
        <br />
        After your RealPage properties are linked, Brilliant will automatically
        synchronize resident leases and other information provided in RealPage
        for your linked properties.
      </div>
    </BaseModal>
  );
};

export default RealPageLogInModal;
