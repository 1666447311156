import styled from "styled-components";

interface Props {
  canModify: boolean;
}

export interface ConfigurationsButtonProps extends Props {
  buttonText: string;
}

const Button = styled.div<Props>`
  opacity: ${(props) => (props.canModify ? "0.5" : "1")};
`;
const ConfigurationsButton: React.FC<ConfigurationsButtonProps> = ({
  buttonText,
  canModify,
}) => {
  return (
    <div className="configuration-button-container">
      <Button canModify={canModify}>
        <div className="menu">{buttonText}</div>
      </Button>
    </div>
  );
};

export default ConfigurationsButton;
