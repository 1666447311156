import { TableCell } from "@mui/material";
import { useEffect, useState } from "react";
import { useReactiveVar } from "@apollo/client";

import {
  isEditAccessDevicesInProgressVar,
  showRemoteLockUpdateInProgressModalVar,
} from "../apollo/LocalState";
import {
  IRemoteLockHome,
  IRemoteLockIntegrationDevice,
} from "../../interfaces/interfaces";
import { remoteLockDeviceType } from "../../utils/integrationUtils";

import AccessDevicesCell from "./AccessDevicesCell";
import EditAccessDevicesDropdown from "./EditAccessDevicesDropdown";
import EditDoorGroupsDropdown from "./EditDoorGroupsDropdown";

export interface IEditAccessDevicesCellProps {
  deviceType: string;
  propertyId: string;
  assignedAccessDevices: IRemoteLockIntegrationDevice[];
  availableDevices: IRemoteLockIntegrationDevice[];
  selectionInProgress: boolean;
  isRowSelected: boolean;
  showEditAccessDevicesDropdown: boolean;
  setShowEditAccessDevicesDropdown: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  halfOpacityClass: string;
  selectedHomes: IRemoteLockHome[];
  startPolling: (pollInterval: number) => void;
  deselectAllHomes: () => void;
  isHomeChangeInProgress: boolean;
  buildingId: string;
  propertyName: string;
  modalRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const EditAccessDevicesCell: React.FC<IEditAccessDevicesCellProps> = ({
  deviceType,
  propertyId,
  availableDevices,
  assignedAccessDevices,
  selectionInProgress,
  isRowSelected,
  showEditAccessDevicesDropdown,
  setShowEditAccessDevicesDropdown,
  halfOpacityClass,
  selectedHomes,
  startPolling,
  deselectAllHomes,
  isHomeChangeInProgress,
  buildingId,
  propertyName,
  modalRef,
}) => {
  const isDoorGroup = deviceType === remoteLockDeviceType.doorGroup;

  const [selectedAccessDevices, setSelectedAccessDevices] = useState<
    IRemoteLockIntegrationDevice[]
  >(assignedAccessDevices);

  useEffect(() => {
    setSelectedAccessDevices(assignedAccessDevices);
  }, [assignedAccessDevices]);

  const isEditAccessDevicesInProgress = useReactiveVar(
    isEditAccessDevicesInProgressVar
  );

  const isRowWithUnitDevicesSelected = !isDoorGroup && isRowSelected;
  const halfOpacityForUnselectedRowOrSelectedUnitDevices =
    (!isRowSelected || isRowWithUnitDevicesSelected) && halfOpacityClass;

  return (
    <TableCell
      // EditAccessDevices component opens if no other EditAccessDevices component are open.
      // If any row is selected unselected EditAccessDevices components are disabled.
      onClick={() => {
        if (isHomeChangeInProgress) {
          showRemoteLockUpdateInProgressModalVar(true);
          return;
        }
        if (!isEditAccessDevicesInProgress) {
          if (
            selectionInProgress &&
            (!isRowSelected || (isRowSelected && !isDoorGroup))
          )
            return;
          setShowEditAccessDevicesDropdown(true);
          isEditAccessDevicesInProgressVar(true);
        }
      }}
    >
      {showEditAccessDevicesDropdown && !isDoorGroup && (
        <EditAccessDevicesDropdown
          availableDevices={availableDevices}
          propertyId={propertyId}
          setShowEditAccessDevicesDropdown={setShowEditAccessDevicesDropdown}
          setSelectedAccessDevices={setSelectedAccessDevices}
          selectedAccessDevices={selectedAccessDevices}
          startPolling={startPolling}
          deselectAllHomes={deselectAllHomes}
          originallyAssignedAccessDevices={assignedAccessDevices}
          buildingId={buildingId}
          propertyName={propertyName}
          modalRef={modalRef}
        />
      )}
      {showEditAccessDevicesDropdown && isDoorGroup && (
        <EditDoorGroupsDropdown
          availableDoorGroups={availableDevices}
          propertyId={propertyId}
          setShowEditDoorGroupsDropdown={setShowEditAccessDevicesDropdown}
          setSelectedAccessDevices={setSelectedAccessDevices}
          selectedAccessDevices={selectedAccessDevices}
          selectedHomes={selectedHomes}
          startPolling={startPolling}
          deselectAllHomes={deselectAllHomes}
          buildingId={buildingId}
          propertyName={propertyName}
          modalRef={modalRef}
        />
      )}
      <div
        className={`remote-lock-cell-container ${
          isRowWithUnitDevicesSelected ? "selected-unit-access-devices" : ""
        } ${halfOpacityForUnselectedRowOrSelectedUnitDevices} ${
          isHomeChangeInProgress &&
          "remote-lock-table-unit-disabled remote-lock-table-unit-disabled-changes-in-progress"
        }`}
      >
        <AccessDevicesCell
          accessDevices={selectedAccessDevices}
          isCommonAreaDevices={isDoorGroup}
          isRowWithUnitDevicesSelected={isRowWithUnitDevicesSelected}
          halfOpacityClass={halfOpacityClass}
        />
      </div>
    </TableCell>
  );
};

export default EditAccessDevicesCell;
