import { ReactComponent as BigRedAlert } from "../../img/Big Red Alert.svg";

const RedAlertMessage: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <div className="tenants-show-description-alert">
      <div
        className="tenants-show-description-alert-icon"
        data-testid="tenants-show-description-alert-icon"
      >
        <BigRedAlert title="Alert" />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default RedAlertMessage;
