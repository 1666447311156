import LoginResultModal from "../../modals/LoginResultModal";
import { ReactComponent as Logo } from "../../../img/logos/Brilliant.svg";
import { ReactComponent as Multiply } from "../../../img/Close.svg";
import { ReactComponent as EntrataLogo } from "../../../img/logos/EntrataRed.svg";

export const renderLogo = (): JSX.Element => {
  return (
    <div className="center-align-as-row">
      <Logo className="brilliant-logo" />
      <Multiply className="multiply-icon" />
      <EntrataLogo className="entrata-logo" />
    </div>
  );
};

const EntrataSuccess: React.FC = () => {
  return (
    <LoginResultModal logo={renderLogo()} title="Success!">
      You have successfully linked one or more properties managed in Entrata
      with Brilliant.
      <br />
      <br />
      Brilliant will now automatically transition units in each of these
      properties using lease info provided in Entrata.
    </LoginResultModal>
  );
};

export default EntrataSuccess;
