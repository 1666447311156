import { connect } from "react-redux";
import { WrappedFieldProps } from "redux-form";

import { ERegistrationError, IAppState } from "../../../interfaces/interfaces";

interface CustomInputProps {
  id: string; // The `id` of the input element.
  disabled?: boolean; // Whether the field should be disabled.
  type: string; // The input type.
  placeholder?: string; // The placeholder for the input.
  registrationError: ERegistrationError; // The current registration error.
  autoComplete?: string; // The autoComplete string for input field.
  registrationErrorHighlight?: ERegistrationError[]; // The type of registration error for which the field should be highlighted.
}

const InputField: React.FC<WrappedFieldProps & CustomInputProps> = ({
  disabled,
  id,
  input,
  registrationError,
  registrationErrorHighlight,
  placeholder,
  type,
  autoComplete,
  meta: { dirty, error },
}) => {
  const inputClassName = `${
    (dirty && error) ||
    (registrationErrorHighlight &&
      registrationErrorHighlight.includes(registrationError))
      ? "right-form-input-alert "
      : ""
  }right-form-input`;

  const showError = dirty && error;

  return (
    <>
      <input
        id={id}
        className={inputClassName}
        {...input}
        autoComplete={autoComplete}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
      />
      {showError && (
        <p className="right-error right-form-field-error">{error}</p>
      )}
    </>
  );
};

InputField.defaultProps = {
  autoComplete: "",
  disabled: false,
  placeholder: "",
  registrationErrorHighlight: undefined,
};

const mapStateToProps = (state: IAppState) => {
  return {
    registrationError:
      (state.newUser && state.newUser.error) ||
      (state.newUserRegistration && state.newUserRegistration.error),
  };
};
export default connect(mapStateToProps)(InputField);
