const ConfigurationsRule: React.FC<{
  popup: boolean;
  rule: JSX.Element;
}> = ({ popup, rule }) => {
  const chooseClassName = `configuration-rule-container ${
    popup ? "configuration-rule-container-popup" : ""
  }`;
  return <div className={chooseClassName}>{rule}</div>;
};

export default ConfigurationsRule;
