import { useEffect } from "react";

import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import LearnMoreButton from "../buttons/LearnMoreButton";
import SectionTitleBar from "../common/SectionTitleBar";
import { ReactComponent as IntegrationIcon } from "../../img/EntrataIntegration.svg";
import { ReactComponent as ExternalLink } from "../../img/ExternalLink.svg";
import { smallHeaderBackArrow, smallHeaderTitle } from "../apollo/LocalState";
import { OpenRealPageLink } from "../../utils/integrationUtils";
import { renderUnitDetailsModalContent } from "../../utils/utils";
import { LEARN_MORE_REALPAGE } from "../../utils/LearnMoreLinks";

const RealPageDetailsPage: React.FC = () => {
  const crumbs = [{ name: "RealPage", path: "/realPage" }];

  useEffect(() => {
    smallHeaderTitle("RealPage");
    smallHeaderBackArrow(true);
    return () => {
      smallHeaderTitle("");
      smallHeaderBackArrow(false);
    };
  }, []);

  return (
    <div className="center-align">
      <div className="page-container-inner padding-bottom-large padding-top-small">
        <Breadcrumbs
          crumbs={crumbs}
          rootCrumb={{ name: "Configurations", path: "/configurations" }}
        />
        <SectionTitleBar
          icon={IntegrationIcon}
          title="RealPage"
          button={{
            isExternal: true,
            text: "Open RealPage",
            url: OpenRealPageLink,
          }}
        />
        <div className="integrations-body">
          <div className="header-description menu element-tab-port-hidden">
            Details
          </div>
          <div className="integrations-description-container">
            <p>
              You have successfully linked one or more properties in your
              RealPage account to Brilliant.
            </p>
            <br />
            {renderUnitDetailsModalContent({
              descriptionList: [
                "A building has been created in Brilliant for each linked RealPage property.",
                "Each building linked to a RealPage property has been automatically populated with units.",
                "Brilliant is actively tracking resident leases in your RealPage account.",
              ],
              title: "What this means:",
            })}
            {renderUnitDetailsModalContent({
              descriptionList: [
                "Your residents must now be managed entirely from RealPage.",
                "Brilliant will automatically transition your unit's devices using lease and resident info you provide in RealPage.",
              ],
              title: "Managing your integration:",
            })}
          </div>
          <div className="integrations-actions">
            <div className="integrations-actions-learn-more-btn">
              <LearnMoreButton link={LEARN_MORE_REALPAGE} />
            </div>
            <a
              className="element-tab-port-visible secondary-btn integrations-actions-button"
              href={OpenRealPageLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open RealPage
              <ExternalLink
                title="External Link"
                className="integrations-actions-button-icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealPageDetailsPage;
