import BrilliantLogo from "../../../img/logos/Brilliant.svg";
import Home from "../../../img/Home.svg";
import Apple from "../../../img/logos/Apple.png";
import GooglePlay from "../../../img/logos/GooglePlay.png";
import LearnMoreButton from "../../buttons/LearnMoreButton";

export interface SuccessPageProps {
  children?: React.ReactNode;
  isUser?: boolean;
  shouldLinksRender?: boolean;
  shouldTipsOnGettingStartedRender?: boolean;
}

const SuccessPage: React.FC<SuccessPageProps> = ({
  children,
  isUser = false,
  shouldLinksRender = true,
  shouldTipsOnGettingStartedRender = false,
}) => {
  return (
    <div
      className={`background-container ${
        isUser && "background-container-user"
      }`}
    >
      <div className="login-container">
        <div className="inner-container registration-Page">
          <img
            src={BrilliantLogo}
            alt=""
            className="registration-success-brilliant-logo"
          />
          <img src={Home} alt="" className="registration-success-key-logo" />
          <h3 className="registration-success-heading">Success!</h3>
          <div className="registration-success-text">{children}</div>
          <br />
          {shouldLinksRender && (
            <div>
              <a
                href="https://apps.apple.com/us/app/brilliant-smart-home-control/id1309322606"
                target="_blank"
                rel="noopener noreferrer"
                className="margin-right-smaller registration-success-img-link"
              >
                <img
                  className="registration-success-img-link"
                  src={Apple}
                  alt="Apple store"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=tech.brilliant.brilliant"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="registration-success-img-link"
                  src={GooglePlay}
                  alt="Google Play"
                />
              </a>
            </div>
          )}
          {shouldTipsOnGettingStartedRender && (
            <>
              <br />
              <LearnMoreButton
                classNameOverride="tips-getting-started-button"
                link="https://www.brilliant.tech/pages/smart-apartment-overview"
                text="Tips on Getting Started"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
