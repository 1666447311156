import { Link } from "react-router-dom";

import { ReactComponent as Settings } from "../../img/settings.svg";

const IntegrationCard: React.FC<{
  hasIntegration: boolean;
  linkTo: string;
  title: string;
  onSettingsClick: () => void;
}> = ({ hasIntegration, linkTo, title, onSettingsClick }) => {
  return hasIntegration ? (
    <Link
      className="configuration-card-container configuration-card-container-tappable"
      to={linkTo}
    >
      <div className="configuration-card-line">
        <div className="unit-title">{title}</div>
        <div className="configuration-card-link">
          <Settings
            data-testid={`settings-icon-${title.toLowerCase()}`}
            title="Settings"
          />
        </div>
      </div>
    </Link>
  ) : (
    <div className="configuration-card-container">
      <div className="configuration-card-line">
        <div className="unit-title">{title}</div>
        <button
          type="button"
          data-testid={`add-btn-${title.toLowerCase()}`}
          className="tertiary-btn-small configuration-card-button element-tab-port-hidden"
          onClick={onSettingsClick}
        >
          Add
        </button>
        <button
          type="button"
          className="tertiary-btn-small configuration-card-button element-tab-port-visible"
          onClick={onSettingsClick}
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default IntegrationCard;
