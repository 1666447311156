import {
  EReconfigurationError,
  EUserManagementGenericError,
} from "../../../interfaces/interfaces";
import { LEARN_MORE_INSTALL_INCOMPLETE } from "../../../utils/LearnMoreLinks";
import {
  reconfigurationErrorMessage,
  userManagementGenericErrorMessages,
} from "../../../utils/errorMessages";
import { renderUnitDetailsModalContent } from "../../../utils/utils";
import LearnMoreButton from "../../buttons/LearnMoreButton";
import RedAlertMessage from "../../common/RedAlertMessage";

export const renderTenantModalReconfigurationError = ({
  reconfigurationError,
}: {
  reconfigurationError: EReconfigurationError;
}): JSX.Element => {
  return (
    <div
      data-testid="reconfiguration-error"
      className="tenants-show-description-error alert"
    >
      {reconfigurationErrorMessage[EReconfigurationError[reconfigurationError]]}
    </div>
  );
};

export const renderTenantModalGenericError = ({
  genericError,
}: {
  genericError: EUserManagementGenericError;
}): JSX.Element => {
  return (
    <div
      data-testid="generic-error"
      className="tenants-show-description-error alert"
    >
      {
        userManagementGenericErrorMessages[
          EUserManagementGenericError[genericError]
        ]
      }
    </div>
  );
};

export const renderInstallIncompleteAlert = ({
  isMoveIn,
}: {
  isMoveIn: boolean;
}): JSX.Element => {
  return (
    <RedAlertMessage>
      {renderUnitDetailsModalContent({
        centerAlignHeaderForSmallScreen: true,
        descriptionList: [
          isMoveIn ? (
            "The unit's installation must be marked as complete before you can start a move in."
          ) : (
            <>
              In order for this unit&#39;s devices to be{" "}
              <b>automatically transitioned</b> during move ins and move outs,
              you must mark the unit&#39;s installation as complete after all of
              its devices are configured.
            </>
          ),
          <>
            To mark this unit&#39;s installation as complete, please select the
            unit while <b>vacant</b> on the Brilliant mobile app and follow the
            instructions provided under{" "}
            <b>Settings → Install Brilliant Device</b>.{" "}
            <LearnMoreButton
              classNameOverride="learn-more-button-inline"
              link={LEARN_MORE_INSTALL_INCOMPLETE}
            />
          </>,
        ],
        title:
          "This unit's installation has not been marked as complete on the Brilliant mobile app!",
      })}
    </RedAlertMessage>
  );
};
