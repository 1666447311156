import Lock from "../../img/errors/lock.svg";
import Footer from "../footer/Footer";

const InvalidInvitation: React.FC = () => {
  return (
    <div className="registration-expired-invitation">
      <img
        src={Lock}
        alt="Lock"
        className="registration-expired-invitation-img"
      />
      <h1 className="registration-expired-invitation-heading">
        Invalid Invitation
      </h1>
      <div className="registration-expired-invitation-invalid-invitation-body sub-menu">
        You are no longer authorized to view this page.
        <br />
        Please contact your administrator for help.
      </div>
      <Footer containerClassName="registration-expired-invitation-footer" />
    </div>
  );
};

export default InvalidInvitation;
