import { createRoot } from "react-dom/client";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import { ApolloProvider } from "@apollo/client";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./sass/main.scss";
import App from "./components/App";
import store from "./store";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "./ErrorBoundaries";
import history from "./history";
import Client from "./components/apollo/Client";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  release: process.env.REACT_APP_SENTRY_RELEASE,
});

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || "", {
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ErrorBoundary>
      <ApolloProvider client={Client}>
        <App />
      </ApolloProvider>
    </ErrorBoundary>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
