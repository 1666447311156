import {
  EFilterColorMethod,
  EPropertyFilter,
} from "../../interfaces/interfaces";

export interface IFilterItemProps {
  cssColorMethod: EFilterColorMethod;
  selected: boolean;
  amount: number;
  name: string;
  setFilterData: React.Dispatch<React.SetStateAction<EPropertyFilter>>;
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  filterMethod: EPropertyFilter;
  title: string;
  focusedFilter?: boolean;
  setFocusedFilter?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilterItem: React.FC<IFilterItemProps> = ({
  setFilterData,
  selected,
  Icon,
  amount,
  name,
  filterMethod,
  title,
  focusedFilter,
  setFocusedFilter,
  cssColorMethod,
}) => {
  const buttonDisabled = amount === 0;
  const containerSelectedClass = selected ? "filter-container-selected" : "";
  const nameSelectedClass = selected
    ? `filter-name-selected sub-menu-selected`
    : "";
  const cssSelectedClass =
    cssColorMethod === EFilterColorMethod.STROKE
      ? "icon-selected-stroke"
      : "icon-selected-fill";
  const iconSelectedClass = selected ? `icon-selected ${cssSelectedClass}` : "";
  const amountSelectedClass = selected ? "filter-number-selected" : "";
  const disabledContainerClass = buttonDisabled
    ? "filter-container-disabled"
    : "";
  const disabledNameClass = buttonDisabled ? "filter-number-empty" : "";
  return (
    <button
      data-testid={`${title}-filter`}
      className={`filter-container ${containerSelectedClass} ${disabledContainerClass}`}
      onClick={() => {
        if (!buttonDisabled) {
          window.scrollTo(0, 0);
          setFilterData(filterMethod);
        }
        if (focusedFilter !== undefined && setFocusedFilter) {
          setFocusedFilter(true);
        }
      }}
      type="button"
    >
      <div className="flex-with-space-between">
        <div className="center-align-as-row">
          <Icon title={title} className={`icon ${iconSelectedClass}`} />
          <div className={`sub-menu ${nameSelectedClass} ${disabledNameClass}`}>
            {name}
          </div>
        </div>
        <div className={`menu-light menu filter-number ${amountSelectedClass}`}>
          {amount}
        </div>
      </div>
    </button>
  );
};

export default FilterItem;
