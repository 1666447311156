import { ISelectedScheduledConfiguration } from "../../interfaces/interfaces";
import { SELECT_SCHEDULED_CONFIGURATION } from "../../actions/actionCreators/scheduledConfiguration";

export interface IActionScheduledConfiguration {
  type: string;
  payload: ISelectedScheduledConfiguration;
}

export default (
  state = {
    createdDate: "",
    date: "",
    id: "",
    showModal: false,
    time: "",
    title: "",
  },
  action: IActionScheduledConfiguration
): ISelectedScheduledConfiguration => {
  switch (action.type) {
    case SELECT_SCHEDULED_CONFIGURATION:
      return action.payload;
    default:
      return state;
  }
};
