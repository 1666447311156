import {
  CLEAR_INVITED_USER_ERROR,
  INVITED_USER_ALREADY_ASSOCIATED_WITH_ORGANIZATION,
} from "../../actions/constants";
import {
  EUserManagementError,
  IActionTypeOnly,
  IUserManagementData,
} from "../../interfaces/interfaces";

export default (
  state = {
    invitedUserError: EUserManagementError.noError,
  },
  action: IActionTypeOnly
): IUserManagementData => {
  switch (action.type) {
    case INVITED_USER_ALREADY_ASSOCIATED_WITH_ORGANIZATION: {
      return {
        ...state,
        invitedUserError:
          EUserManagementError.invitedUserAlreadyAssociatedWithOrganization,
      };
    }
    case CLEAR_INVITED_USER_ERROR: {
      return {
        ...state,
        invitedUserError: EUserManagementError.noError,
      };
    }
    default:
      return state;
  }
};
