import { useQuery } from "@apollo/client";

import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import Spinner from "../Spinner";
import { IConfigurationDetails } from "../../interfaces/interfaces";
import { GET_CONFIGURATION_DETAILS } from "../../api/gqlQueries";

import ConfigurationsHeader from "./ConfigurationsHeader";
import ConfigurationsRule from "./ConfigurationsRule";
import ConfigurationsButton from "./ConfigurationsButton";

interface IConfigurationDetailsVariables {
  id: string;
}

const VACANT = "Vacant";

const zeroRule = (
  <div className="center-align-as-row menu-light menu">
    Set up&nbsp; <ConfigurationsButton buttonText="Thermostat" canModify />
    &nbsp;
    <ConfigurationsButton buttonText="Cool" canModify /> &nbsp;to&nbsp;
    <ConfigurationsButton buttonText="65" canModify />
  </div>
);

const firstRule = (
  <div className="center-align-as-row menu-light menu">
    Set up&nbsp; <ConfigurationsButton buttonText="Settings" canModify />
    &nbsp;
    <ConfigurationsButton buttonText="Locked" canModify />
    &nbsp;and...
  </div>
);

const secondRule = (
  <div className="center-align-as-row menu-light menu">
    Set up&nbsp; <ConfigurationsButton buttonText="Control" canModify />
    &nbsp;
    <ConfigurationsButton buttonText="Display Settings" canModify />
    &nbsp;to&nbsp;
    <ConfigurationsButton buttonText="Turn ON" canModify />
    &nbsp;
    <ConfigurationsButton buttonText="When Motion Detected" canModify />
    &nbsp;and...
  </div>
);

const thirdRule = (
  <div className="center-align-as-row menu-light menu">
    Set up&nbsp; <ConfigurationsButton buttonText="Control" canModify />
    &nbsp;
    <ConfigurationsButton buttonText="Display Settings" canModify />
    &nbsp;to&nbsp;
    <ConfigurationsButton buttonText="Turn OFF" canModify />
    &nbsp;
    <ConfigurationsButton buttonText="after 10 min of no activity" canModify />
    &nbsp;and...
  </div>
);

const fourthRule = (
  <div className="center-align-as-row menu-light menu">
    Set up&nbsp; <ConfigurationsButton buttonText="Thermostat" canModify />
    &nbsp;to&nbsp;
    <ConfigurationsButton buttonText="Heat-Cool Mode" canModify />
    &nbsp;from&nbsp;
    <ConfigurationsButton buttonText="65" canModify /> &nbsp;to&nbsp;
    <ConfigurationsButton buttonText="75" canModify />
    &nbsp;and...
  </div>
);

const fifthRule = (
  <div>
    <div className="center-align-as-row menu-light menu margin-bottom-small">
      Set up&nbsp;
      <ConfigurationsButton buttonText="Motion Settings" canModify />
      &nbsp;to&nbsp;
      <ConfigurationsButton buttonText="Per Room" canModify /> &nbsp;in&nbsp;
      <ConfigurationsButton buttonText="All Rooms" canModify />
    </div>
    <div
      style={{ marginLeft: "7rem" }}
      className="center-align-as-row menu-light menu margin-bottom-small"
    >
      When&nbsp;
      <ConfigurationsButton buttonText="Motion is detected" canModify />
      &nbsp;in a room, turn ON&nbsp;
      <ConfigurationsButton buttonText="Lights" canModify />
    </div>
    <div
      style={{ marginLeft: "6rem" }}
      className="center-align-as-row menu-light menu"
    >
      <ConfigurationsButton
        buttonText="After 30 minutes of no activity"
        canModify
      />
      &nbsp;turn OFF&nbsp;
      <ConfigurationsButton buttonText="Lights" canModify /> &nbsp;in that Room
    </div>
  </div>
);

const vacantRules = [
  { id: "firstRuleVacant", rule: firstRule },
  { id: "secondRuleVacant", rule: secondRule },
  { id: "thirdRuleVacant", rule: thirdRule },
  { id: "fourthRuleVacant", rule: fourthRule },
  { id: "fifthRuleVacant", rule: fifthRule },
];
const showUnitRules = [
  { id: "firstRuleShowUnit", rule: firstRule },
  { id: "secondRuleShowUnit", rule: secondRule },
  { id: "thirdRuleShowUnit", rule: thirdRule },
  { id: "fifthRuleShowUnit", rule: fifthRule },
];

export const renderRulesPlaceholder = (
  title: string,
  popup: boolean
): JSX.Element => {
  if (title === VACANT) {
    return (
      <>
        <div className="configuration-details-rules-name menu">
          Configuration Rules
        </div>
        <div className="configuration-details-rules-list">
          {vacantRules.map((element) => {
            return (
              <ConfigurationsRule
                key={element.id}
                popup={popup}
                rule={element.rule}
              />
            );
          })}
        </div>
      </>
    );
  }
  return (
    <>
      <div className="configuration-details-rules-name menu">
        Configuration Rules
      </div>
      <div className="configuration-details-rules-list">
        <ConfigurationsRule popup={popup} rule={zeroRule} />
      </div>
      <div className="margin-top-small" />
      <div className="configuration-details-rules-name menu">
        Vacant Settings still active
      </div>
      <div className="configuration-details-rules-list">
        {showUnitRules.map((element) => {
          return (
            <ConfigurationsRule
              key={element.id}
              popup={popup}
              rule={element.rule}
            />
          );
        })}
      </div>
    </>
  );
};

const ConfigurationsDetails: React.FC<{
  match: { params: { configurationId: string } };
}> = ({ match }) => {
  const { configurationId } = match.params;
  const { loading, data } = useQuery<
    IConfigurationDetails,
    IConfigurationDetailsVariables
  >(GET_CONFIGURATION_DETAILS, {
    variables: { id: configurationId },
  });
  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    throw Error("No data was returned");
  }
  const crumbs = [
    {
      name: data.configuration.title,
      path: "",
    },
  ];
  return (
    <div className="center-align-as-column">
      <div className="configuration-details-container">
        <Breadcrumbs
          crumbs={crumbs}
          rootCrumb={{ name: "Configuration", path: "/configurations" }}
        />
        <ConfigurationsHeader
          title={data.configuration.title}
          description={data.configuration.description}
          popup={false}
        />
        <div className="configuration-details-rules-container">
          {renderRulesPlaceholder(data.configuration.title, false)}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationsDetails;
