import { useFeature } from "@optimizely/react-sdk";
import { useEffect, useState } from "react";
import { useQuery, useReactiveVar } from "@apollo/client";
import { connect } from "react-redux";

import RemoteLockLogInModal from "../login/RemoteLockLogInModal";
import EntrataLogInModal from "../login/EntrataLogInModal";
import Spinner from "../Spinner";
import { GET_INTEGRATION_TYPES_FOR_ORGANIZATION } from "../../api/gqlQueries";
import {
  IAppState,
  IOrganizationData,
  IOrganizationParams,
} from "../../interfaces/interfaces";
import { checkIntegrations } from "../../utils/integrationUtils";
import {
  GET_REMOTE_LOCK_POLLING_STATE,
  isRemoteLockPolling,
  isRemoteLockTokenInvalidVar,
} from "../apollo/LocalState";
import InvalidTokenErrorModal from "../integrations/modals/InvalidTokenErrorModal";
import RemoteLockMobileModal from "../integrations/modals/RemoteLockMobileModal";
import RemoteLockLoginSuccessModal from "../integrations/modals/RemoteLockLoginSuccessModal";
import RealPageLogInModal from "../login/RealPageLoginModal";
import history from "../../history";
import { oneMinInMs } from "../../utils/utils";

import Configurations from "./Configurations";
import IntegrationCard from "./IntegrationCard";

export interface IConfigure {
  enableConfigurations?: boolean;
  organizationId: string;
}

const Configure: React.FC<IConfigure> = ({
  enableConfigurations = false,
  organizationId,
}) => {
  const isRemoteLockTokenInvalid = useReactiveVar(isRemoteLockTokenInvalidVar);
  const [isEnabled] = useFeature("configurations");

  // Tracks whether the user is logging into RemoteLock.
  const [attemptingLogin, setAttemptingLogin] = useState(false);
  // Controls display of the login success modal.
  const [showRemoteLockLoginSuccessModal, setShowRemoteLockLoginSuccessModal] =
    useState(false);

  const [showRemoteLockLogInModal, setShowRemoteLockLogInModal] =
    useState(false);
  const [showEntrataLogInModal, setShowEntrataLogInModal] = useState(false);
  const [showRealPageLogInModal, setShowRealPagLogInModal] = useState(false);
  const [showRemoteLockMobileModal, setShowRemoteLockMobileModal] =
    useState(false);
  const { loading, data, startPolling, stopPolling } = useQuery<
    IOrganizationData,
    IOrganizationParams
  >(GET_INTEGRATION_TYPES_FOR_ORGANIZATION, {
    variables: { organizationId },
  });
  const { data: startedRemoteLockPolling } = useQuery<{
    isRemoteLockPolling: boolean;
  }>(GET_REMOTE_LOCK_POLLING_STATE, {
    variables: { organizationId },
  });

  const closeLoginSuccessModalFn = () => {
    setShowRemoteLockLoginSuccessModal(false);
    setAttemptingLogin(false);
  };
  function stopPollingIntegrations() {
    isRemoteLockPolling(false);
    stopPolling();
  }

  let hasRemoteLockIntegration = false;
  let hasEntrataIntegration = false;
  let realPageIntegration = false;
  useEffect(() => {
    if (hasRemoteLockIntegration) {
      setShowRemoteLockLoginSuccessModal(true);
      stopPollingIntegrations();
    }
    if (startedRemoteLockPolling?.isRemoteLockPolling) {
      setTimeout(stopPollingIntegrations, oneMinInMs);
    }
  }, [data, startedRemoteLockPolling?.isRemoteLockPolling]);

  if (loading) return <Spinner />;
  if (!data) throw Error("No data was returned");

  ({
    entrataIntegration: hasEntrataIntegration,
    realPageIntegration,
    remoteLockIntegration: hasRemoteLockIntegration,
  } = checkIntegrations(data.organization.integrations));

  return (
    <div className="configuration-container">
      {isRemoteLockTokenInvalid && (
        <InvalidTokenErrorModal startPolling={startPolling} />
      )}
      {showRemoteLockLogInModal && (
        <RemoteLockLogInModal
          closeLogInModal={() => setShowRemoteLockLogInModal(false)}
          setAttemptingLoginFn={setAttemptingLogin}
          startPolling={startPolling}
        />
      )}
      {showEntrataLogInModal && (
        <EntrataLogInModal
          closeLogInModal={() => setShowEntrataLogInModal(false)}
        />
      )}
      {showRealPageLogInModal && (
        <RealPageLogInModal
          closeLogInModal={() => setShowRealPagLogInModal(false)}
        />
      )}
      {attemptingLogin && showRemoteLockLoginSuccessModal && (
        <RemoteLockLoginSuccessModal
          actionButtonFn={() => history.push("/remoteLock")}
          closeModalFn={closeLoginSuccessModalFn}
        />
      )}
      {showRemoteLockMobileModal && (
        <RemoteLockMobileModal
          closeMobileModal={() => setShowRemoteLockMobileModal(false)}
        />
      )}
      <div className="configuration-container-inner">
        {(isEnabled || enableConfigurations) && <Configurations />}
        <div>
          <div className="configuration-title menu">Integrations</div>
          <div className="configuration-list">
            <IntegrationCard
              hasIntegration={hasEntrataIntegration}
              linkTo="/entrata"
              title="Entrata"
              onSettingsClick={() => setShowEntrataLogInModal(true)}
            />
            <IntegrationCard
              hasIntegration={realPageIntegration}
              linkTo="/realPage"
              title="RealPage"
              onSettingsClick={() => setShowRealPagLogInModal(true)}
            />
            <IntegrationCard
              hasIntegration={hasRemoteLockIntegration}
              linkTo="/remoteLock"
              title="RemoteLock"
              onSettingsClick={() => setShowRemoteLockLogInModal(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    organizationId: state.user.organizationId,
  };
};

export default connect(mapStateToProps)(Configure);
