import queryString from "query-string";
import { AxiosResponse } from "axios";

import { brilliantUrl } from "./api";

export const postAddMFAPhoneNumber = (
  userId: string,
  phoneNumber: string,
  token: string
): Promise<AxiosResponse<{ token: string }>> => {
  return brilliantUrl.post(`/users/${userId}/mfa-devices/phone-number`, {
    phone_number: phoneNumber,
    token,
  });
};

export const postCreateUser = (
  birthdate: string,
  emailAddress: string,
  givenName: string,
  familyName: string,
  password: string,
  token: string
  // Disable camelcase here since these are the raw values we expect from the API.
  /* eslint-disable camelcase */
): Promise<AxiosResponse<{ token: string; user_id: string }>> => {
  return brilliantUrl.post(
    "/users",
    queryString.stringify({
      birthdate,
      email_address: emailAddress,
      family_name: familyName,
      given_name: givenName,
      password,
      token,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};
