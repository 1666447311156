import { AnyAction, Dispatch } from "redux";
import { toast } from "react-toastify";

import { LOADER_START, LOADER_STOP } from "../types";
import {
  postApplyConfiguration,
  postScheduleConfiguration,
} from "../../api/homes";
import { DATE_CHANGE, TIME_CHANGE } from "../constants";

export const updateDate = (date: string): AnyAction => {
  return {
    payload: date,
    type: DATE_CHANGE,
  };
};

export const updateTime = (time: string): AnyAction => {
  return {
    payload: time,
    type: TIME_CHANGE,
  };
};

export const applyConfiguration =
  (homeId: string, configurationId: string, refetch: () => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    toast("Making Changes", {
      className: "Toast__disable-select",
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      hideProgressBar: false,
      pauseOnHover: false,
    });
    dispatch({
      type: LOADER_START,
    });
    await postApplyConfiguration(
      homeId,
      configurationId,
      dispatch,
      async () => {
        refetch();
        dispatch({
          type: LOADER_STOP,
        });
      },
      () => {
        dispatch({
          type: LOADER_STOP,
        });
        return false;
      }
    );
  };

export const scheduleConfiguration =
  (
    homeId: string,
    configurationId: string,
    date: string,
    time: string,
    refetch: () => void
  ) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: LOADER_START,
    });
    toast("Making Changes", {
      className: "Toast__disable-select",
      closeButton: false,
      closeOnClick: false,
      draggable: false,
      hideProgressBar: false,
      pauseOnHover: false,
    });
    await postScheduleConfiguration(
      homeId,
      configurationId,
      date,
      time,
      dispatch,
      async () => {
        refetch();
        dispatch({
          type: LOADER_STOP,
        });
      },
      () => {
        dispatch({
          type: LOADER_STOP,
        });
        return false;
      }
    );
  };
