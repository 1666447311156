import { connect } from "react-redux";
import { useQuery } from "@apollo/client";

import { ReactComponent as Properties } from "../../img/Properties.svg";
import { ReactComponent as Key } from "../../img/key.svg";
import {
  IAppState,
  IOrganizationParams,
  IPropertyData,
} from "../../interfaces/interfaces";
import Spinner from "../Spinner";
import { GET_ORGANIZATION_SUMMARY } from "../../api/gqlQueries";
import { RenderEmptyHomeView } from "../../utils/utils";

import BuildingCard from "./BuildingCard";
import Menu from "./Menu";

interface IOrganizationData {
  organization: {
    numHomes: number;
    buildings: IPropertyData[];
  };
}

const Dashboard: React.FC<{
  organizationId: string;
}> = ({ organizationId }) => {
  const { loading, data } = useQuery<IOrganizationData, IOrganizationParams>(
    GET_ORGANIZATION_SUMMARY,
    { variables: { organizationId } }
  );
  if (loading) return <Spinner />;
  if (!data) throw Error("No data was returned");

  const { buildings, numHomes } = data.organization;
  const numBuildings = buildings.length;
  return (
    <div className="dashboard-container">
      <div className="dashboard-container-inner">
        <div className="dashboard-settings-container">
          <Menu
            icon={Properties}
            amount={numBuildings}
            name="Properties"
            singular="Property"
          />
          <Menu
            icon={Key}
            amount={numHomes}
            name="Rental Units"
            singular="Rental Unit"
          />
        </div>
        <div className="options">
          <div className="options-name menu">Properties</div>
          {numBuildings === 0 ? (
            <RenderEmptyHomeView
              icon={Properties}
              iconClassName="dashboard-empty-img"
              iconTitle="Empty Properties"
              componentTitle={
                "You haven't added any properties to Brilliant yet"
              }
              componentDescription="To add a property, contact Brilliant or link your property management software (PMS) system."
            />
          ) : (
            <div className="options-properties">
              {buildings.map((building: IPropertyData) => {
                return <BuildingCard key={building.id} property={building} />;
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    organizationId: state.user.organizationId,
  };
};

export default connect(mapStateToProps)(Dashboard);
