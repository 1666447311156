import Header from "./Header";
import HeaderSmall from "./HeaderSmall";

const HeaderHelper: React.FC = () => {
  return (
    <>
      <Header />
      <HeaderSmall />
    </>
  );
};

export default HeaderHelper;
