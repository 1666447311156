import { ReactComponent as RemoteLockLogo } from "../../../img/logos/RemoteLock.svg";
import BaseModal from "../../modals/BaseModal";

export interface RemoteLockLoginSuccessModalProps {
  actionButtonFn: () => void;
  closeModalFn: () => void;
}

const RemoteLockLoginSuccessModal: React.FC<
  RemoteLockLoginSuccessModalProps
> = ({ actionButtonFn, closeModalFn }) => (
  <BaseModal
    actionButtonFn={actionButtonFn}
    actionButtonText="View Integration"
    closeModalFn={closeModalFn}
    headerText="Success!"
  >
    <br />
    <RemoteLockLogo title="RemoteLock" className="remote-lock-logo" />
    <br />
    <div className="menu menu-light modal-body-text-container">
      You have successfully integrated RemoteLock with Brilliant. You can now
      assign RemoteLock access devices to your units&apos; Brilliant homes.
    </div>
    <br />
  </BaseModal>
);
export default RemoteLockLoginSuccessModal;
