import { useEffect } from "react";

import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import LearnMoreButton from "../buttons/LearnMoreButton";
import SectionTitleBar from "../common/SectionTitleBar";
import { ReactComponent as EntrataIcon } from "../../img/EntrataIntegration.svg";
import { ReactComponent as ExternalLink } from "../../img/ExternalLink.svg";
import { smallHeaderBackArrow, smallHeaderTitle } from "../apollo/LocalState";
import { ENTRATA_LINK } from "../../utils/integrationUtils";
import { LEARN_MORE_ENTRATA } from "../../utils/LearnMoreLinks";

const EntrataDetailsPage: React.FC = () => {
  const crumbs = [{ name: "Entrata", path: "/entrata" }];

  useEffect(() => {
    smallHeaderTitle("Entrata");
    smallHeaderBackArrow(true);
    return () => {
      smallHeaderTitle("");
      smallHeaderBackArrow(false);
    };
  }, []);

  return (
    <div className="center-align">
      <div className="page-container-inner padding-bottom-large padding-top-small">
        <Breadcrumbs
          crumbs={crumbs}
          rootCrumb={{ name: "Configurations", path: "/configurations" }}
        />
        <SectionTitleBar
          icon={EntrataIcon}
          title="Entrata"
          button={{
            isExternal: true,
            text: "Open Entrata",
            url: ENTRATA_LINK,
          }}
        />
        <div className="integrations-body">
          <div className="header-description menu element-tab-port-hidden">
            Details
          </div>
          <div className="integrations-description-container">
            <p>
              You have successfully linked one or more properties in your
              Entrata account to Brilliant.
            </p>
            <div className="placeholder integrations-description-text">
              What this means:
            </div>
            <ul className="integrations-ul">
              <li className="integrations-li">
                A building has been created in Brilliant for each linked Entrata
                property.
              </li>
              <li className="integrations-li">
                Each building linked to an Entrata property has been
                automatically populated with units.
              </li>
              <li className="integrations-li">
                Brilliant is actively tracking resident leases in your Entrata
                account.
              </li>
            </ul>
            <div className="placeholder integrations-description-text">
              Managing your integration:
            </div>
            <ul className="integrations-ul">
              <li className="integrations-li">
                Your residents must now be managed entirely from the Entrata
                Platform.
              </li>
              <li className="integrations-li">
                Brilliant will automatically transition your unit’s devices
                using lease and resident info you provide in Entrata.
              </li>
            </ul>
          </div>
          <div className="integrations-actions">
            <div className="integrations-actions-learn-more-btn">
              <LearnMoreButton link={LEARN_MORE_ENTRATA} />
            </div>
            <a
              className="element-tab-port-visible secondary-btn integrations-actions-button"
              href={ENTRATA_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Entrata
              <ExternalLink
                title="External Link"
                className="integrations-actions-button-icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntrataDetailsPage;
