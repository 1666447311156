import { useDispatch } from "react-redux";
import moment from "moment";

import ConfigurationsButton from "../configurations/ConfigurationsButton";
import { ReactComponent as Pencil } from "../../img/Edit.svg";
import { IScheduledConfigurationsData } from "../../interfaces/interfaces";
import { updateSelectedConfiguration } from "../../actions";

export interface ChangesActivityProps {
  scheduledConfigurations: IScheduledConfigurationsData[];
}

const ChangesActivity: React.FC<ChangesActivityProps> = ({
  scheduledConfigurations,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="align-as-column">
      <div className="brilliant-container-name menu">Changes Activity</div>
      {scheduledConfigurations.map((element: IScheduledConfigurationsData) => {
        const date = moment(element.scheduledDatetime).format("LL");
        const time = moment(element.scheduledDatetime).format("LT");
        const createdDate = moment(element.createdDatetime).format("LL");
        return (
          <button
            key={element.id}
            onClick={() => {
              dispatch(
                updateSelectedConfiguration(
                  createdDate,
                  date,
                  element.id,
                  true,
                  time,
                  element.configurationTitle
                )
              );
            }}
            type="button"
            className="unit-configurations-container"
          >
            <div className="unit-configurations-activity menu-light menu">
              <ConfigurationsButton
                buttonText={element.configurationTitle}
                canModify
              />
              &nbsp;
              {`to be applied on ${date} at ${time}`}
              <Pencil
                title="Edit"
                className="unit-configurations-activity-pencil"
              />
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default ChangesActivity;
