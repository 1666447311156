import { useMutation, useReactiveVar } from "@apollo/client";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";

import {
  REMOTE_LOCK_LARGE_UPDATE_THRESHOLD,
  remoteLockDeviceType,
  sortAccessDevicesByName,
} from "../../utils/integrationUtils";
import { quantityDisplay } from "../../utils/utils";
import {
  IAccessDevice,
  IDoorGroup,
  IRemoteLockChangedHome,
  IRemoteLockHome,
  IRemoteLockIntegrationDevice,
  IRemotelockDeviceAssignment,
  IRemotelockDeviceRemoval,
} from "../../interfaces/interfaces";
import {
  currentChangedHomesVar,
  isEditAccessDevicesInProgressVar,
  showRemoteLockLargeUpdateModalVar,
} from "../apollo/LocalState";
import { ReactComponent as CloseIcon } from "../../img/Close.svg";
import { ReactComponent as Search } from "../../img/propertyDetails/Search.svg";
import {
  ADD_REMOTELOCK_DEVICES_TO_PROPERTIES,
  REMOVE_REMOTELOCK_DEVICES_TO_PROPERTIES,
} from "../../api/gqlQueries";
import Tooltip from "../../utils/Tooltip";

// Custom hook which checks if ref element is currently in a viewport
const useOnScreen = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
  const [isIntersecting, setIntersecting] = useState(true);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    if (ref.current instanceof Element) observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

const generateRemotelockDeviceRemovals = ({
  newDoorGroupsToRemove,
  propertyId,
}: {
  newDoorGroupsToRemove: IRemoteLockIntegrationDevice[];
  propertyId: string;
}) => {
  const remotelockDeviceRemovals: IRemotelockDeviceRemoval[] = [];
  _.map(newDoorGroupsToRemove, (accessDeviceToRemove) => {
    if (accessDeviceToRemove.remotelockAccessGrantId) {
      const newDevice: IRemotelockDeviceRemoval = {
        propertyId,
        remotelockAccessGrantId: accessDeviceToRemove.remotelockAccessGrantId,
        remotelockDeviceId: accessDeviceToRemove.id,
      };
      remotelockDeviceRemovals.push(newDevice);
    }
  });
  return remotelockDeviceRemovals;
};

const generateRemotelockDeviceAssignments = ({
  newDoorGroupsToAdd,
  propertyId,
}: {
  newDoorGroupsToAdd: IRemoteLockIntegrationDevice[];
  propertyId: string;
}) => {
  const remotelockDeviceAssignments: IRemotelockDeviceAssignment[] = [];
  _.map(newDoorGroupsToAdd, (accessDevice) => {
    const newDevice: IRemotelockDeviceAssignment = {
      deviceType: remoteLockDeviceType.doorGroup,
      propertyId,
      remotelockAccessibleType: accessDevice.accessibleType,
      remotelockDeviceId: accessDevice.id,
      remotelockDeviceName: accessDevice.name,
    };
    remotelockDeviceAssignments.push(newDevice);
  });
  return remotelockDeviceAssignments;
};

export const getRemoteLockIntegrationDevice = (
  element: IAccessDevice
): IRemoteLockIntegrationDevice => {
  const { id, device } = element;
  const newDevice = {
    ...device,
    remotelockAccessGrantId: id,
  };
  return newDevice;
};

export const getRemoteLockDoorGroupIntegration = (
  doorGroupDevice: IDoorGroup
): IRemoteLockIntegrationDevice => {
  const { id, doorGroup } = doorGroupDevice;
  const newDevice = {
    ...doorGroup,
    remotelockAccessGrantId: id,
  };
  return newDevice;
};

// This function is used to generate device assignments and removals without conflict logic since it is getting removed after introduction of door groups.
export const generateDeviceAssignmentsAndRemovals = ({
  propertyId,
  propertyName,
  selectedHomes,
  doorGroupsToAdd,
  doorGroupsToRemove,
  currentChangedHomesMap,
  buildingId,
}: {
  propertyId: string;
  propertyName: string;
  selectedHomes: IRemoteLockHome[]; // List of selected homes.
  doorGroupsToAdd: IRemoteLockIntegrationDevice[]; // List of door groups to be added.
  doorGroupsToRemove: IRemoteLockIntegrationDevice[]; // List of door groups to be removed.
  currentChangedHomesMap: Map<string, IRemoteLockChangedHome[]>; // This map is used to keep track of changed homes.
  buildingId: string;
}): {
  remotelockDeviceAssignments: IRemotelockDeviceAssignment[];
  remotelockDeviceRemovals: IRemotelockDeviceRemoval[];
  changedHomes: IRemoteLockChangedHome[];
} => {
  const bulkChangesInProgress = selectedHomes.length > 1;
  const remotelockDeviceAssignments: IRemotelockDeviceAssignment[] = [];
  const remotelockDeviceRemovals: IRemotelockDeviceRemoval[] = [];
  const currentChangedHomes = currentChangedHomesMap.get(buildingId) || [];
  // Make a shallow copy of the current changed homes to not modify the original.
  const changedHomes: IRemoteLockChangedHome[] = [...currentChangedHomes];

  if (bulkChangesInProgress) {
    let newRemotelockDoorGroups: IRemoteLockIntegrationDevice[] = [];
    _.forEach(selectedHomes, (home) => {
      const remotelockDoorGroups = sortAccessDevicesByName(
        _.map(home.remotelockDoorGroups, (element) => {
          return getRemoteLockDoorGroupIntegration(element);
        })
      );

      // From the list of doorGroupsToAdd pick groups which needs to be added for this home.
      // If the group is already assigned to the property, it will be skipped.
      if (doorGroupsToAdd.length > 0) {
        const newDoorGroupsToAdd = _.differenceBy(
          doorGroupsToAdd,
          remotelockDoorGroups,
          "id"
        );
        // In case newDoorGroupsToAdd is not empty, prepare them to be added to this home.
        if (newDoorGroupsToAdd.length > 0) {
          newRemotelockDoorGroups = newDoorGroupsToAdd;
        }

        remotelockDeviceAssignments.push(
          ...generateRemotelockDeviceAssignments({
            newDoorGroupsToAdd,
            propertyId: home.id,
          })
        );
      }

      // From the list of doorGroupsToRemove pick groups which needs to be removed from this home.
      // If the group is not assigned to the property, it will be skipped.
      if (doorGroupsToRemove.length > 0) {
        // Check if doorGroupsToRemove exist in this home.
        const newDoorGroupsToRemove = _.intersectionBy(
          remotelockDoorGroups,
          doorGroupsToRemove,
          "id"
        );
        // In case doorGroupsToRemove is not empty, prepare them to be removed from this home.
        if (newDoorGroupsToRemove.length > 0) {
          newRemotelockDoorGroups = newRemotelockDoorGroups.concat(
            newDoorGroupsToRemove
          );
        }
        remotelockDeviceRemovals.push(
          ...generateRemotelockDeviceRemovals({
            newDoorGroupsToRemove,
            propertyId: home.id,
          })
        );
      }
      if (newRemotelockDoorGroups.length > 0) {
        changedHomes.push({
          deviceType: remoteLockDeviceType.doorGroup,
          homeName: home.propertyName,
          id: home.id,
          remotelockAccessDevices: newRemotelockDoorGroups,
        });
      }
    });
  } else {
    remotelockDeviceAssignments.push(
      ...generateRemotelockDeviceAssignments({
        newDoorGroupsToAdd: doorGroupsToAdd,
        propertyId,
      })
    );

    remotelockDeviceRemovals.push(
      ...generateRemotelockDeviceRemovals({
        newDoorGroupsToRemove: doorGroupsToRemove,
        propertyId,
      })
    );

    if (
      remotelockDeviceAssignments.length > 0 ||
      remotelockDeviceRemovals.length > 0
    ) {
      changedHomes.push({
        deviceType: remoteLockDeviceType.doorGroup,
        homeName: propertyName,
        id: propertyId,
        remotelockAccessDevices: doorGroupsToAdd.concat(doorGroupsToRemove),
      });
    }
  }
  return {
    changedHomes,
    remotelockDeviceAssignments,
    remotelockDeviceRemovals,
  };
};

const renderSearchBar = ({
  searchValue,
  setSearchValue,
}: {
  searchValue: string;
  setSearchValue: (value: React.SetStateAction<string>) => void;
}) => {
  return (
    <div className="remote-lock-table-dropdown-search-box">
      <form
        className="remote-lock-table-dropdown-search-container center-align-as-row"
        onSubmit={(evt: React.SyntheticEvent) => {
          evt.preventDefault();
        }}
      >
        <Search title="Search" />
        <input
          type="text"
          data-testid="search-input"
          placeholder="Search"
          className="remote-lock-table-dropdown-search-input tool-tip"
          maxLength={50}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </form>
    </div>
  );
};

const renderSelectedAccessDevices = ({
  doorGroupsToAdd,
  setDoorGroupsToAdd,
  setSelectedAccessDevices,
  setAvailableDoorGroupsState,
  selectedAccessDevices,
  availableDoorGroupsState,
  doorGroupsToRemove,
  setDoorGroupsToRemove,
}: {
  doorGroupsToAdd: IRemoteLockIntegrationDevice[];
  setDoorGroupsToAdd: React.Dispatch<
    React.SetStateAction<IRemoteLockIntegrationDevice[]>
  >;
  setSelectedAccessDevices: (
    value: React.SetStateAction<IRemoteLockIntegrationDevice[]>
  ) => void;
  setAvailableDoorGroupsState: (
    value: React.SetStateAction<IRemoteLockIntegrationDevice[]>
  ) => void;
  selectedAccessDevices: IRemoteLockIntegrationDevice[];
  availableDoorGroupsState: IRemoteLockIntegrationDevice[];
  doorGroupsToRemove: IRemoteLockIntegrationDevice[];
  setDoorGroupsToRemove: React.Dispatch<
    React.SetStateAction<IRemoteLockIntegrationDevice[]>
  >;
}) => {
  if (selectedAccessDevices.length === 0) {
    return (
      <>
        <div className="remote-lock-table-device-empty tool-tip">
          Select groups below...
        </div>
      </>
    );
  }

  return selectedAccessDevices.map((selectedAccessDevice) => {
    return (
      <div
        className="remote-lock-table-device-name-container tooltip remote-lock-table-device-name-large secondary-btn remote-lock-table-assign-devices-green"
        key={selectedAccessDevice.id}
      >
        <Tooltip tooltipText={selectedAccessDevice.name}>
          <div className="remote-lock-table-device-name">
            {selectedAccessDevice.name}
          </div>
        </Tooltip>
        <CloseIcon
          title={`Remove access device ${selectedAccessDevice.name}`}
          className="remote-lock-table-device-name-icon"
          onClick={() => {
            // Check if device is assigned to the property.
            // It is done to check if device is intended to be removed or if the user change his mind and no longer wants to add device to the home.
            if (selectedAccessDevice.remotelockAccessGrantId) {
              const remotelockIntegrations: IRemoteLockIntegrationDevice[] = [
                ...doorGroupsToRemove,
              ];
              remotelockIntegrations.push(selectedAccessDevice);
              setDoorGroupsToRemove(remotelockIntegrations);
            } else {
              setDoorGroupsToAdd(
                _.filter(doorGroupsToAdd, ({ id }) => {
                  return id !== selectedAccessDevice.id;
                })
              );
            }
            setSelectedAccessDevices(
              _.without(selectedAccessDevices, selectedAccessDevice)
            );
            setAvailableDoorGroupsState(
              sortAccessDevicesByName(
                availableDoorGroupsState.concat(selectedAccessDevice)
              )
            );
          }}
        />
      </div>
    );
  });
};

const renderAvailableAccessDevices = ({
  filteredAvailableDoorGroups,
  setSelectedAccessDevices,
  setAvailableDoorGroupsState,
  selectedAccessDevices,
  availableDoorGroupsState,
  doorGroupsToAdd,
  setDoorGroupsToAdd,
  doorGroupsToRemove,
  setDoorGroupsToRemove,
  scrollRef,
}: {
  filteredAvailableDoorGroups: IRemoteLockIntegrationDevice[];
  setSelectedAccessDevices: (
    value: React.SetStateAction<IRemoteLockIntegrationDevice[]>
  ) => void;
  setAvailableDoorGroupsState: (
    value: React.SetStateAction<IRemoteLockIntegrationDevice[]>
  ) => void;
  selectedAccessDevices: IRemoteLockIntegrationDevice[];
  availableDoorGroupsState: IRemoteLockIntegrationDevice[];
  doorGroupsToAdd: IRemoteLockIntegrationDevice[];
  setDoorGroupsToAdd: React.Dispatch<
    React.SetStateAction<IRemoteLockIntegrationDevice[]>
  >;
  doorGroupsToRemove: IRemoteLockIntegrationDevice[];
  setDoorGroupsToRemove: React.Dispatch<
    React.SetStateAction<IRemoteLockIntegrationDevice[]>
  >;
  scrollRef: any;
}) => {
  return (
    <div className="remote-lock-table-dropdown-search-devices-container">
      <div className="remote-lock-table-dropdown-available-device-container">
        {filteredAvailableDoorGroups.length === 0 && (
          <>
            <p
              data-testid="no-assigned-devices-found"
              className="remote-lock-table-dropdown-available-device-empty"
            >
              <>
                <>No Unassigned Door Groups Found</>
                <br />
                <>Door Groups Are Created In RemoteLock</>
              </>
            </p>
          </>
        )}
        {filteredAvailableDoorGroups.map((doorGroup) => {
          return (
            <button
              type="button"
              key={doorGroup.id}
              className="remote-lock-table-dropdown-available-device tool-tip"
              onClick={() => {
                // Check if device is not assigned to the property.
                if (!doorGroup.remotelockAccessGrantId) {
                  const remotelockIntegrations: IRemoteLockIntegrationDevice[] =
                    [...doorGroupsToAdd];
                  remotelockIntegrations.push(doorGroup);
                  setDoorGroupsToAdd(remotelockIntegrations);
                } else {
                  setDoorGroupsToRemove(
                    _.filter(doorGroupsToRemove, ({ id }) => {
                      return id !== doorGroup.id;
                    })
                  );
                }
                // Local component state changes
                setSelectedAccessDevices(
                  _.concat(selectedAccessDevices, doorGroup)
                );
                setAvailableDoorGroupsState(
                  _.without(availableDoorGroupsState, doorGroup)
                );
              }}
            >
              {doorGroup.numDevices !== undefined
                ? `${doorGroup.name} (${doorGroup.numDevices} ${quantityDisplay(
                    doorGroup.numDevices,
                    "Door"
                  )})`
                : doorGroup.name}
            </button>
          );
        })}
      </div>
      <div ref={scrollRef} />
    </div>
  );
};

export interface IEditDoorGroupsDropdownProps {
  availableDoorGroups: IRemoteLockIntegrationDevice[];
  propertyId: string;
  propertyName: string;
  setShowEditDoorGroupsDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAccessDevices: (
    value: React.SetStateAction<IRemoteLockIntegrationDevice[]>
  ) => void;
  selectedAccessDevices: IRemoteLockIntegrationDevice[];
  selectedHomes: IRemoteLockHome[];
  startPolling: (pollInterval: number) => void;
  deselectAllHomes: () => void;
  buildingId: string;
  modalRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const EditDoorGroupsDropdown: React.FC<IEditDoorGroupsDropdownProps> = ({
  availableDoorGroups,
  setShowEditDoorGroupsDropdown,
  setSelectedAccessDevices,
  selectedAccessDevices,
  propertyId,
  selectedHomes,
  startPolling,
  deselectAllHomes,
  buildingId,
  propertyName,
  modalRef,
}) => {
  sortAccessDevicesByName(availableDoorGroups);
  const [searchValue, setSearchValue] = useState("");
  const [availableDoorGroupsState, setAvailableDoorGroupsState] =
    useState<IRemoteLockIntegrationDevice[]>(availableDoorGroups);

  // `remotelockIntegrationsToAdd` tracks the access devices selected
  // to be added to the unit.
  const [doorGroupsToAdd, setDoorGroupsToAdd] = useState<
    IRemoteLockIntegrationDevice[]
  >([]);

  // `remotelockIntegrationsToRemove` tracks the access devices selected
  // to be deleted from the unit.
  const [doorGroupsToRemove, setDoorGroupsToRemove] = useState<
    IRemoteLockIntegrationDevice[]
  >([]);

  const [addDoorGroups] = useMutation<
    { count: string },
    { remotelockDeviceAssignments: IRemotelockDeviceAssignment[] }
  >(ADD_REMOTELOCK_DEVICES_TO_PROPERTIES);

  const [removeDoorGroups] = useMutation<
    { recordIds: string[] },
    { remotelockDeviceRemovals: IRemotelockDeviceRemoval[] }
  >(REMOVE_REMOTELOCK_DEVICES_TO_PROPERTIES);

  const currentChangedHomesMap = useReactiveVar(currentChangedHomesVar);

  const filteredAvailableDoorGroups = _.filter(
    availableDoorGroupsState,
    (accessDevice) => {
      return accessDevice.name
        .toLocaleLowerCase()
        .includes(searchValue.toLowerCase());
    }
  );

  const closeEditDoorGroupsDropdownComponent = () => {
    setShowEditDoorGroupsDropdown(false);
    setSelectedAccessDevices(selectedAccessDevices);
    setAvailableDoorGroupsState(sortAccessDevicesByName(availableDoorGroups));
    setDoorGroupsToAdd([]);
    setDoorGroupsToRemove([]);
    isEditAccessDevicesInProgressVar(false);
  };

  const ref = useRef<null | HTMLDivElement>(null);

  const scrollRef = useRef<null | HTMLDivElement>(null);
  const isEndOfTheDropdownVisible = useOnScreen(scrollRef);

  useEffect(() => {
    setAvailableDoorGroupsState(availableDoorGroups);
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") closeEditDoorGroupsDropdownComponent();
    };
    const handleClickOutside = (event: any) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        modalRef?.current &&
        !modalRef.current.contains(event.target)
      )
        closeEditDoorGroupsDropdownComponent();
    };
    window.addEventListener("keydown", close);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      window.removeEventListener("keydown", close);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (!isEndOfTheDropdownVisible) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isEndOfTheDropdownVisible]);

  const handleAssignmentChanges = () => {
    if (doorGroupsToAdd.length === 0 && doorGroupsToRemove.length === 0) {
      setShowEditDoorGroupsDropdown(false);
      isEditAccessDevicesInProgressVar(false);
      return;
    }
    const {
      remotelockDeviceAssignments,
      remotelockDeviceRemovals,
      changedHomes,
    } = generateDeviceAssignmentsAndRemovals({
      buildingId,
      currentChangedHomesMap,
      doorGroupsToAdd,
      doorGroupsToRemove,
      propertyId,
      propertyName,
      selectedHomes,
    });
    const executeTransactions = () => {
      setShowEditDoorGroupsDropdown(false);
      isEditAccessDevicesInProgressVar(false);
      currentChangedHomesMap.set(buildingId, changedHomes);
      currentChangedHomesVar(currentChangedHomesMap);
      setSelectedAccessDevices(selectedAccessDevices);
      if (remotelockDeviceAssignments.length > 0) {
        addDoorGroups({
          variables: {
            remotelockDeviceAssignments,
          },
        });
        setDoorGroupsToAdd([]);
      }
      if (remotelockDeviceRemovals.length > 0) {
        removeDoorGroups({
          variables: {
            remotelockDeviceRemovals,
          },
        });
        setDoorGroupsToRemove([]);
      }
      if (
        remotelockDeviceAssignments.length > 0 ||
        remotelockDeviceRemovals.length > 0
      ) {
        startPolling(1000);
        deselectAllHomes();
      }
    };
    const totalChanges =
      remotelockDeviceAssignments.length + remotelockDeviceRemovals.length;
    if (totalChanges >= REMOTE_LOCK_LARGE_UPDATE_THRESHOLD) {
      showRemoteLockLargeUpdateModalVar({
        confirmFn: executeTransactions,
        isShowing: true,
      });
    } else {
      executeTransactions();
    }
  };

  return (
    <div ref={ref} className="remote-lock-table-dropdown-container">
      <div className="remote-lock-table-dropdown-devices-checkmark-container">
        <div className="remote-lock-table-dropdown-devices-container">
          {renderSelectedAccessDevices({
            availableDoorGroupsState,
            doorGroupsToAdd,
            doorGroupsToRemove,
            selectedAccessDevices,
            setAvailableDoorGroupsState,
            setDoorGroupsToAdd,
            setDoorGroupsToRemove,
            setSelectedAccessDevices,
          })}
        </div>
        <button
          type="button"
          className="remote-lock-table-dropdown-checkmark-container remote-lock-table-dropdown-checkmark-container-green"
          data-testid="checkmark"
          onClick={() => {
            handleAssignmentChanges();
          }}
        >
          <div className="remote-lock-table-dropdown-checkmark" />
        </button>
      </div>
      {renderSearchBar({
        searchValue,
        setSearchValue,
      })}
      {renderAvailableAccessDevices({
        availableDoorGroupsState,
        doorGroupsToAdd,
        doorGroupsToRemove,
        filteredAvailableDoorGroups,
        scrollRef,
        selectedAccessDevices,
        setAvailableDoorGroupsState,
        setDoorGroupsToAdd,
        setDoorGroupsToRemove,
        setSelectedAccessDevices,
      })}
    </div>
  );
};

export default EditDoorGroupsDropdown;
