import { useFeature } from "@optimizely/react-sdk";

import {
  AlertTypes,
  alertDescription,
  alertTitle,
} from "../../../utils/alertUtils";
import { IAlarmNotificationSetting } from "../../../interfaces/interfaces";
import { NotificationMethod } from "../../../utils/notificationUtils";

import AlertPreference from "./AlertPreference";

const GroupedAlertPreferences: React.FC<{
  name: string;
  propertyId: string;
  alarmNotificationSettings: IAlarmNotificationSetting[];
  isOfflineBrilliantControlStories?: boolean;
}> = ({
  alarmNotificationSettings,
  name,
  propertyId,
  isOfflineBrilliantControlStories = false,
}) => {
  const [isOfflineBrilliantControl] = useFeature(
    "offline_brilliant_control_alert"
  );
  return (
    <div className="grouped-preferences-container">
      <div
        data-testid={`group-preferences-name-${name.replace(/\s/g, "")}`}
        className="menu"
      >
        {name}
      </div>
      {alarmNotificationSettings.map((setting) => {
        const smsPreference = setting.notificationSettings.find(
          (notification) =>
            notification.notificationMethod === NotificationMethod.Sms
        );
        const emailPreference = setting.notificationSettings.find(
          (notification) =>
            notification.notificationMethod === NotificationMethod.Email
        );
        if (
          setting.alarmType === AlertTypes.OfflineDevices &&
          !(isOfflineBrilliantControl || isOfflineBrilliantControlStories)
        ) {
          return <></>;
        }
        return (
          <AlertPreference
            alertType={setting.alarmType}
            alertName={alertTitle[setting.alarmType]}
            alertDescription={alertDescription[setting.alarmType]}
            smsPreference={smsPreference}
            emailPreference={emailPreference}
            propertyId={propertyId}
          />
        );
      })}
    </div>
  );
};

export default GroupedAlertPreferences;
