export interface IToolTipUpperProps {
  text: string;
  upperContainerOverrideClass?: string;
  learnMoreLink?: string;
  isVisibleStory?: boolean;
}

const ToolTipUpper: React.FC<IToolTipUpperProps> = ({
  text,
  upperContainerOverrideClass = "",
  isVisibleStory = false,
}) => {
  return (
    <div className={`${!isVisibleStory && "dialog-box"}`}>
      <div
        className={`tooltip-upper-container ${upperContainerOverrideClass} `}
      >
        <div className="tooltip-upper-container-inner">
          <div className="unit-description-small">{text}</div>
        </div>
      </div>
    </div>
  );
};

export default ToolTipUpper;
