import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Field, InjectedFormProps } from "redux-form";

import {
  ERegistrationError,
  IVerificationFormCustomProps,
  IVerificationFormData,
} from "../../../interfaces/interfaces";
import {
  errorMessage,
  maxLength6,
  normalizeMaxLength6,
  required,
} from "../../../formValidation/FormValidation";
import { clearRegistrationError } from "../../../actions";
import SubmitButton from "../../buttons/SubmitButton";

import InputField from "./InputField";

const VerificationForm: React.FC<
  IVerificationFormCustomProps &
    InjectedFormProps<IVerificationFormData, IVerificationFormCustomProps>
> = ({
  handleSubmit,
  invalid,
  submitting,
  submitText,
  resendCodeAction,
  pristine,
  registrationError,
}) => {
  const dispatch = useDispatch();
  const clearError = () => dispatch(clearRegistrationError);
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);

  const showError = registrationError !== ERegistrationError.noError;
  const wrongTenantCode =
    registrationError === ERegistrationError.isWrongTenantCode;

  useEffect(() => {
    if (registrationError === ERegistrationError.verificationRateLimited) {
      setVerificationCodeSent(true);
    }
  }, [registrationError]);

  return (
    <form onSubmit={handleSubmit} className="right-form">
      <label htmlFor="verificationCodeField">Verification Code</label>
      <Field
        id="verificationCodeField"
        name="verificationCode"
        normalize={normalizeMaxLength6}
        registrationErrorHighlight={[
          ERegistrationError.isWrongCode,
          ERegistrationError.isWrongTenantCode,
        ]}
        validate={[required, maxLength6]}
        component={InputField}
        type="input"
        onChange={clearError}
      />
      {showError && !wrongTenantCode && (
        <p className="right-error">
          {errorMessage[ERegistrationError[registrationError]]}
        </p>
      )}
      {wrongTenantCode && (
        <div className="margin-bottom-small">
          <p className="right-error">This verification code is incorrect.</p>
        </div>
      )}
      <div className="right-form-btn-container">
        <SubmitButton
          disabled={invalid || submitting || pristine}
          name={submitText}
          decorator="right-form-btn"
        />
        {resendCodeAction && (
          <button
            type="button"
            disabled={verificationCodeSent}
            className="tertiary-btn-small right-form-btn menu"
            onClick={() => {
              dispatch(resendCodeAction());
              setVerificationCodeSent(true);
            }}
          >
            Resend Code
          </button>
        )}
      </div>
      {verificationCodeSent && !showError && (
        <p>A new verification code has been sent.</p>
      )}
    </form>
  );
};

export default VerificationForm;
