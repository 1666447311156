import { useState } from "react";

interface TooltipProps {
  children?: React.ReactNode;
  tooltipText: string;
}

const Tooltip: React.FC<TooltipProps> = ({ tooltipText, children }) => {
  const [hasOverflowingChildren, setHasOverflowingChildren] = useState(false);

  const updateOverflow = (e: any) => {
    const { target } = e;
    if (!hasOverflowingChildren && target.scrollWidth > target.clientWidth) {
      setHasOverflowingChildren(true);
    }
  };

  return (
    <div>
      <div onMouseEnter={updateOverflow}>{children}</div>
      {hasOverflowingChildren && (
        <div className="tooltiptext tooltiptext-small unit-description">
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
