import { Dispatch } from "redux";
import { StatusCodes } from "http-status-codes";
import * as Sentry from "@sentry/browser";
import { AxiosError, AxiosResponse } from "axios";

import { LOADER_START, LOADER_STOP } from "../types";
import {
  ITokenResponse,
  ITokenWithUserIdResponse,
  postTenantVerification,
  postUserVerification,
  postVerifyUserEmail,
} from "../../api/emails";
import { postVerifyTenantEmail } from "../../api/registration";
import { postAddTenantToHome } from "../../api/organizations";
import { postCreateUser } from "../../api/users";
import EWebAPIError from "../../api/errors";
import {
  EReconfigurationError,
  IAppState,
  IRegistrationTenantAccountFormData,
} from "../../interfaces/interfaces";
import {
  BIRTHDATE_FORMAT_INVALID,
  BIRTHDATE_INVALID,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_USER_SUCCESS,
  EMAIL_VERIFICATION_SENT,
  EMAIL_VERIFICATION_SUCCESS,
  EXPIRED_INVITATION,
  FAMILY_NAME_INVALID,
  GIVEN_NAME_INVALID,
  INVITATION_NOT_FOUND,
  PASSWORD_INVALID,
  VERIFICATION_CODE_FAILURE,
  VERIFICATION_RATE_LIMITED,
} from "../constants";
import { reconfigurationErrorVar } from "../../components/apollo/LocalState";

export const addUserToHome =
  () =>
  async (dispatch: Dispatch, getState: () => IAppState): Promise<void> => {
    dispatch({ type: LOADER_START });
    const { propertyId } = getState().newUserRegistration;
    try {
      const response = await postAddTenantToHome(
        propertyId,
        getState().newUserRegistration.userId,
        getState().newUserRegistration.jwt
      );
      dispatch({
        payload: {
          buildingName: response.data.building_name,
          configHomeName: response.data.config_home_name,
          propertyName: response.data.home_property_name,
        },
        type: CREATE_ACCOUNT_SUCCESS,
      });
    } catch (error) {
      /* eslint-disable camelcase */
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.INVALID_TOKEN
      ) {
        dispatch({ type: EXPIRED_INVITATION });
      }
      if (
        response &&
        response.status === StatusCodes.NOT_FOUND &&
        response.data.error_id === EWebAPIError.INVITATION_NOT_FOUND
      ) {
        dispatch({ type: INVITATION_NOT_FOUND });
      } else {
        Sentry.captureMessage(
          `Route "/organizations/homes/${propertyId}/users" returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
      }
    }
    dispatch({ type: LOADER_STOP });
  };

export const verifyUserEmail =
  ({
    code,
    isTenantRegistrationFlow,
  }: {
    code: string;
    isTenantRegistrationFlow: boolean;
  }) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: Dispatch<any>, getState: () => IAppState): Promise<void> => {
    dispatch({ type: LOADER_START });
    let EmailVerificationResponse: AxiosResponse<ITokenWithUserIdResponse>;
    try {
      if (isTenantRegistrationFlow) {
        EmailVerificationResponse = await postVerifyTenantEmail(
          getState().newUserRegistration.email,
          getState().newUserRegistration.jwt,
          code
        );
      } else {
        EmailVerificationResponse = await postVerifyUserEmail(
          getState().newUserRegistration.email,
          getState().newUserRegistration.jwt,
          code
        );
      }
      dispatch({
        payload: {
          jwt: EmailVerificationResponse.data.token,
          userId: EmailVerificationResponse.data.user_id,
        },
        type: EMAIL_VERIFICATION_SUCCESS,
      });
      if (EmailVerificationResponse.data.user_id) dispatch(addUserToHome());
      else dispatch({ type: LOADER_STOP });
    } catch (error) {
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.INVALID_TOKEN
      ) {
        dispatch({ type: EXPIRED_INVITATION });
      }
      if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.WRONG_CODE
      ) {
        dispatch({ type: VERIFICATION_CODE_FAILURE });
      } else if (
        response &&
        response.status === StatusCodes.TOO_MANY_REQUESTS &&
        response.data.error_id === EWebAPIError.TOO_MANY_REQUESTS
      ) {
        dispatch({
          type: VERIFICATION_RATE_LIMITED,
        });
      } else {
        Sentry.captureMessage(
          `Route ${
            isTenantRegistrationFlow
              ? "registration_tenant_verify_email"
              : "emails_verify-user-email"
          } returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
      }
      dispatch({ type: LOADER_STOP });
    }
  };

export const sendUserVerificationEmail =
  ({
    isTenantRegistrationFlow,
    force = false,
  }: {
    isTenantRegistrationFlow: boolean;
    force?: boolean;
  }) =>
  async (dispatch: Dispatch, getState: () => IAppState): Promise<void> => {
    let EmailVerificationResponse: AxiosResponse<ITokenResponse>;
    try {
      if (isTenantRegistrationFlow) {
        EmailVerificationResponse = await postTenantVerification(
          getState().newUserRegistration.email,
          getState().newUserRegistration.emailJWT,
          force
        );
      } else {
        EmailVerificationResponse = await postUserVerification(
          getState().newUserRegistration.email,
          getState().newUserRegistration.emailJWT
        );
      }
      dispatch({
        payload: {
          jwt: EmailVerificationResponse.data.token,
        },
        type: EMAIL_VERIFICATION_SENT,
      });
    } catch (error) {
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.INVALID_TOKEN
      ) {
        dispatch({ type: EXPIRED_INVITATION });
      } else {
        Sentry.captureMessage(
          `Route ${
            isTenantRegistrationFlow
              ? "emails_send-tenant-verification-email"
              : "emails_send-user-verification-email"
          } returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
      }
    }
  };

export const createNewUser =
  ({ values }: { values: IRegistrationTenantAccountFormData }) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (dispatch: Dispatch<any>, getState: () => IAppState): Promise<void> => {
    dispatch({ type: LOADER_START });
    const { birthdate, emailAddress, givenName, familyName, newPassword } =
      values;
    try {
      const response = await postCreateUser(
        birthdate,
        emailAddress,
        givenName,
        familyName,
        newPassword,
        getState().newUserRegistration.jwt
      );
      dispatch({
        payload: {
          birthdate,
          familyName,
          givenName,
          jwt: response.data.token,
          userId: response.data.user_id,
        },
        type: CREATE_USER_SUCCESS,
      });
      if (response.data.token) dispatch(addUserToHome());
      else dispatch({ type: LOADER_STOP });
    } catch (error) {
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.BAD_REQUEST &&
        response.data.error_id === EWebAPIError.PASSWORD_INVALID
      ) {
        dispatch({ type: PASSWORD_INVALID });
      } else if (
        response &&
        response.status === StatusCodes.BAD_REQUEST &&
        response.data.error_id === EWebAPIError.BIRTHDATE_FORMAT_INVALID
      ) {
        dispatch({ type: BIRTHDATE_FORMAT_INVALID });
      } else if (
        response &&
        response.status === StatusCodes.BAD_REQUEST &&
        response.data.error_id === EWebAPIError.BIRTHDATE_INVALID
      ) {
        dispatch({ type: BIRTHDATE_INVALID });
      } else if (
        response &&
        response.status === StatusCodes.BAD_REQUEST &&
        response.data.error_id === EWebAPIError.FAMILY_NAME_INVALID
      ) {
        dispatch({ type: FAMILY_NAME_INVALID });
      } else if (
        response &&
        response.status === StatusCodes.BAD_REQUEST &&
        response.data.error_id === EWebAPIError.GIVEN_NAME_INVALID
      ) {
        dispatch({ type: GIVEN_NAME_INVALID });
      } else if (
        response &&
        response.status === StatusCodes.UNAUTHORIZED &&
        response.data.error_id === EWebAPIError.INVALID_TOKEN
      ) {
        dispatch({ type: EXPIRED_INVITATION });
      } else if (
        response &&
        response.status === StatusCodes.SERVICE_UNAVAILABLE &&
        response.data.error_id ===
          EWebAPIError.PROPERTY_RECONFIGURATION_IN_PROGRESS
      ) {
        reconfigurationErrorVar(
          EReconfigurationError.reconfigurationInProgress
        );
      } else {
        Sentry.captureMessage(
          `Route "tenant_users_create" returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
      }
      dispatch({ type: LOADER_STOP });
    }
  };
