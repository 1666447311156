import { ReactComponent as ReconfigurationBannerIcon } from "../../img/ReconfigurationBanner.svg";

export interface ReconfigurationBannerProps {
  setShowBannerReconfigurationModal?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

const ReconfigurationBanner: React.FC<ReconfigurationBannerProps> = ({
  setShowBannerReconfigurationModal,
}) => {
  return (
    <>
      <button
        data-testid="info-banner-button"
        type="button"
        title="Learn More"
        onClick={() => setShowBannerReconfigurationModal?.(true)}
        className="unit-alert-container-reconfiguration center-align center-align-as-row"
      >
        <div className="center-align-as-row margin-left-right-smaller">
          <div className="unit-alert-icon-container center-align-as-row">
            <ReconfigurationBannerIcon title="Reconfiguration Banner Icon" />
          </div>
          <div className="unit-alert-text tool-tip">
            Unit Reconfiguration In Progress
          </div>
          <div className="unit-alert-call-to-action sub-heading">Details</div>
        </div>
      </button>
    </>
  );
};

export default ReconfigurationBanner;
