import { AnyAction, Dispatch } from "redux";

import { LOADER_START, LOADER_STOP } from "../types";
import history from "../../history";
import { deleteScheduledConfiguration } from "../../api/homes";

export const SELECT_SCHEDULED_CONFIGURATION = "SELECT_SCHEDULED_CONFIGURATION";

export const updateSelectedConfiguration = (
  createdDate: string,
  date: string,
  id: string,
  showModal: boolean,
  time: string,
  title: string
): AnyAction => {
  return {
    payload: {
      createdDate,
      date,
      id,
      showModal,
      time,
      title,
    },
    type: SELECT_SCHEDULED_CONFIGURATION,
  };
};

export const deleteScheduledConfigurationAction =
  (stateConfigId: string, homeId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: LOADER_START,
    });
    await deleteScheduledConfiguration(
      stateConfigId,
      homeId,
      dispatch,
      // We need to refresh the UnitDetails page to remove the modal and to refresh
      // "changes activity" list to show updated change list
      () => {
        history.go(0);
      },
      () => {
        dispatch({
          type: LOADER_STOP,
        });
        return false;
      }
    );
  };
