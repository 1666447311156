import Logo from "../../img/logos/Brilliant_grey.svg";

interface FooterProps {
  containerClassName?: string;
}

const Footer: React.FC<FooterProps> = ({ containerClassName }) => {
  return (
    <div className={`footer-container ${containerClassName}`}>
      <div className="footer-name">Powered by</div>
      <img className="footer-logo" alt="Brilliant" src={Logo} />
    </div>
  );
};

Footer.defaultProps = {
  containerClassName: "",
};

export default Footer;
