import { LEARN_MORE_MOVE_IN } from "../../../utils/LearnMoreLinks";
import LearnMoreButton from "../../buttons/LearnMoreButton";

export interface IMoveInStartedStepTwoProps {
  children?: React.ReactNode;
}

const MoveInStartedStep: React.FC<IMoveInStartedStepTwoProps> = ({
  children,
}) => {
  return (
    <div className="tenants-show-body">
      {children}
      <div className="invitation-sent-learn-more-container">
        <LearnMoreButton link={LEARN_MORE_MOVE_IN} />
      </div>
    </div>
  );
};

export default MoveInStartedStep;
