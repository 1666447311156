import { connect } from "react-redux";

import Logo from "../../img/logos/Brilliant.svg";
import { errorMessage } from "../../formValidation/FormValidation";
import {
  ELoginError,
  IAppState,
  ILoginData,
} from "../../interfaces/interfaces";
import TwoFactorAuthentication from "../twoFactorAuthentication/TwoFactorAuthentication";

import LoginForm from "./LoginForm";

const renderLoginView = (error: ELoginError) => {
  if (error === ELoginError.isForbidden) {
    return (
      <div className="center-align-as-column login-forbidden-container">
        Thank you for logging in.
        <br />
        Your account is not set up for management access at this time.
        <div className="login-forbidden-line" />
        Please contact support for additional help.
        <a
          href="https://support.brilliant.tech/"
          className="login-forbidden-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Support
        </a>
      </div>
    );
  }
  return (
    <div className="login-form-container">
      <div className="login-description">
        Log in to manage your smart apartments and properties.
      </div>
      <LoginForm />
      <a
        href={`${process.env.REACT_APP_BRILLIANT_URL}/web/reset-password`}
        className="login-reset"
        target="_blank"
        rel="noopener noreferrer"
      >
        Reset Password
      </a>
      {error !== ELoginError.noError && (
        <div className="alert">{errorMessage[ELoginError[error]]}</div>
      )}
    </div>
  );
};

const RenderLoginContainer: React.FC<{ error: ELoginError }> = ({ error }) => {
  return (
    <div className="background-container">
      <div className="login-container">
        <div className="inner-container">
          <img className="logo" src={Logo} alt="Logo" />
          <h1 data-testid="login-name" className="login-name">
            Command Center
          </h1>
          {renderLoginView(error)}
        </div>
      </div>
    </div>
  );
};

const Login: React.FC<{ user: ILoginData }> = ({ user }) => {
  return (
    <>
      {user.isSecondStep ? (
        <TwoFactorAuthentication />
      ) : (
        RenderLoginContainer(user)
      )}
    </>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(Login);
