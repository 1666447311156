import { useQuery, useReactiveVar } from "@apollo/client";
import { connect } from "react-redux";

import { ReactComponent as Bell } from "../../img/Bell.svg";
import { ReactComponent as Close } from "../../img/Close.svg";
import { GET_ALERTS_FOR_ORGANIZATION } from "../../api/gqlQueries";
import {
  IAlertData,
  IAppState,
  IOrganizationParams,
} from "../../interfaces/interfaces";
import Spinner from "../Spinner";
import { isAlertSideMenuVisibleVar } from "../apollo/LocalState";

const AlertBell: React.FC<{
  organizationId: string;
}> = ({ organizationId }) => {
  const oneMinInMs = 60 * 1000;

  const isAlertSideMenuVisible = useReactiveVar(isAlertSideMenuVisibleVar);

  const { loading, data } = useQuery<IAlertData, IOrganizationParams>(
    GET_ALERTS_FOR_ORGANIZATION,
    {
      pollInterval: oneMinInMs * 5,
      variables: { organizationId },
    }
  );
  if (loading) return <Spinner />;
  if (!data) throw Error("No data was returned");
  const numberOfAlerts = data.organization.alerts.length;
  const shouldAlertsNumberRender = numberOfAlerts > 0;
  return (
    <button
      type="button"
      data-testid="header-alert"
      className="header-alert"
      onClick={() => {
        isAlertSideMenuVisibleVar(!isAlertSideMenuVisible);
      }}
    >
      {!isAlertSideMenuVisible ? (
        <div className="header-bell-container">
          <Bell title="Bell" />
          {shouldAlertsNumberRender && (
            <div className="header-alert-number notification-counter">
              {numberOfAlerts > 99 ? "99+" : numberOfAlerts}
            </div>
          )}
        </div>
      ) : (
        <Close
          data-testid="header-close"
          className="header-close"
          title="Close"
        />
      )}
    </button>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    organizationId: state.user.organizationId,
  };
};
export default connect(mapStateToProps)(AlertBell);
