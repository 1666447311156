import { ReactComponent as Entrata } from "../../img/logos/EntrataRed.svg";
import { LEARN_MORE_ENTRATA } from "../../utils/LearnMoreLinks";
import BaseModal from "../modals/BaseModal";

export interface IEntrataLogInModalProps {
  closeLogInModal: () => void;
}

const EntrataLogInModal: React.FC<IEntrataLogInModalProps> = ({
  closeLogInModal,
}) => {
  return (
    <BaseModal
      actionButtonFn={() => window.open("https://www.entrata.com")}
      actionButtonText="Open Entrata"
      closeModalFn={closeLogInModal}
      headerText="Add Integration"
      learnMoreLink={LEARN_MORE_ENTRATA}
    >
      <Entrata title="Entrata" />
      <div className="menu menu-light remote-lock-login-body-text">
        To add this integration, you must link your properties to Brilliant from
        Entrata.
        <br />
        <br />
        You can do so on Entrata by searching for &quot;Brilliant Smart
        Home&quot; under
        <br />
        <b>Apps &rarr; 3rd-Party Apps.</b>
      </div>
    </BaseModal>
  );
};

export default EntrataLogInModal;
