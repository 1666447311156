import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client";

import {
  IAppState,
  ILoginData,
  IUserData,
  IUserParams,
} from "../../interfaces/interfaces";
import Logo from "../../img/logos/Logo.svg";
import { GET_USER } from "../../api/gqlQueries";
import { ReactComponent as User } from "../../img/user.svg";

import AlertBell from "./AlertBell";
import HeaderDropdown from "./HeaderDropdown";

const renderLink = ({
  destinationPath,
  name,
}: {
  destinationPath: string;
  name: string;
}) => {
  return (
    <NavLink
      to={`/${destinationPath}`}
      exact
      activeClassName="header-menu-link header-menu-selected"
      className="header-menu-link"
    >
      {name}
    </NavLink>
  );
};

const Header: React.FC<{
  user: ILoginData;
  enableConfigurationFlow?: boolean;
}> = ({ user }) => {
  const [isLogoutVisible, setLogoutVisibility] = useState(false);

  const headerButtonRef = useRef<null | HTMLDivElement>(null);
  const { loading, data } = useQuery<IUserData, IUserParams>(GET_USER, {
    variables: { userId: user.userId },
  });
  if (loading) return null;
  if (!data) throw Error("No data was returned");

  return (
    <div>
      <div className="header-container header-container-large">
        <img className="header-logo" alt="header-logo" src={Logo} />
        <div className="header-menu">
          {renderLink({ destinationPath: "", name: "Dashboard" })}
          {renderLink({
            destinationPath: "configurations",
            name: "Configure",
          })}
        </div>
        <div ref={headerButtonRef} className="center-align-as-row">
          <button
            data-testid="header-name"
            aria-label="Show account actions"
            onClick={() => setLogoutVisibility(!isLogoutVisible)}
            type="button"
            className="header-name"
          >
            <span className="center-align-as-row">
              <span className="margin-right-smaller">
                {data.user.givenName} {data.user.familyName}
              </span>
              <User title="User" />
            </span>
          </button>
          {isLogoutVisible && (
            <HeaderDropdown
              headerButtonRef={headerButtonRef}
              closeHeaderDropdownComponent={() => setLogoutVisibility(false)}
            />
          )}
          <AlertBell />
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  enableConfigurationFlow: false,
};

const mapStateToProps = (state: IAppState) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(Header);
