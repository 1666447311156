import { IBackupWifiNetworkModal } from "../../interfaces/interfaces";
import { ReactComponent as Settings } from "../../img/settings.svg";
import { ReactComponent as CloseIcon } from "../../img/Close.svg";

import BackupWifiNetworkForm from "./BackupWifiNetworkForm";

const BackupWifiNetworkModal: React.FC<{
  data: IBackupWifiNetworkModal;
  closeModalFn: () => void;
}> = ({ data, closeModalFn }) => {
  return (
    <div className="popup-background">
      <div className="invite-manager-modal">
        <div className="invite-manager-modal-header">
          <div className="center-align-as-row">
            <Settings
              title="Settings"
              className="invite-manager-modal-header-svg"
            />
            <div className="invite-manager-modal-header-title menu">
              Backup WiFi Network
            </div>
          </div>
          <CloseIcon
            onClick={closeModalFn}
            className="changes-header-img"
            title="Close modal"
          />
        </div>
        <div className="invite-manager-modal-body">
          <div className="invite-manager-modal-section">
            <p>
              The following credentials are applied to the Brilliant devices
              when a resident is added or removed from this unit. Any changes
              made here will not be applied until a resident is moved in or out.
            </p>
            <br />
            <p>
              For any immediate changes, please go to the unit itself and apply
              the changes manually.
            </p>
          </div>
          <BackupWifiNetworkForm
            closeModalFn={closeModalFn}
            inputData={{
              networkName: data.networkName,
              networkType: data.networkType,
              password: data.password,
              propertyId: data.propertyId,
              securityType: data.securityType,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BackupWifiNetworkModal;
