import LearnMoreButton from "../buttons/LearnMoreButton";
import { ReactComponent as Close } from "../../img/Close.svg";

export enum EModalButtonType {
  GreyButton = 0,
  BlueButton,
}

export interface IBaseModalProps {
  actionButtonFn: () => void;
  actionButtonText?: string;
  children?: React.ReactNode;
  closeModalFn: () => void;
  headerText: string;
  learnMoreLink?: string;
  buttonType?: EModalButtonType;
}

const ActionButton: React.FC<{
  actionButtonText: string;
  onClickFn: () => void;
  buttonType?: EModalButtonType;
}> = ({ onClickFn, actionButtonText, buttonType }) => {
  let buttonClass = "modal-body-button";
  if (buttonType === EModalButtonType.BlueButton) {
    buttonClass = "modal-body-primary-button";
  }
  return (
    <button
      className={`primary-btn ${buttonClass}`}
      type="button"
      onClick={onClickFn}
    >
      {actionButtonText}
    </button>
  );
};

const BaseModal: React.FC<IBaseModalProps> = ({
  actionButtonFn,
  actionButtonText,
  children,
  closeModalFn,
  headerText,
  learnMoreLink,
  buttonType,
}) => {
  return (
    <div className="popup-background">
      <div className="modal-container">
        <div className="modal-header">
          <div className="unit-title">{headerText}</div>
          <Close
            onClick={closeModalFn}
            className="modal-header-img"
            title="Close modal"
          />
        </div>

        <div className="modal-body">
          {children}

          {actionButtonText && (
            <ActionButton
              actionButtonText={actionButtonText}
              onClickFn={actionButtonFn}
              buttonType={buttonType}
            />
          )}
          {learnMoreLink && (
            <div className="modal-body-learn-more-button">
              <LearnMoreButton link={learnMoreLink} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BaseModal;
