import {
  EPropertyFilter,
  IObjectToIFiltersData,
  IObjectToIUnitDetailsDataHomeAmount,
} from "../../interfaces/interfaces";

import FilterItem from "./FilterItem";

export interface IFilterList {
  amount: IObjectToIUnitDetailsDataHomeAmount;
  currentFilterEnum: EPropertyFilter;
  setFilterData: React.Dispatch<React.SetStateAction<EPropertyFilter>>;
  FilterItemsData: IObjectToIFiltersData;
}
const FilterList: React.FC<IFilterList> = ({
  amount,
  setFilterData,
  currentFilterEnum,
  FilterItemsData,
}) => {
  return (
    <div className="align-as-column filter-large">
      {Object.keys(FilterItemsData).map((key) => {
        return (
          <FilterItem
            key={FilterItemsData[key].name}
            selected={currentFilterEnum === FilterItemsData[key].filterTypeEnum}
            amount={amount[key]}
            name={FilterItemsData[key].name}
            setFilterData={setFilterData}
            Icon={FilterItemsData[key].icon}
            title={FilterItemsData[key].title}
            filterMethod={FilterItemsData[key].filterTypeEnum}
            cssColorMethod={FilterItemsData[key].cssColorMethod}
          />
        );
      })}
    </div>
  );
};

export default FilterList;
