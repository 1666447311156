import { useDispatch } from "react-redux";
import { useQuery, useReactiveVar } from "@apollo/client";
import { useEffect } from "react";

import { ReactComponent as TenantsImg } from "../../../img/Tenants.svg";
import {
  EUnitDetailsFilter,
  EUserManagementGenericError,
  IPropertyIdVariable,
} from "../../../interfaces/interfaces";
import { ReactComponent as CloseIcon } from "../../../img/Close.svg";
import { renderUnitDetailsModalContent } from "../../../utils/utils";
import { cancelMoveIn } from "../../../actions";
import Spinner from "../../Spinner";
import { GET_INVITED_RESIDENT_EMAILS } from "../../../api/gqlQueries";
import { userManagementGenericErrorVar } from "../../apollo/LocalState";
import { userManagementGenericErrorMessages } from "../../../utils/errorMessages";

export interface ICancelMoveInModalProps {
  propertyName: string;
  unitName: string;
  closeCancelMoveInModal: () => void;
  setUnitDetailsFilter: React.Dispatch<
    React.SetStateAction<EUnitDetailsFilter>
  >;
  propertyId: string;
}

interface IInvitedUserEmail {
  emailAddress: string;
}

interface IShowInvitedResidentEmails {
  invitedResidents: IInvitedUserEmail[];
}

interface IShowInvitedResidentEmailsData {
  home: IShowInvitedResidentEmails;
}

const CancelMoveInModal: React.FC<ICancelMoveInModalProps> = ({
  propertyName,
  unitName,
  closeCancelMoveInModal,
  setUnitDetailsFilter,
  propertyId,
}) => {
  const dispatch = useDispatch();
  const genericError = useReactiveVar(userManagementGenericErrorVar);
  useEffect(() => {
    return () => {
      userManagementGenericErrorVar(EUserManagementGenericError.noError);
    };
  }, []);
  const { loading, data } = useQuery<
    IShowInvitedResidentEmailsData,
    IPropertyIdVariable
  >(GET_INVITED_RESIDENT_EMAILS, {
    variables: { propertyId },
  });
  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    throw Error("No data was returned");
  }
  const emailAddresses = Array.from(data.home.invitedResidents, (element) => {
    return element.emailAddress;
  });
  return (
    <div className="popup-background">
      <div className="tenants-show-container align-as-column">
        <div className="tenants-show-header">
          <div className="center-align-as-row">
            <TenantsImg title="Residents" />
            <div className="tenants-show-header-title menu">
              <b>Cancel Move In</b>
              <div className="tenants-show-header-title-name">
                : {unitName}, {propertyName}
              </div>
            </div>
          </div>
          <CloseIcon
            onClick={() => {
              closeCancelMoveInModal();
              setUnitDetailsFilter(EUnitDetailsFilter.residents);
            }}
            className="changes-header-img"
            title="close"
          />
        </div>
        <div className="tenants-show-body">
          <div className="tenants-show-description-container margin-left-right-small">
            Please confirm that you would like to cancel the move in for this
            unit.
            <div className="margin-top-small" />
            {renderUnitDetailsModalContent({
              descriptionList: [
                "All pending resident invites will be canceled.",
                "The unit’s Brilliant home will keep its existing settings and will not be reconfigured.",
              ],
              title: "If you cancel the move in:",
            })}
          </div>
          {genericError !== EUserManagementGenericError.noError && (
            <div
              data-testid="generic-error"
              className="tenants-show-description-error alert"
            >
              {
                userManagementGenericErrorMessages[
                  EUserManagementGenericError[genericError]
                ]
              }
            </div>
          )}
          <div className="invite-tenant-modal-actions margin-top-small">
            <button
              type="button"
              className="tertiary-btn-small tertiary-btn-small-alert"
              onClick={closeCancelMoveInModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="tertiary-btn-small tertiary-btn-small-main tertiary-btn-small-main-alert invite-tenant-modal-actions-submit"
              onClick={() => {
                dispatch(
                  cancelMoveIn({
                    emailAddresses,
                    propertyId,
                  })
                );
                closeCancelMoveInModal();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelMoveInModal;
