import InviteTenantForm from "../InviteTenantForm";

export interface InviteTenantStepOneProps {
  closeInviteTenantModal: () => void;
  propertyId: string;
  increaseCurrentStep: () => void;
  children?: React.ReactNode;
}

const InviteTenantStepOne: React.FC<InviteTenantStepOneProps> = ({
  closeInviteTenantModal,
  propertyId,
  increaseCurrentStep,
  children,
}) => {
  return (
    <div className="tenants-show-body">
      {children}
      <div className="tenants-show-form-container">
        <>
          <div className="menu tenants-show-form-title">
            Invite Resident by Email
          </div>

          <InviteTenantForm
            closeModalFn={() => {
              closeInviteTenantModal();
            }}
            propertyId={propertyId}
            increaseCurrentStep={increaseCurrentStep}
          />
        </>
      </div>
    </div>
  );
};

export default InviteTenantStepOne;
