import { connect, useDispatch } from "react-redux";

import { IAppState } from "../../interfaces/interfaces";
import { deleteScheduledConfigurationAction } from "../../actions";

export interface ConfirmWindowProps {
  cancelDeletion: () => void;
  id: string;
  homeId: string;
  title: string;
  date: string;
  time: string;
  buildingName: string;
  unitName: string;
}

const ConfirmWindow: React.FC<ConfirmWindowProps> = ({
  cancelDeletion,
  id,
  homeId,
  title,
  date,
  time,
  buildingName,
  unitName,
}) => {
  const dispatch = useDispatch();
  return (
    <div style={{ overflow: "initial" }} className="popup-background">
      <div className="cancel-container">
        <div className="cancel-title menu">
          Are you sure you want to remove this scheduled configuration?
        </div>
        <div className="cancel-info">
          <div className="cancel-info-body unit-description">
            {title} Configuration
          </div>
          <div>
            <div className="cancel-info-body unit-description">{unitName}</div>
            <div className="cancel-info-body unit-description cancel-info-body-bottom">
              {buildingName}
            </div>
          </div>
          <div>
            <div className="cancel-info-body unit-description">{time}</div>
            <div className="cancel-info-body unit-description cancel-info-body-bottom">
              {date}
            </div>
          </div>
        </div>
        <div className="center-align">
          <button
            className="primary-btn cancel-btn margin-right-small"
            onClick={() => {
              dispatch(deleteScheduledConfigurationAction(id, homeId));
            }}
            type="button"
          >
            Yes
          </button>
          <button
            className="primary-btn cancel-btn"
            onClick={() => cancelDeletion()}
            type="button"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    date: state.selectedScheduledConfiguration.date,
    id: state.selectedScheduledConfiguration.id,
    time: state.selectedScheduledConfiguration.time,
    title: state.selectedScheduledConfiguration.title,
  };
};

export default connect(mapStateToProps)(ConfirmWindow);
