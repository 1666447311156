import { useMutation, useReactiveVar } from "@apollo/client";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";

import {
  REMOTE_LOCK_LARGE_UPDATE_THRESHOLD,
  remoteLockDeviceType,
  sortAccessDevicesByName,
} from "../../utils/integrationUtils";
import { quantityDisplay } from "../../utils/utils";
import {
  IAccessDevice,
  IRemoteLockChangedHome,
  IRemoteLockIntegrationDevice,
  IRemotelockDeviceAssignment,
  IRemotelockDeviceRemoval,
} from "../../interfaces/interfaces";
import {
  currentChangedHomesVar,
  isEditAccessDevicesInProgressVar,
  showRemoteLockLargeUpdateModalVar,
} from "../apollo/LocalState";
import { ReactComponent as CloseIcon } from "../../img/Close.svg";
import { ReactComponent as Search } from "../../img/propertyDetails/Search.svg";
import {
  ADD_REMOTELOCK_DEVICES_TO_PROPERTIES,
  REMOVE_REMOTELOCK_DEVICES_TO_PROPERTIES,
} from "../../api/gqlQueries";
import Tooltip from "../../utils/Tooltip";

// Custom hook which checks if ref element is currently in a viewport
const useOnScreen = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
  const [isIntersecting, setIntersecting] = useState(true);

  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting)
  );

  useEffect(() => {
    if (ref.current instanceof Element) observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

const generateRemotelockDeviceRemovals = ({
  newAccessDevicesToRemove,
  propertyId,
}: {
  newAccessDevicesToRemove: IRemoteLockIntegrationDevice[];
  propertyId: string;
}) => {
  const remotelockDeviceRemovals: IRemotelockDeviceRemoval[] = [];
  _.map(newAccessDevicesToRemove, (accessDeviceToRemove) => {
    if (accessDeviceToRemove.remotelockAccessGrantId) {
      const newDevice: IRemotelockDeviceRemoval = {
        propertyId,
        remotelockAccessGrantId: accessDeviceToRemove.remotelockAccessGrantId,
        remotelockDeviceId: accessDeviceToRemove.id,
      };
      remotelockDeviceRemovals.push(newDevice);
    }
  });
  return remotelockDeviceRemovals;
};

const generateRemotelockDeviceAssignments = ({
  newAccessDevicesToAdd,
  propertyId,
}: {
  newAccessDevicesToAdd: IRemoteLockIntegrationDevice[];
  propertyId: string;
}) => {
  const remotelockDeviceAssignments: IRemotelockDeviceAssignment[] = [];
  _.map(newAccessDevicesToAdd, (accessDevice) => {
    const newDevice: IRemotelockDeviceAssignment = {
      deviceType: remoteLockDeviceType.unitDevice,
      propertyId,
      remotelockAccessibleType: accessDevice.accessibleType,
      remotelockDeviceId: accessDevice.id,
      remotelockDeviceName: accessDevice.name,
    };
    remotelockDeviceAssignments.push(newDevice);
  });
  return remotelockDeviceAssignments;
};

export const getRemoteLockIntegrationDevice = (
  element: IAccessDevice
): IRemoteLockIntegrationDevice => {
  const { id, device } = element;
  const newDevice = {
    ...device,
    remotelockAccessGrantId: id,
  };
  return newDevice;
};

// This function is used to generate device assignments and removals without conflict logic since it is getting removed after introduction of door groups.
export const generateDeviceAssignmentsAndRemovalsWithoutConflictLogic = ({
  propertyId,
  propertyName,
  remotelockIntegrationsToAdd,
  remotelockIntegrationsToRemoveFromHome,
  currentChangedHomesMap,
  buildingId,
}: {
  propertyId: string;
  propertyName: string;
  remotelockIntegrationsToAdd: IRemoteLockIntegrationDevice[]; // List of unit devices to be added.
  remotelockIntegrationsToRemoveFromHome: IRemoteLockIntegrationDevice[]; // List of unit devices to be removed.
  currentChangedHomesMap: Map<string, IRemoteLockChangedHome[]>; // This map is used to keep track of changed homes.
  buildingId: string;
}): {
  remotelockDeviceAssignments: IRemotelockDeviceAssignment[];
  remotelockDeviceRemovals: IRemotelockDeviceRemoval[];
  changedHomes: IRemoteLockChangedHome[];
} => {
  const remotelockDeviceAssignments: IRemotelockDeviceAssignment[] = [];
  const remotelockDeviceRemovals: IRemotelockDeviceRemoval[] = [];
  const currentChangedHomes = currentChangedHomesMap.get(buildingId) || [];
  // Make a shallow copy of the current changed homes to not modify the original.
  const changedHomes: IRemoteLockChangedHome[] = [...currentChangedHomes];

  remotelockDeviceAssignments.push(
    ...generateRemotelockDeviceAssignments({
      newAccessDevicesToAdd: remotelockIntegrationsToAdd,
      propertyId,
    })
  );

  remotelockDeviceRemovals.push(
    ...generateRemotelockDeviceRemovals({
      newAccessDevicesToRemove: remotelockIntegrationsToRemoveFromHome,
      propertyId,
    })
  );

  if (
    remotelockDeviceAssignments.length > 0 ||
    remotelockDeviceRemovals.length > 0
  ) {
    changedHomes.push({
      deviceType: remoteLockDeviceType.unitDevice,
      homeName: propertyName,
      id: propertyId,
      remotelockAccessDevices: remotelockIntegrationsToAdd.concat(
        remotelockIntegrationsToRemoveFromHome
      ),
    });
  }
  return {
    changedHomes,
    remotelockDeviceAssignments,
    remotelockDeviceRemovals,
  };
};

const renderSearchBar = ({
  searchValue,
  setSearchValue,
}: {
  searchValue: string;
  setSearchValue: (value: React.SetStateAction<string>) => void;
}) => {
  return (
    <div className="remote-lock-table-dropdown-search-box">
      <form
        className="remote-lock-table-dropdown-search-container center-align-as-row"
        onSubmit={(evt: React.SyntheticEvent) => {
          evt.preventDefault();
        }}
      >
        <Search title="Search" />
        <input
          type="text"
          data-testid="search-input"
          placeholder="Search"
          className="remote-lock-table-dropdown-search-input tool-tip"
          maxLength={50}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </form>
    </div>
  );
};

const renderSelectedAccessDevices = ({
  remotelockIntegrationsToAdd,
  setRemotelockIntegrationsToAdd,
  setSelectedAccessDevices,
  setAvailableAccessDevices,
  selectedAccessDevices,
  availableAccessDevices,
  remotelockIntegrationsToRemove,
  setRemotelockIntegrationsToRemove,
}: {
  remotelockIntegrationsToAdd: IRemoteLockIntegrationDevice[];
  setRemotelockIntegrationsToAdd: React.Dispatch<
    React.SetStateAction<IRemoteLockIntegrationDevice[]>
  >;
  setSelectedAccessDevices: (
    value: React.SetStateAction<IRemoteLockIntegrationDevice[]>
  ) => void;
  setAvailableAccessDevices: (
    value: React.SetStateAction<IRemoteLockIntegrationDevice[]>
  ) => void;
  selectedAccessDevices: IRemoteLockIntegrationDevice[];
  availableAccessDevices: IRemoteLockIntegrationDevice[];
  remotelockIntegrationsToRemove: IRemoteLockIntegrationDevice[];
  setRemotelockIntegrationsToRemove: React.Dispatch<
    React.SetStateAction<IRemoteLockIntegrationDevice[]>
  >;
}) => {
  if (selectedAccessDevices.length === 0) {
    return (
      <>
        <div className="remote-lock-table-device-empty tool-tip">
          Select doors below...
        </div>
      </>
    );
  }

  return selectedAccessDevices.map((selectedAccessDevice) => {
    return (
      <div
        className="remote-lock-table-device-name-container tooltip remote-lock-table-device-name-large secondary-btn"
        key={selectedAccessDevice.id}
      >
        <Tooltip tooltipText={selectedAccessDevice.name}>
          <div className="remote-lock-table-device-name">
            {selectedAccessDevice.name}
          </div>
        </Tooltip>
        <CloseIcon
          title={`Remove access device ${selectedAccessDevice.name}`}
          className="remote-lock-table-device-name-icon"
          onClick={() => {
            // Check if device is assigned to the property.
            // It is done to check if device is intended to be removed or if the user change his mind and no longer wants to add device to the home.
            if (selectedAccessDevice.remotelockAccessGrantId) {
              const remotelockIntegrations: IRemoteLockIntegrationDevice[] = [
                ...remotelockIntegrationsToRemove,
              ];
              remotelockIntegrations.push(selectedAccessDevice);
              setRemotelockIntegrationsToRemove(remotelockIntegrations);
            } else {
              setRemotelockIntegrationsToAdd(
                _.filter(remotelockIntegrationsToAdd, ({ id }) => {
                  return id !== selectedAccessDevice.id;
                })
              );
            }
            setSelectedAccessDevices(
              _.without(selectedAccessDevices, selectedAccessDevice)
            );
            setAvailableAccessDevices(
              sortAccessDevicesByName(
                availableAccessDevices.concat(selectedAccessDevice)
              )
            );
          }}
        />
      </div>
    );
  });
};

const renderAvailableAccessDevices = ({
  filteredAvailableAccessDevices,
  setSelectedAccessDevices,
  setAvailableAccessDevices,
  selectedAccessDevices,
  availableAccessDevices,
  remotelockIntegrationsToAdd,
  setRemotelockIntegrationsToAdd,
  remotelockIntegrationsToRemove,
  setRemotelockIntegrationsToRemove,
  scrollRef,
}: {
  filteredAvailableAccessDevices: IRemoteLockIntegrationDevice[];
  setSelectedAccessDevices: (
    value: React.SetStateAction<IRemoteLockIntegrationDevice[]>
  ) => void;
  setAvailableAccessDevices: (
    value: React.SetStateAction<IRemoteLockIntegrationDevice[]>
  ) => void;
  selectedAccessDevices: IRemoteLockIntegrationDevice[];
  availableAccessDevices: IRemoteLockIntegrationDevice[];
  remotelockIntegrationsToAdd: IRemoteLockIntegrationDevice[];
  setRemotelockIntegrationsToAdd: React.Dispatch<
    React.SetStateAction<IRemoteLockIntegrationDevice[]>
  >;
  remotelockIntegrationsToRemove: IRemoteLockIntegrationDevice[];
  setRemotelockIntegrationsToRemove: React.Dispatch<
    React.SetStateAction<IRemoteLockIntegrationDevice[]>
  >;
  scrollRef: any;
}) => {
  return (
    <div className="remote-lock-table-dropdown-search-devices-container">
      <div className="remote-lock-table-dropdown-available-device-container">
        {filteredAvailableAccessDevices.length === 0 && (
          <>
            <p
              data-testid="no-assigned-devices-found"
              className="remote-lock-table-dropdown-available-device-empty"
            >
              No Unassigned Doors Found
            </p>
          </>
        )}
        {filteredAvailableAccessDevices.map((accessDevice) => {
          return (
            <button
              type="button"
              key={accessDevice.id}
              className="remote-lock-table-dropdown-available-device tool-tip"
              onClick={() => {
                // Check if device is not assigned to the property.
                if (!accessDevice.remotelockAccessGrantId) {
                  const remotelockIntegrations: IRemoteLockIntegrationDevice[] =
                    [...remotelockIntegrationsToAdd];
                  remotelockIntegrations.push(accessDevice);
                  setRemotelockIntegrationsToAdd(remotelockIntegrations);
                } else {
                  setRemotelockIntegrationsToRemove(
                    _.filter(remotelockIntegrationsToRemove, ({ id }) => {
                      return id !== accessDevice.id;
                    })
                  );
                }
                // Local component state changes
                setSelectedAccessDevices(
                  _.concat(selectedAccessDevices, accessDevice)
                );
                setAvailableAccessDevices(
                  _.without(availableAccessDevices, accessDevice)
                );
              }}
            >
              {accessDevice.numDevices !== undefined
                ? `${accessDevice.name} (${
                    accessDevice.numDevices
                  } ${quantityDisplay(accessDevice.numDevices, "Door")})`
                : accessDevice.name}
            </button>
          );
        })}
      </div>
      <div ref={scrollRef} />
    </div>
  );
};

export interface IEditAccessDevicesDropdownProps {
  availableDevices: IRemoteLockIntegrationDevice[];
  propertyId: string;
  propertyName: string;
  setShowEditAccessDevicesDropdown: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setSelectedAccessDevices: (
    value: React.SetStateAction<IRemoteLockIntegrationDevice[]>
  ) => void;
  selectedAccessDevices: IRemoteLockIntegrationDevice[];
  startPolling: (pollInterval: number) => void;
  deselectAllHomes: () => void;
  originallyAssignedAccessDevices: IRemoteLockIntegrationDevice[];
  buildingId: string;
  modalRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const EditAccessDevicesDropdown: React.FC<IEditAccessDevicesDropdownProps> = ({
  availableDevices,
  setShowEditAccessDevicesDropdown,
  setSelectedAccessDevices,
  selectedAccessDevices,
  propertyId,
  startPolling,
  deselectAllHomes,
  originallyAssignedAccessDevices,
  buildingId,
  propertyName,
  modalRef,
}) => {
  sortAccessDevicesByName(availableDevices);

  const [searchValue, setSearchValue] = useState("");
  const [availableAccessDevices, setAvailableAccessDevices] =
    useState<IRemoteLockIntegrationDevice[]>(availableDevices);

  // `remotelockIntegrationsToAdd` tracks the access devices selected
  // to be added to the unit.
  const [remotelockIntegrationsToAdd, setRemotelockIntegrationsToAdd] =
    useState<IRemoteLockIntegrationDevice[]>([]);

  // `remotelockIntegrationsToRemove` tracks the access devices selected
  // to be deleted from the unit.
  const [remotelockIntegrationsToRemove, setRemotelockIntegrationsToRemove] =
    useState<IRemoteLockIntegrationDevice[]>([]);

  const [addAccessDevices] = useMutation<
    { count: string },
    { remotelockDeviceAssignments: IRemotelockDeviceAssignment[] }
  >(ADD_REMOTELOCK_DEVICES_TO_PROPERTIES);

  const [removeAccessDevices] = useMutation<
    { recordIds: string[] },
    { remotelockDeviceRemovals: IRemotelockDeviceRemoval[] }
  >(REMOVE_REMOTELOCK_DEVICES_TO_PROPERTIES);

  const currentChangedHomesMap = useReactiveVar(currentChangedHomesVar);

  const filteredAvailableAccessDevices = _.filter(
    availableAccessDevices,
    (accessDevice) => {
      return accessDevice.name
        .toLocaleLowerCase()
        .includes(searchValue.toLowerCase());
    }
  );

  const closeEditAccessDevicesDropdownComponent = () => {
    setShowEditAccessDevicesDropdown(false);
    setSelectedAccessDevices(originallyAssignedAccessDevices);
    setAvailableAccessDevices(sortAccessDevicesByName(availableDevices));
    setRemotelockIntegrationsToAdd([]);
    setRemotelockIntegrationsToRemove([]);
    isEditAccessDevicesInProgressVar(false);
  };

  const ref = useRef<null | HTMLDivElement>(null);

  const scrollRef = useRef<null | HTMLDivElement>(null);
  const isEndOfTheDropdownVisible = useOnScreen(scrollRef);

  useEffect(() => {
    setAvailableAccessDevices(availableDevices);
    const close = (e: KeyboardEvent) => {
      if (e.key === "Escape") closeEditAccessDevicesDropdownComponent();
    };
    const handleClickOutside = (event: any) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        modalRef?.current &&
        !modalRef.current.contains(event.target)
      )
        closeEditAccessDevicesDropdownComponent();
    };
    window.addEventListener("keydown", close);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      window.removeEventListener("keydown", close);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (!isEndOfTheDropdownVisible) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isEndOfTheDropdownVisible]);

  const handleAssignmentChanges = () => {
    if (
      remotelockIntegrationsToAdd.length === 0 &&
      remotelockIntegrationsToRemove.length === 0
    ) {
      setShowEditAccessDevicesDropdown(false);
      isEditAccessDevicesInProgressVar(false);
      return;
    }
    const {
      remotelockDeviceAssignments,
      remotelockDeviceRemovals,
      changedHomes,
    } = generateDeviceAssignmentsAndRemovalsWithoutConflictLogic({
      buildingId,
      currentChangedHomesMap,
      propertyId,
      propertyName,
      remotelockIntegrationsToAdd,
      remotelockIntegrationsToRemoveFromHome: remotelockIntegrationsToRemove,
    });
    const executeTransactions = () => {
      setShowEditAccessDevicesDropdown(false);
      isEditAccessDevicesInProgressVar(false);
      currentChangedHomesMap.set(buildingId, changedHomes);
      currentChangedHomesVar(currentChangedHomesMap);
      setSelectedAccessDevices(originallyAssignedAccessDevices);
      if (remotelockDeviceAssignments.length > 0) {
        addAccessDevices({
          variables: {
            remotelockDeviceAssignments,
          },
        });
        setRemotelockIntegrationsToAdd([]);
      }
      if (remotelockDeviceRemovals.length > 0) {
        removeAccessDevices({
          variables: {
            remotelockDeviceRemovals,
          },
        });
        setRemotelockIntegrationsToRemove([]);
      }
      if (
        remotelockDeviceAssignments.length > 0 ||
        remotelockDeviceRemovals.length > 0
      ) {
        startPolling(1000);
        deselectAllHomes();
      }
    };
    const totalChanges =
      remotelockDeviceAssignments.length + remotelockDeviceRemovals.length;
    if (totalChanges >= REMOTE_LOCK_LARGE_UPDATE_THRESHOLD) {
      showRemoteLockLargeUpdateModalVar({
        confirmFn: executeTransactions,
        isShowing: true,
      });
    } else {
      executeTransactions();
    }
  };

  return (
    <div ref={ref} className="remote-lock-table-dropdown-container">
      <div className="remote-lock-table-dropdown-devices-checkmark-container">
        <div className="remote-lock-table-dropdown-devices-container">
          {renderSelectedAccessDevices({
            availableAccessDevices,
            remotelockIntegrationsToAdd,
            remotelockIntegrationsToRemove,
            selectedAccessDevices,
            setAvailableAccessDevices,
            setRemotelockIntegrationsToAdd,
            setRemotelockIntegrationsToRemove,
            setSelectedAccessDevices,
          })}
        </div>
        <button
          type="button"
          className="remote-lock-table-dropdown-checkmark-container"
          data-testid="checkmark"
          onClick={() => {
            handleAssignmentChanges();
          }}
        >
          <div className="remote-lock-table-dropdown-checkmark" />
        </button>
      </div>
      {renderSearchBar({
        searchValue,
        setSearchValue,
      })}
      {renderAvailableAccessDevices({
        availableAccessDevices,
        filteredAvailableAccessDevices,
        remotelockIntegrationsToAdd,
        remotelockIntegrationsToRemove,
        scrollRef,
        selectedAccessDevices,
        setAvailableAccessDevices,
        setRemotelockIntegrationsToAdd,
        setRemotelockIntegrationsToRemove,
        setSelectedAccessDevices,
      })}
    </div>
  );
};

export default EditAccessDevicesDropdown;
