import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import LoginReducer from "./reducers/LoginReducer";
import RegistrationReducer from "./reducers/RegistrationReducer";
import EntrataReducer from "./reducers/EntrataReducer";
import LoaderReducer from "./reducers/LoaderReducer";
import SchedulerReducer from "./reducers/SchedulerReducer";
import scheduledConfigurationReducer from "./reducers/scheduledConfigurationReducer";
import UserRegistration from "./reducers/UserRegistrationReducer";
import userManagementReducer from "./reducers/userManagementReducer";

export default combineReducers({
  entrataData: EntrataReducer,
  form: formReducer,
  loading: LoaderReducer,
  newUser: RegistrationReducer,
  newUserRegistration: UserRegistration,
  schedulerDatetime: SchedulerReducer,
  selectedScheduledConfiguration: scheduledConfigurationReducer,
  user: LoginReducer,
  userManagement: userManagementReducer,
});
