import { useDispatch } from "react-redux";
import { useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";

import { SelectedTableEntry } from "../../tables/UsersTables";
import { resendInviteTenant } from "../../../actions/actionCreators/tenantManagement";
import { isResendInvitationErrorVar } from "../../apollo/LocalState";
import { ShowTenantSteps } from "../TenantManagementSteps";
import { ReactComponent as Arrow } from "../../../img/Arrow.svg";
import { EShowTenantSteps } from "../../../interfaces/interfaces";

import AccessCredentials from "./AccessCredentials";

export interface ShowTenantProps {
  increaseCurrentStep: () => void;
  hasInvitation: boolean;
  closeRemoveTenantModal: () => void;
  hasIntegration: boolean;
  propertyId: string;
  emailAddress: string;
  hasRemoteLockIntegration: boolean;
  userId: string;
  isSmartCardCredentialsEnabledStories?: boolean;
}

const ShowTenant: React.FC<ShowTenantProps> = ({
  increaseCurrentStep,
  hasInvitation,
  closeRemoveTenantModal,
  hasIntegration,
  propertyId,
  emailAddress,
  hasRemoteLockIntegration,
  userId,
  isSmartCardCredentialsEnabledStories = false,
}) => {
  const isResendInvitationError = useReactiveVar(isResendInvitationErrorVar);
  const [showTenantCurrentStep, setShowTenantCurrentCurrentStep] = useState(
    EShowTenantSteps.ShowTenantGeneralStep
  );
  const [startClickOutAnimation, setStartClickOutAnimation] = useState(false);
  const [focusedFilter, setFocusedFilter] = useState(true);

  useEffect(() => {
    return () => {
      isResendInvitationErrorVar(false);
    };
  }, []);
  const dispatch = useDispatch();
  const actionButtonText = hasInvitation ? "Cancel Invite" : "Remove Resident";

  return (
    <>
      {hasRemoteLockIntegration && (
        <>
          <div className="tenants-show-navigation-container">
            <div
              className={`tenants-show-navigation-link ${
                showTenantCurrentStep ===
                  EShowTenantSteps.ShowTenantGeneralStep &&
                "tenants-show-navigation-link-selected"
              }`}
              onClick={() =>
                setShowTenantCurrentCurrentStep(
                  EShowTenantSteps.ShowTenantGeneralStep
                )
              }
              aria-hidden="true"
            >
              <div data-testid="general-tab">
                {ShowTenantSteps.get(EShowTenantSteps.ShowTenantGeneralStep)}
              </div>
              <div
                className={` ${
                  showTenantCurrentStep ===
                  EShowTenantSteps.ShowTenantGeneralStep
                    ? "tenants-show-navigation-underline"
                    : "tenants-show-navigation-underline-hidden"
                }`}
              />
            </div>
            <div
              className={`tenants-show-navigation-link ${
                showTenantCurrentStep ===
                  EShowTenantSteps.ShowTenantAccessCredentialsStep &&
                "tenants-show-navigation-link-selected"
              }`}
              onClick={() => {
                setStartClickOutAnimation(true);
                setShowTenantCurrentCurrentStep(
                  EShowTenantSteps.ShowTenantAccessCredentialsStep
                );
              }}
              aria-hidden="true"
            >
              <div data-testid="access-credentials-tab">
                {ShowTenantSteps.get(
                  EShowTenantSteps.ShowTenantAccessCredentialsStep
                )}
              </div>
              {startClickOutAnimation && (
                <div
                  className={`${
                    showTenantCurrentStep ===
                    EShowTenantSteps.ShowTenantAccessCredentialsStep
                      ? "tenants-show-navigation-underline"
                      : "tenants-show-navigation-underline-hidden"
                  }`}
                />
              )}
            </div>
          </div>
          <div className="tenants-show-navigation-container-small">
            {focusedFilter ? (
              <button
                className="filter-container"
                onClick={() => {
                  setFocusedFilter(false);
                }}
                type="button"
              >
                <div className="flex-with-space-between">
                  <div className="center-align-as-row">
                    <div className="sub-menu filter-name-selected sub-menu-selected">
                      {ShowTenantSteps.get(showTenantCurrentStep)}
                    </div>
                  </div>
                  <div className="center-align-as-row">
                    <Arrow title="Expand" className="arrow-down" />
                  </div>
                </div>
              </button>
            ) : (
              <div className="tenants-show-navigation-container-small-inner">
                {Array.from(ShowTenantSteps.entries()).map((entry) => {
                  return (
                    <button
                      className="filter-container"
                      onClick={() => {
                        setStartClickOutAnimation(true);
                        setFocusedFilter(true);
                        setShowTenantCurrentCurrentStep(entry[0]);
                      }}
                      type="button"
                    >
                      <div className="flex-with-space-between">
                        <div
                          className={`${
                            entry[0] === showTenantCurrentStep &&
                            "filter-name-selected sub-menu-selected"
                          } sub-menu`}
                        >
                          {entry[1]}
                        </div>
                      </div>
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
      {showTenantCurrentStep === EShowTenantSteps.ShowTenantGeneralStep && (
        <div>
          <div
            className={`tenants-show-description margin-left-right-small ${
              hasIntegration ? "" : "margin-top-small"
            }`}
          >
            The following resident has full access and permissions for this
            unit.
          </div>
          <div className="tenants-show-description-body margin-left-right-small">
            <SelectedTableEntry />
          </div>

          <div className="tenants-resend-error-container margin-left-right-small">
            {isResendInvitationError && (
              <p className="alert tenants-resend-error-large">
                Something went wrong resending the invite. Please try again.
              </p>
            )}
            <div className="invite-tenant-modal-actions tenants-show-move-out-btn-container-new-flow">
              {!hasIntegration && (
                <button
                  type="button"
                  className={`tertiary-btn-small ${
                    hasInvitation ? "" : "tertiary-btn-small-alert"
                  }`}
                  onClick={increaseCurrentStep}
                >
                  {actionButtonText}
                </button>
              )}
              {hasInvitation && (
                <button
                  type="button"
                  className="tertiary-btn-small user-card-action-btn user-card-action-btn-resend"
                  data-testid="user-card-action-btn-resend-large-view"
                  onClick={() => {
                    dispatch(
                      resendInviteTenant({
                        email: emailAddress,
                        onSuccessAction: () => {
                          closeRemoveTenantModal();
                        },
                        propertyId,
                      })
                    );
                  }}
                >
                  Resend Invite
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {showTenantCurrentStep ===
        EShowTenantSteps.ShowTenantAccessCredentialsStep && (
        <AccessCredentials
          closeRemoveTenantModal={closeRemoveTenantModal}
          hasInvitation={hasInvitation}
          emailAddress={emailAddress}
          propertyId={propertyId}
          userId={userId}
          isSmartCardCredentialsEnabledStories={
            isSmartCardCredentialsEnabledStories
          }
        />
      )}
    </>
  );
};

export default ShowTenant;
