import { connect } from "react-redux";

import {
  ELoginError,
  IAppState,
  ILoginData,
} from "../../interfaces/interfaces";

const renderInputField: React.FC<{
  input: { value: string };
  type: string;
  user: ILoginData;
  autoFocus: boolean;
}> = ({ input, type, user, autoFocus }) => {
  if (input.value.length > 6) {
    input.value = input.value.slice(0, 6);
  }
  return (
    <input
      data-testid="two-factor-input"
      className={`${
        user.error !== ELoginError.noError ? "right-form-input-alert " : ""
      }right-form-input`}
      {...input}
      type={type}
      /* eslint-disable jsx-a11y/no-autofocus */
      autoFocus={autoFocus}
    />
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    user: state.user,
  };
};
export default connect(mapStateToProps)(renderInputField);
