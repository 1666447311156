import { useState } from "react";

import { alertImage, alertTitle } from "../../utils/alertUtils";

import AlertModal from "./AlertModal";

export interface AlertCardProps {
  alarmType: string;
  buildingName: string;
  unitName: string;
  deviceName: string;
  ambientTemperatureF?: number;
  deviceType?: string;
}

const AlertCard: React.FC<AlertCardProps> = ({
  unitName,
  alarmType,
  buildingName,
  deviceName,
  ambientTemperatureF,
  deviceType,
}) => {
  const [showAlertPopupModal, setAlertPopupModal] = useState(false);
  return (
    <>
      {showAlertPopupModal && (
        <AlertModal
          alarmType={alarmType}
          closeAlertModal={() => setAlertPopupModal(false)}
          buildingName={buildingName}
          unitName={unitName}
          deviceName={deviceName}
          ambientTemperatureF={ambientTemperatureF}
          deviceType={deviceType}
        />
      )}
      <button
        type="button"
        data-testid="alert-card-btn"
        className="alert-card-container"
        onClick={() => setAlertPopupModal(true)}
      >
        <div className="center-align-as-row">
          <div className="alert-card-red-line" />
          <div className="alert-card-description">
            <div className="margin-bottom-very-small alert-card-title">
              <div
                data-testid="alert-card-title"
                className="sub-menu sub-menu-selected"
              >
                {alertTitle[alarmType]}
              </div>
            </div>
            <div className="center-align-as-row">
              {alertImage(alarmType)}
              <div className="sub-heading">{unitName}</div>
            </div>
          </div>
        </div>
      </button>
    </>
  );
};

export default AlertCard;
