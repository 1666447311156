import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { connect } from "react-redux";

import { ReactComponent as Settings } from "../../img/settings.svg";
import InviteManagerModal from "../header/InviteManagerModal";
import { UsersTables } from "../tables/UsersTables";
import { GET_PORTAL_USERS_INFO } from "../../api/gqlQueries";
import {
  IAppState,
  IInvitedUser,
  IOrganizationIdVariable,
  IRegisteredUser,
  IShowPortalUsersData,
} from "../../interfaces/interfaces";
import Spinner from "../Spinner";
import { selectedUserToShowVar, smallHeaderTitle } from "../apollo/LocalState";

import RemoveManagerModal from "./RemoveManagerModal";

export interface EditManagersProps {
  organizationId: string;
}

const EditManagers: React.FC<EditManagersProps> = ({ organizationId }) => {
  useEffect(() => {
    smallHeaderTitle("Edit Managers");
    return () => {
      smallHeaderTitle("");
    };
  }, []);
  const [showInviteManagerModal, setShowInviteManagerModal] = useState(false);
  const [showRemoveManagerModal, setRemoveManagerModal] = useState(false);
  const openRemoveManagerModal = (
    user: IRegisteredUser | IInvitedUser | null
  ) => {
    selectedUserToShowVar(user);
    setRemoveManagerModal(true);
  };
  const { loading, data, refetch } = useQuery<
    IShowPortalUsersData,
    IOrganizationIdVariable
  >(GET_PORTAL_USERS_INFO, {
    variables: { organizationId },
  });
  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    throw Error("No data was returned");
  }
  const shouldEmptyTableRender =
    data.organization.portalUsers.length === 0 &&
    data.organization.invitedPortalUsers.length === 0;
  return (
    <div className="managers-container center-align">
      {showInviteManagerModal && (
        <InviteManagerModal
          closeModalFn={() => setShowInviteManagerModal(false)}
          refetch={refetch}
        />
      )}
      {showRemoveManagerModal && (
        <RemoveManagerModal
          closeRemoveManagerModal={() => setRemoveManagerModal(false)}
          refetch={refetch}
          organizationName={data.organization.name}
        />
      )}
      <div className="managers-container-inner padding-bottom-large">
        <div className="managers-header-container">
          <div className="managers-header unit-title">
            <Settings title="Settings" className="managers-header-icon" />
            Edit Managers
          </div>
        </div>
        <div className="managers-description menu">
          The following users are managers of the {data.organization.name}{" "}
          organization
        </div>
        <div className="margin-top-small" />
        <div className="managers-description-table">
          <UsersTables
            invitedPortalUsers={data.organization.invitedPortalUsers}
            portalUsers={data.organization.portalUsers}
            shouldEmptyTableRender={shouldEmptyTableRender}
            openRemoveFlowModal={openRemoveManagerModal}
            shouldTrashRender
            shouldGearRender={false}
          />
        </div>
        <button
          onClick={() => setShowInviteManagerModal(true)}
          type="button"
          className="primary-btn managers-btn"
        >
          Invite Manager
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    organizationId: state.user.organizationId,
  };
};

export default connect(mapStateToProps)(EditManagers);
