import { Dispatch } from "redux";
import { connect, useDispatch } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { ERegistrationError, IAppState } from "../../interfaces/interfaces";
import {
  maxLength1000,
  normalizePhone,
  phoneNumberLength,
  required,
} from "../../formValidation/FormValidation";
import { addPhoneNumber, clearRegistrationError } from "../../actions";
import SubmitButton from "../buttons/SubmitButton";

import InputField from "./common/InputField";

interface IInputMFAPhoneNumberFormCustomProps {
  registrationError: ERegistrationError;
}

interface IInputMFAPhoneNumberFormData {
  phoneNumber: string;
}

const InputMFAPhoneNumberForm: React.FC<
  IInputMFAPhoneNumberFormCustomProps &
    InjectedFormProps<
      IInputMFAPhoneNumberFormData,
      IInputMFAPhoneNumberFormCustomProps
    >
> = ({ handleSubmit, invalid, submitting, pristine }) => {
  const dispatch = useDispatch();
  const clearError = () => dispatch(clearRegistrationError);

  return (
    <form onSubmit={handleSubmit} className="right-form">
      <label htmlFor="phoneNumberField">Mobile Number</label>
      <Field
        id="phoneNumberField"
        name="phoneNumber"
        normalize={normalizePhone}
        placeholder="(111) 111-1111"
        validate={[required, phoneNumberLength, maxLength1000]}
        component={InputField}
        type="input"
        onChange={clearError}
      />

      <SubmitButton
        disabled={invalid || submitting || pristine}
        name="Confirm"
        decorator="right-form-btn"
      />
    </form>
  );
};

const onSubmit = (
  values: IInputMFAPhoneNumberFormData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
) => {
  dispatch(addPhoneNumber(values.phoneNumber));
};

const ReduxForm = reduxForm<
  IInputMFAPhoneNumberFormData,
  IInputMFAPhoneNumberFormCustomProps
>({
  enableReinitialize: true,
  form: "inputMFAPhoneNumberForm",
  onSubmit,
})(InputMFAPhoneNumberForm);

const mapStateToProps = (state: IAppState) => ({
  phoneNumber: state.newUser.phoneNumber,
  registrationError: state.newUser.error,
  userExists: state.newUser.userId.length !== 0,
});

export default connect(mapStateToProps)(ReduxForm);
