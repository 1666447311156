import { ReactComponent as ExtremeTemperatureAlert } from "../img/ExtremeTemperatureAlert.svg";
import { ReactComponent as LeakAlert } from "../img/LeakAlert.svg";
import { ReactComponent as Reconfiguration } from "../img/Reconfiguration.svg";
import { ReactComponent as LowBatteryImg } from "../img/LowBattery.svg";
import { ReactComponent as Tenants } from "../img/Tenants.svg";
import { ReactComponent as OfflineDevice } from "../img/OfflineDevice.svg";
import { IAlert, IWarning } from "../interfaces/interfaces";

export enum AlertTypes {
  ExtremeTemperature = "BRL_EXTREME_TEMPERATURE",
  LeakDetected = "WATER_DETECTED",
  ResidentInviteFailed = "INVITATION_FAILED",
  ReconfigurationTakingTooLong = "RECONFIGURATION_TOO_LONG",
  LowBattery = "LOW_BATTERY",
  OfflineDevices = "OFFLINE_DEVICES",
}

export const devicesAlerts: string[] = [
  AlertTypes.ExtremeTemperature,
  AlertTypes.LeakDetected,
  AlertTypes.LowBattery,
  AlertTypes.OfflineDevices,
];
export const residentTransitionAlerts: string[] = [
  AlertTypes.ResidentInviteFailed,
  AlertTypes.ReconfigurationTakingTooLong,
];

export const warningTitle: { [key: string]: string } = {
  INSTALL_INCOMPLETE: "Install Incomplete",
  OFFLINE_DEVICES: "Devices Offline",
};

export const alertTitle: { [key: string]: string } = {
  BRL_EXTREME_TEMPERATURE: "Climate Issue",
  INVITATION_FAILED: "Resident Invite Failed",
  LOW_BATTERY: "Device Low Battery",
  OFFLINE_DEVICES: "Device Offline",
  RECONFIGURATION_TOO_LONG: "Unit Reconfiguration Issue",
  WATER_DETECTED: "Leak Detected",
};

export const alertDescription: { [key: string]: string } = {
  BRL_EXTREME_TEMPERATURE:
    "Get notified when an unusual temperature is detected in your property. Unusual temperature is defined as a temperature reading below 50°F or over 90°F. You must have Honeywell Thermostats installed in your units to use this feature.",
  INVITATION_FAILED:
    "Get notified if Brilliant fails to automatically invite a resident that was added in a linked Property Management Software (PMS) system.",
  LOW_BATTERY:
    "Get notified when a battery-powered partner device integrated with Brilliant is running low on power. ",
  OFFLINE_DEVICES:
    "Get notified when your Brilliant Control(s) have been offline for over 24 hours.",
  RECONFIGURATION_TOO_LONG:
    "Get notified if an issue occurs while a unit’s devices are being reconfigured with default settings during a move-in or move-out.",
  WATER_DETECTED:
    "Get notified when a leak is detected in your property. You must have Honeywell Leak Detectors installed in your units to use this feature.",
};

export const alertInfo: { [key: string]: string } = {
  INVITATION_FAILED: "Unit’s Installation Is Still Incomplete ",
  RECONFIGURATION_TOO_LONG: "Brilliant Devices Taking Too Long to Reconfigure",
};

export const alertImage = (alarmType: string, popup?: boolean): JSX.Element => {
  const popupSVGClass = "alert-popup-description-svg";
  if (alarmType === AlertTypes.LeakDetected) {
    return (
      <LeakAlert
        title="Leak detected"
        className={
          popup
            ? `${popupSVGClass} ${popupSVGClass}-leak`
            : "alert-card-description-svg margin-right-smaller"
        }
      />
    );
  }
  if (alarmType === AlertTypes.ExtremeTemperature) {
    return (
      <ExtremeTemperatureAlert
        title="Extreme temperature detected"
        className={
          popup
            ? `${popupSVGClass} ${popupSVGClass}-extreme-temperature`
            : "alert-card-description-svg margin-right-smaller"
        }
      />
    );
  }
  if (alarmType === AlertTypes.ResidentInviteFailed) {
    return (
      <Tenants
        title="Invitation Failed detected"
        className={
          popup
            ? `${popupSVGClass}-failed-invite`
            : "alert-card-description-svg-tenant margin-right-smaller"
        }
      />
    );
  }
  if (alarmType === AlertTypes.ReconfigurationTakingTooLong) {
    return (
      <Reconfiguration
        title="Reconfiguration Issue Alert"
        className={
          popup
            ? `${popupSVGClass} ${popupSVGClass}-reconfiguration`
            : "alert-card-description-svg-tenant margin-right-smaller"
        }
      />
    );
  }
  if (alarmType === AlertTypes.LowBattery) {
    return (
      <LowBatteryImg
        title="Low Battery Alert"
        className={
          popup
            ? `${popupSVGClass} ${popupSVGClass}-low-battery`
            : "alert-card-description-svg-low-battery margin-right-smaller"
        }
      />
    );
  }
  if (alarmType === AlertTypes.OfflineDevices) {
    return (
      <OfflineDevice
        title="Offline Device Alert"
        className={
          popup
            ? `${popupSVGClass} ${popupSVGClass}-offline-device`
            : "alert-card-description-svg-offline-device margin-right-smaller"
        }
      />
    );
  }
  return <></>;
};

export const getWarningNames = (warnings: IWarning[]): string[] => {
  const warningNames = warnings.map(
    (warning: IWarning) => warningTitle[warning.warningType]
  );
  return warningNames;
};

export const getClimateAlertNames = (alerts: IAlert[]): string[] => {
  const alertTitles = alerts.map((climateAlarm) => {
    // The ReconfigurationTakingTooLong title has a shortened form for tags.
    if (climateAlarm.alarmType === AlertTypes.ReconfigurationTakingTooLong) {
      return "Reconfiguration Issue";
    }
    return alertTitle[climateAlarm.alarmType];
  });
  return alertTitles.sort();
};

export const renderAlertTitle = ({
  ambientTemperatureF,
  alarmType,
}: {
  ambientTemperatureF: number | undefined;
  alarmType: string;
}): string => {
  if (alarmType === AlertTypes.ExtremeTemperature) {
    return `${alertTitle[alarmType]} - ${ambientTemperatureF}°F Detected`;
  }
  return alertTitle[alarmType];
};
