import { ReactComponent as Connect } from "../../img/Connect.svg";

import ToolTipUpper from "./TooltipUpper";

export interface IIntegrationLinkProps {
  integrationName: string;
}

const IntegrationLink: React.FC<IIntegrationLinkProps> = ({
  integrationName,
}) => {
  return (
    <div className="integrations-tag-container tooltip">
      <Connect title="connect" />
      <div className="unit-tag integrations-tag-text">
        Linked to {integrationName}
      </div>
      <ToolTipUpper
        upperContainerOverrideClass="integrations-tag-upper-tooltip"
        text={`Brilliant is monitoring this unit's lease info in ${integrationName} to transition residents automatically.`}
      />
    </div>
  );
};

export default IntegrationLink;
