import { useReactiveVar } from "@apollo/client";
import { useEffect } from "react";

import { EReconfigurationError } from "../../../interfaces/interfaces";
import { reconfigurationErrorVar } from "../../apollo/LocalState";
import { reconfigurationErrorMessage } from "../../../utils/errorMessages";

import SplitScreenLayout from "./SplitScreenLayout";

export interface CompleteAccountStepProps {
  form: React.ReactNode;
  isUser?: boolean;
}

const CompleteAccountStep: React.FC<CompleteAccountStepProps> = ({
  form,
  isUser,
}) => {
  const reconfigurationError = useReactiveVar(reconfigurationErrorVar);
  useEffect(() => {
    return () => {
      reconfigurationErrorVar(EReconfigurationError.noError);
    };
  }, []);
  return (
    <SplitScreenLayout
      form={form}
      leftTitle="Let's complete your account!"
      leftDescription="For security purposes, please provide the requested
      information to create your own Brilliant Account."
      rightMessage=""
      isUser={isUser}
      reconfigurationError={
        reconfigurationErrorMessage[EReconfigurationError[reconfigurationError]]
      }
    />
  );
};

export default CompleteAccountStep;
