import { LOADER_START, LOADER_STOP } from "../../actions/types";
import { IActionTypeOnly } from "../../interfaces/interfaces";

export default (state = false, action: IActionTypeOnly): boolean => {
  switch (action.type) {
    case LOADER_START:
      return true;
    case LOADER_STOP:
      return false;
    default:
      return state;
  }
};
