import { Slide, ToastContainer } from "react-toastify";

const ToastMessages: React.FC = () => {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={3000}
      closeButton={false}
      hideProgressBar
      transition={Slide}
    />
  );
};

export default ToastMessages;
