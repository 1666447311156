import { Dispatch } from "redux";
import { AxiosError, AxiosResponse } from "axios";

import { brilliantRequest, brilliantRequestDelete } from "./api";

export const postApplyConfiguration = (
  homeId: string,
  configurationId: string,
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => boolean
): void => {
  const params = { configuration_id: configurationId };
  brilliantRequest(
    `/homes/${homeId}/configurations`,
    params,
    dispatch,
    onSuccess,
    onError
  );
};

export const postScheduleConfiguration = (
  homeId: string,
  configurationId: string,
  date: string,
  time: string,
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => boolean
): void => {
  const params = { configuration_id: configurationId, date, time };
  brilliantRequest(
    `/homes/${homeId}/configurations`,
    params,
    dispatch,
    onSuccess,
    onError
  );
};

export const deleteScheduledConfiguration = (
  stateConfigId: string,
  homeId: string,
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => boolean
): void => {
  brilliantRequestDelete(
    `/homes/${homeId}/configurations/${stateConfigId}`,
    dispatch,
    onSuccess,
    onError
  );
};

export const deleteCurrentTenant = (
  userId: string,
  homeId: string,
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => boolean
): void => {
  brilliantRequestDelete(
    `/homes/${homeId}/users/${userId}`,
    dispatch,
    onSuccess,
    onError
  );
};
