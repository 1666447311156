import {
  ENTRATA_EXCHANGE_ERROR,
  ENTRATA_EXCHANGE_UNAUTHORIZED,
  ENTRATA_EXCHANGE_UPSTREAM_ERROR,
} from "../../actions/constants";
import {
  ENTRATA_EXCHANGE_START,
  ENTRATA_EXCHANGE_SUCCESS,
} from "../../actions/types";
import {
  EEntrataError,
  IActionTypeOnly,
  IEntrataData,
} from "../../interfaces/interfaces";

export default (
  state = {
    error: EEntrataError.noError,
    isLoading: false,
  },
  action: IActionTypeOnly
): IEntrataData => {
  switch (action.type) {
    case ENTRATA_EXCHANGE_START:
      return {
        error: EEntrataError.noError,
        isLoading: true,
      };
    case ENTRATA_EXCHANGE_SUCCESS:
      return {
        error: EEntrataError.noError,
        isLoading: false,
      };
    case ENTRATA_EXCHANGE_UNAUTHORIZED:
      return {
        error: EEntrataError.notAuthorized,
        isLoading: false,
      };
    case ENTRATA_EXCHANGE_UPSTREAM_ERROR:
      return {
        error: EEntrataError.upstreamError,
        isLoading: false,
      };
    case ENTRATA_EXCHANGE_ERROR:
      return {
        error: EEntrataError.unknownError,
        isLoading: false,
      };
    default:
      return state;
  }
};
