import { useEffect } from "react";

import { ReactComponent as User } from "../../img/user.svg";
import { smallHeaderTitle } from "../apollo/LocalState";

import UserInfoTable from "./UserInfoTable";

const AccountDetails: React.FC = () => {
  useEffect(() => {
    smallHeaderTitle("Account Details");
    return () => {
      smallHeaderTitle("");
    };
  }, []);

  return (
    <div className="page-container center-align">
      <div className="page-container-inner padding-bottom-large">
        <div className="header unit-title">
          <User title="Settings" className="header-icon" />
          Account Details
        </div>
        <div className="margin-top-small" />
        <UserInfoTable />
      </div>
    </div>
  );
};

export default AccountDetails;
