import { connect, useDispatch } from "react-redux";

import { ReactComponent as Close } from "../../img/Close.svg";
import { IAppState } from "../../interfaces/interfaces";
import { ReactComponent as Sliders } from "../../img/sliders.svg";
import { updateSelectedConfiguration } from "../../actions";

export interface IConfigurationsHeader {
  popup: boolean;
  title: string;
  showViewConfiguration?: boolean;
  createdDate?: string;
  description?: string;
}

const ConfigurationsHeader: React.FC<IConfigurationsHeader> = ({
  popup,
  title,
  createdDate,
  description,
  showViewConfiguration,
}) => {
  const dispatch = useDispatch();
  return (
    <div
      className={`configuration-header-container ${
        popup ? "configuration-header-container-popup" : ""
      }`}
    >
      <div className="configuration-header-main">
        <div className="center-align-as-row">
          <Sliders title="Sliders" className="configuration-header-img" />
          <div>
            <div className="center-align-as-row">
              <div className="unit-title">{title}</div>
              {!popup && (
                <div className="configuration-header-title-sign unit-tag">
                  Read Only
                </div>
              )}
            </div>
            <div className="unit-description">
              {!popup ? (
                <div>{description}</div>
              ) : (
                <div>Created {createdDate}</div>
              )}
            </div>
          </div>
        </div>
        {showViewConfiguration && (
          <Close
            onClick={() => {
              dispatch(updateSelectedConfiguration("", "", "", false, "", ""));
            }}
            className="changes-header-img"
            title="close"
          />
        )}
      </div>
    </div>
  );
};

ConfigurationsHeader.defaultProps = {
  createdDate: "",
  description: "",
  showViewConfiguration: false,
};

const mapStateToProps = (state: IAppState) => {
  return {
    showViewConfiguration: state.selectedScheduledConfiguration.showModal,
  };
};

export default connect(mapStateToProps)(ConfigurationsHeader);
