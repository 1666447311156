import { Dispatch } from "redux";
import { AxiosError, AxiosResponse } from "axios";

import { brilliantRequest, brilliantRequestDelete, brilliantUrl } from "./api";

export const deletePortalUserInvitation = (
  propertyId: string,
  emailAddress: string | null,
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => boolean
): Promise<void> =>
  brilliantRequestDelete(
    `/organizations/${propertyId}/invitations/${emailAddress}`,
    dispatch,
    onSuccess,
    onError
  );

export const deleteTenantInvitation = (
  propertyId: string,
  emailAddress: string,
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => boolean
): Promise<void> =>
  brilliantRequestDelete(
    `/organizations/homes/${propertyId}/invitations/${emailAddress}`,
    dispatch,
    onSuccess,
    onError
  );

export const deleteAllTenantInvitations = (
  propertyId: string,
  emailAddresses: string[],
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => boolean
): Promise<void> =>
  brilliantRequestDelete(
    `/organizations/homes/${propertyId}/invitations`,
    dispatch,
    onSuccess,
    onError,
    { email_addresses: emailAddresses }
  );

export const deleteUserFromOrganization = (
  organizationId: string,
  userId: string,
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => boolean
): void => {
  brilliantRequestDelete(
    `/organizations/${organizationId}/users/${userId}`,
    dispatch,
    onSuccess,
    onError
  );
};

export const postAddTenantToHome = (
  propertyId: string,
  userId: string,
  token: string
): Promise<
  // Disable camelcase here since these are the raw values we expect from the API.
  /* eslint-disable camelcase */
  AxiosResponse<{
    home_property_name: string;
    building_name: string;
    config_home_name: string;
  }>
> =>
  brilliantUrl.post(`/organizations/homes/${propertyId}/users`, {
    token,
    user_id: userId,
  });

export const postAddUserToOrganization = (
  organizationId: string,
  userId: string,
  token: string
): Promise<AxiosResponse<{ token: string }>> =>
  brilliantUrl.post(`/organizations/${organizationId}/users`, {
    token,
    user_id: userId,
  });

export const postInviteManager = (
  emailAddress: string,
  organizationId: string,
  token: string
): Promise<AxiosResponse> => {
  const data = { email_address: emailAddress };
  const headers = { headers: { Authorization: `Bearer ${token}` } };

  return brilliantUrl.post(
    `/organizations/${organizationId}/invitations`,
    data,
    headers
  );
};

export const postResetHome = (
  propertyId: string,
  dispatch: Dispatch,
  onSuccess: (response: AxiosResponse) => void,
  onError: (error: AxiosError) => boolean
): Promise<void> =>
  brilliantRequest(
    `/organizations/homes/${propertyId}/reset-home`,
    {},
    dispatch,
    onSuccess,
    onError
  );

export const postInviteTenant = (
  email: string,
  propertyId: string,
  token: string
): Promise<AxiosResponse> => {
  const params = { email_address: email };
  const headers = { headers: { Authorization: `Bearer ${token}` } };
  return brilliantUrl.post(
    `/organizations/homes/${propertyId}/invitations`,
    params,
    headers
  );
};

export const postResendInviteTenant = (
  email: string,
  propertyId: string,
  token: string
): Promise<AxiosResponse> => {
  const params = { email_address: email };
  const headers = { headers: { Authorization: `Bearer ${token}` } };
  return brilliantUrl.post(
    `/organizations/homes/${propertyId}/invitations/resend`,
    params,
    headers
  );
};
