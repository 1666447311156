import { IPropertyData } from "../interfaces/interfaces";
import history from "../history";

const BuildingDropdown: React.FC<{
  sortedBuildings: IPropertyData[];
  match: { params: { buildingId: string }; path: string };
  setSelectedBuildingId: React.Dispatch<React.SetStateAction<string>>;
  disabled?: boolean;
  selectedBuildingId: string;
}> = ({
  sortedBuildings,
  match,
  setSelectedBuildingId,
  selectedBuildingId,
  disabled,
}) => {
  const renderSelectOptions = (building: IPropertyData) => (
    <option
      className="scheduler-select-option menu"
      key={building.id}
      value={building.id}
    >
      {building.propertyName}
    </option>
  );
  return (
    <>
      <div className="building-select-container network-type-select-container">
        <select
          onChange={(e) => {
            setSelectedBuildingId(e.target.value);
            match.params.buildingId = e.target.value;
            history.push(`/${match.path.split("/")[1]}/${e.target.value}`);
          }}
          disabled={disabled}
          className="building-select menu tool-tip"
          value={selectedBuildingId}
        >
          {sortedBuildings.map(renderSelectOptions)}
        </select>
      </div>
    </>
  );
};

export default BuildingDropdown;
