import { ReactComponent as EntrataIntegrationIcon } from "../../img/EntrataIntegration.svg";
import { LEARN_MORE_ENTRATA } from "../../utils/LearnMoreLinks";

export interface IEntrataBanner {
  text: JSX.Element;
  linkTo?: string;
}

const EntrataBanner: React.FC<IEntrataBanner> = ({ text, linkTo }) => {
  return (
    <div className="entrata-banner-container flex-with-space-between">
      <div className="center-align-as-row entrata-banner-container-inner ">
        <EntrataIntegrationIcon
          data-testid="entrata-banner-icon"
          className="entrata-banner-icon"
        />
        <p className="entrata-banner-text">{text}</p>
      </div>
      <a
        className="secondary-btn entrata-banner-btn"
        href={linkTo || LEARN_MORE_ENTRATA}
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </a>
    </div>
  );
};

export default EntrataBanner;
