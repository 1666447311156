import { Dispatch } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { createNewUser, passwordMismatchError } from "../../../actions";
import {
  IAppState,
  IRegistrationTenantAccountFormData,
} from "../../../interfaces/interfaces";
import CompleteUserAccountFormWithoutPhoneNumber, {
  ICompleteAccountFormCustomProps,
} from "../common/CompleteUserAccountFormWithoutPhoneNumber";
import { completeAccountSelectedDateVar } from "../../apollo/LocalState";

const onSubmit = (
  values: IRegistrationTenantAccountFormData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
) => {
  if (values.newPassword === values.confirmPassword) {
    values.birthdate = completeAccountSelectedDateVar();
    dispatch(createNewUser({ values }));
  } else {
    dispatch(passwordMismatchError);
  }
};

const ReduxForm = reduxForm<
  IRegistrationTenantAccountFormData,
  ICompleteAccountFormCustomProps
>({
  enableReinitialize: true,
  form: "completeTenantAccount",
  onSubmit,
})(CompleteUserAccountFormWithoutPhoneNumber);

const mapStateToProps = (state: IAppState) => ({
  birthdate: state.newUserRegistration.birthdate,
  initialValues: {
    birthdate: state.newUserRegistration.birthdate,
    emailAddress: state.newUserRegistration.email,
    familyName: state.newUserRegistration.familyName,
    givenName: state.newUserRegistration.givenName,
    userId: state.newUserRegistration.userId,
  },
  registrationError: state.newUserRegistration.error,
});

export default connect(mapStateToProps)(ReduxForm);
