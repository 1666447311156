import { ReactComponent as Reconfiguration } from "../../img/Reconfiguration.svg";
import BaseModal from "../modals/BaseModal";

export interface IReconfigurationModalProps {
  children?: React.ReactNode;
  headerText: string;
  closeReconfigurationModal: () => void;
}

const ReconfigurationModal: React.FC<IReconfigurationModalProps> = ({
  children,
  headerText,
  closeReconfigurationModal,
}) => {
  return (
    <BaseModal
      actionButtonText="Learn More"
      headerText={headerText}
      closeModalFn={closeReconfigurationModal}
      actionButtonFn={() =>
        window.open(
          "https://support.brilliant.tech/hc/en-us/articles/15807618915099"
        )
      }
    >
      <Reconfiguration title="reconfiguration" />
      <div
        className="modal-body-text-container"
        data-testid="reconfiguration-modal-body"
      >
        {children}
      </div>
    </BaseModal>
  );
};

export default ReconfigurationModal;
