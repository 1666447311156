import {
  IActionTypeWithStringPayload,
  ISchedulerDatetime,
} from "../../interfaces/interfaces";
import { DATE_CHANGE, TIME_CHANGE } from "../../actions/constants";

export default (
  state = { date: "", time: "" },
  action: IActionTypeWithStringPayload
): ISchedulerDatetime => {
  switch (action.type) {
    case DATE_CHANGE:
      return { ...state, date: action.payload };
    case TIME_CHANGE:
      return { ...state, time: action.payload };
    default:
      return state;
  }
};
