import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useQuery } from "@apollo/client";
import {
  Field,
  InjectedFormProps,
  formValueSelector,
  reduxForm,
} from "redux-form";
import { Dispatch } from "redux";
import { connect, useDispatch } from "react-redux";

import {
  IAppState,
  IConfiguration,
  IConfigurationData,
  IScheduleForm,
} from "../../interfaces/interfaces";
import Spinner from "../Spinner";
import {
  applyConfiguration,
  scheduleConfiguration,
  updateDate,
  updateTime,
} from "../../actions";
import { required } from "../../formValidation/FormValidation";
import { GET_CONFIGURATIONS } from "../../api/gqlQueries";

const NOW = "Now";
export const ON_A_SPECIFIC_DATE = "On this day";
let gCloseMakeChangesModal: () => void;
let gHomeId: string;
let gRefetch: () => void;

const DropDownSelect: React.FC<{
  input: { value: string };
  options: IConfigurationData[];
  title: string;
}> = ({ input, options, title }) => {
  const renderSelectOptions = (option: IConfigurationData) => (
    <option
      className="scheduler-select-option menu"
      key={option.id}
      value={option.id}
    >
      {option.title}
    </option>
  );
  const selectClassName = `scheduler-select menu ${
    input.value !== "" ? "scheduler-select-selected" : ""
  }`;
  return (
    <div className="scheduler-select-container">
      <select className={selectClassName} {...input}>
        <option className="scheduler-select-option" hidden value="">
          {title}
        </option>
        {options.map(renderSelectOptions)}
      </select>
    </div>
  );
};

interface IScheduler {
  whenSelectValue: string;
  homeId: string;
  setDisabled: (disable: boolean) => void;
  closeMakeChangesModal: () => void;
  refetch: () => void;
}

const Scheduler: React.FC<
  IScheduler & InjectedFormProps<IScheduleForm, IScheduler>
> = ({
  whenSelectValue,
  homeId,
  handleSubmit,
  submitting,
  pristine,
  invalid,
  setDisabled,
  closeMakeChangesModal,
  refetch,
}) => {
  gHomeId = homeId;
  gCloseMakeChangesModal = closeMakeChangesModal;
  gRefetch = refetch;
  const [selectedDate, setDate] = useState<Date | null>(null);
  const [selectedTime, setTime] = useState<Date | null>(null);
  const dispatch = useDispatch();
  let disable = submitting || pristine || invalid;
  if (whenSelectValue === ON_A_SPECIFIC_DATE) {
    disable = !(!invalid && selectedDate !== null && selectedTime !== null);
  }
  useEffect(() => {
    setDisabled(disable);
  }, [disable, whenSelectValue, setDisabled]);
  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 1);
  const { loading, data } = useQuery<IConfiguration>(GET_CONFIGURATIONS);
  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    throw Error("No data was returned");
  }
  return (
    <div className="scheduler-container">
      <div className="align-as-column">
        <div className="scheduler-title menu">Execute the configuration...</div>
        <form className="center-align-as-row" onSubmit={handleSubmit}>
          <Field
            options={data.configurations}
            name="what"
            title="What"
            validate={required}
            component={DropDownSelect}
          />
          <Field
            name="when"
            title="When"
            validate={required}
            options={[
              { id: NOW, title: NOW },
              { id: ON_A_SPECIFIC_DATE, title: ON_A_SPECIFIC_DATE },
            ]}
            component={DropDownSelect}
          />
          {whenSelectValue === ON_A_SPECIFIC_DATE && (
            <div className="scheduler-date menu">
              <DatePicker
                selected={selectedDate}
                onChange={(date: Date) => {
                  setDate(date);
                  dispatch(
                    updateDate(
                      `${
                        date.getMonth() + 1
                      }/${date.getDate()}/${date.getFullYear()}`
                    )
                  );
                }}
                minDate={minDate}
                placeholderText="Pick date"
                dateFormat="MMMM d, yyyy"
                className="scheduler-select-container scheduler-select-font menu margin-right-very-small"
              />
              at
              <DatePicker
                selected={selectedTime}
                onChange={(date: Date) => {
                  setTime(date);
                  dispatch(
                    updateTime(
                      `${date.getHours() % 12}:${date.getMinutes()} ${
                        date.getHours() >= 12 ? "PM" : "AM"
                      }`
                    )
                  );
                }}
                showTimeSelect
                placeholderText="Select Time"
                showTimeSelectOnly
                timeIntervals={10}
                dateFormat="h:mm aa"
                className="scheduler-select-container scheduler-select-font scheduler-select-time menu"
              />
            </div>
          )}
        </form>
      </div>
      {whenSelectValue === ON_A_SPECIFIC_DATE && (
        <div className="scheduler-select-description unit-description">
          All configurations will be applied in accordance to the unit’s
          timezone.
        </div>
      )}
    </div>
  );
};
const onSubmit = (
  values: IScheduleForm,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  // Redux-Form expects Error type and not compatible with passing a redux state.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: any
) => {
  const { date, time } = state.schedulerDatetime;
  if (values.when === NOW) {
    dispatch(applyConfiguration(gHomeId, values.what, gRefetch));
  } else {
    dispatch(scheduleConfiguration(gHomeId, values.what, date, time, gRefetch));
  }
  gCloseMakeChangesModal();
};
const selector = formValueSelector("selectTime");

const WrappedForm = reduxForm<IScheduleForm, IScheduler>({
  form: "selectTime",
  onSubmit,
})(Scheduler);

const SelectingFormValuesForm = connect((state: IAppState) => {
  const whenSelectValue = selector(state, "when");
  return {
    schedulerDatetime: state.schedulerDatetime,
    whenSelectValue,
  };
})(WrappedForm);

export default SelectingFormValuesForm;
