import { ELoginError, IRegistrationStep } from "../../../interfaces/interfaces";
import BrilliantWhite from "../../../img/logos/Brilliant_white.svg";
import { errorMessage } from "../../../formValidation/FormValidation";

const renderErrorMessage = (error: ELoginError) => {
  return (
    <>
      {error !== ELoginError.noError && (
        <p className="right-error">{errorMessage[ELoginError[error]]}</p>
      )}
    </>
  );
};

const SplitScreenLayout = ({
  leftTitle,
  leftDescription,
  rightMessage,
  form,
  messageAfterForm,
  error,
  reconfigurationError,
  isUser = false,
}: IRegistrationStep): JSX.Element => (
  <div
    className={`background-container background-container-vertical_split ${
      isUser && "background-container-user"
    }`}
  >
    <div className="split-screen-layout-container">
      <div className="left">
        <img className="left-logo" src={BrilliantWhite} alt="logo" />
        <div className="left-container">
          <h3 className="left-title">{leftTitle}</h3>
          <div className="left-description">{leftDescription}</div>
        </div>
      </div>
      <div className="right">
        {rightMessage && <div className="right-message">{rightMessage}</div>}
        {form}
        {messageAfterForm}
        {error ? renderErrorMessage(error) : <></>}
        {reconfigurationError && (
          <div className="right-form padding-bottom-small">
            <p className="right-error">{reconfigurationError}</p>
          </div>
        )}
      </div>
    </div>
  </div>
);

export default SplitScreenLayout;
