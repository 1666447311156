import { useQuery } from "@apollo/client";
import { connect } from "react-redux";
import moment from "moment";

import {
  IAppState,
  ILoginData,
  IUserDataWithAccountInfo,
  IUserParams,
} from "../../interfaces/interfaces";
import Spinner from "../Spinner";
import { GET_USER_ACCOUNT_INFO } from "../../api/gqlQueries";

function formatPhoneNumber(rawNumber: string) {
  if (rawNumber.length === 12 && rawNumber.startsWith("+1")) {
    const numberToFormat = rawNumber.substring(2);
    return numberToFormat.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  return rawNumber;
}

const UserInfoTableCell: React.FC<{
  fieldName: string;
  fieldValue: string;
}> = ({ fieldName, fieldValue }) => {
  return (
    <div className="bcc-table-cell">
      <div className="bcc-table-container-inner">
        <div className="bcc-table-row-header">{fieldName}</div>
        <div className="bcc-table-row-body bcc-table-bottom-border placeholder-inactive">
          {fieldValue}
        </div>
      </div>
    </div>
  );
};

const UserInfoTable: React.FC<{
  user: ILoginData;
}> = ({ user }) => {
  const { loading, data } = useQuery<IUserDataWithAccountInfo, IUserParams>(
    GET_USER_ACCOUNT_INFO,
    {
      variables: { userId: user.userId },
    }
  );
  if (loading) return <Spinner />;
  if (!data) throw Error("No data was returned");

  return (
    <div className="bcc-table-container-outer">
      <div className="bcc-table-row">
        <UserInfoTableCell
          fieldName="First Name"
          fieldValue={data.user.givenName}
        />
        <UserInfoTableCell
          fieldName="Last Name"
          fieldValue={data.user.familyName}
        />
        <UserInfoTableCell
          fieldName="Mobile Number"
          fieldValue={formatPhoneNumber(data.user.mfaPhoneNumber)}
        />
        <UserInfoTableCell fieldName="Email" fieldValue={user.email} />
        <UserInfoTableCell
          fieldName="Birthdate"
          fieldValue={moment(data.user.birthdate).format("L")}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(UserInfoTable);
