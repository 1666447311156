import { Dispatch } from "redux";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { StatusCodes } from "http-status-codes";

import { GET_HOME, GET_RESIDENT_INFO } from "../../api/gqlQueries";
import Client from "../../components/apollo/Client";
import { deleteCurrentTenant } from "../../api/homes";
import { getToken } from "../../utils/auth_token";
import { LOADER_START, LOADER_STOP } from "../types";
import {
  deleteAllTenantInvitations,
  deleteTenantInvitation,
  postInviteTenant,
  postResendInviteTenant,
  postResetHome,
} from "../../api/organizations";
import EWebAPIError from "../../api/errors";
import {
  isResendInvitationErrorVar,
  reconfigurationErrorVar,
  userManagementGenericErrorVar,
} from "../../components/apollo/LocalState";
import {
  EReconfigurationError,
  EUserManagementGenericError,
} from "../../interfaces/interfaces";

export const inviteTenant =
  ({
    email,
    increaseCurrentStep,
    propertyId,
  }: {
    email: string;
    increaseCurrentStep: () => void;
    propertyId: string;
  }) =>
  async (): Promise<void> => {
    try {
      await postInviteTenant(email, propertyId, getToken() || "");
      toast("Invite Sent");
      await Client.refetchQueries({
        include: [GET_HOME, GET_RESIDENT_INFO],
      });
      increaseCurrentStep();
    } catch (error) {
      /* eslint-disable camelcase */
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      if (
        response &&
        response.status === StatusCodes.SERVICE_UNAVAILABLE &&
        response.data.error_id ===
          EWebAPIError.PROPERTY_RECONFIGURATION_IN_PROGRESS
      ) {
        reconfigurationErrorVar(
          EReconfigurationError.reconfigurationInProgress
        );
      } else if (
        response &&
        response.status === StatusCodes.SERVICE_UNAVAILABLE &&
        response.data.error_id === EWebAPIError.PROPERTY_RECONFIGURATION_FAILED
      ) {
        reconfigurationErrorVar(EReconfigurationError.reconfigurationFailed);
      } else if (
        response &&
        response.status === StatusCodes.BAD_REQUEST &&
        response.data.error_id === EWebAPIError.INVALID_EMAIL
      ) {
        userManagementGenericErrorVar(
          EUserManagementGenericError.invalidDomain
        );
      } else {
        userManagementGenericErrorVar(
          EUserManagementGenericError.somethingWentWrong
        );
        Sentry.captureMessage(
          `Route "homes_invitations" returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
      }
    }
  };

export const resendInviteTenant =
  ({
    email,
    onSuccessAction,
    propertyId,
  }: {
    email: string;
    onSuccessAction: () => void;
    propertyId: string;
  }) =>
  async (): Promise<void> => {
    try {
      await postResendInviteTenant(email, propertyId, getToken() || "").then(
        (response) => {
          if (response.status === StatusCodes.OK) {
            onSuccessAction();
            toast("Invite Sent");
          }
        }
      );
      await Client.refetchQueries({
        include: [GET_RESIDENT_INFO],
      });
    } catch (error) {
      /* eslint-disable camelcase */
      const { response, message } = error as AxiosError<{
        error_id: EWebAPIError;
      }>;
      isResendInvitationErrorVar(true);
      Sentry.captureMessage(
        `Route "homes_resend_invitations" returned unexpected error: ${JSON.stringify(
          (response && response.data) || message
        )}`
      );
    }
  };

export const removeResident =
  ({
    userId,
    homeId,
    closeRemoveTenantModal,
  }: {
    userId: string;
    homeId: string;
    closeRemoveTenantModal: () => void;
  }) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: LOADER_START,
    });
    await deleteCurrentTenant(
      userId,
      homeId,
      dispatch,
      async () => {
        await Client.refetchQueries({
          include: [GET_HOME, GET_RESIDENT_INFO],
        });
        closeRemoveTenantModal();
        toast("Resident Removed");
        dispatch({
          type: LOADER_STOP,
        });
      },
      (error) => {
        /* eslint-disable camelcase */
        const { response, message } = error as AxiosError<{
          error_id: EWebAPIError;
        }>;
        if (
          response &&
          response.status === StatusCodes.SERVICE_UNAVAILABLE &&
          response.data.error_id ===
            EWebAPIError.PROPERTY_RECONFIGURATION_IN_PROGRESS
        ) {
          reconfigurationErrorVar(
            EReconfigurationError.reconfigurationInProgress
          );
        } else if (
          response &&
          response.status === StatusCodes.SERVICE_UNAVAILABLE &&
          response.data.error_id ===
            EWebAPIError.PROPERTY_RECONFIGURATION_FAILED
        ) {
          reconfigurationErrorVar(EReconfigurationError.reconfigurationFailed);
        } else {
          userManagementGenericErrorVar(
            EUserManagementGenericError.somethingWentWrong
          );
          Sentry.captureMessage(
            `Route "remove_homes_users" returned unexpected error: ${JSON.stringify(
              (response && response.data) || message
            )}`
          );
        }
        dispatch({
          type: LOADER_STOP,
        });
        return true;
      }
    );
  };

export const resetHome =
  ({
    propertyId,
    increaseCurrentStep,
  }: {
    propertyId: string;
    increaseCurrentStep: () => void;
  }) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: LOADER_START,
    });
    await postResetHome(
      propertyId,
      dispatch,
      async () => {
        await Client.refetchQueries({
          include: [GET_HOME, GET_RESIDENT_INFO],
        });
        increaseCurrentStep();
        toast("Move Out Started");
        dispatch({
          type: LOADER_STOP,
        });
      },
      (error) => {
        /* eslint-disable camelcase */
        const { response, message } = error as AxiosError<{
          error_id: EWebAPIError;
        }>;
        if (
          response &&
          response.status === StatusCodes.SERVICE_UNAVAILABLE &&
          response.data.error_id ===
            EWebAPIError.PROPERTY_RECONFIGURATION_IN_PROGRESS
        ) {
          reconfigurationErrorVar(
            EReconfigurationError.reconfigurationInProgress
          );
        } else if (
          response &&
          response.status === StatusCodes.SERVICE_UNAVAILABLE &&
          response.data.error_id ===
            EWebAPIError.PROPERTY_RECONFIGURATION_FAILED
        ) {
          reconfigurationErrorVar(EReconfigurationError.reconfigurationFailed);
        } else {
          userManagementGenericErrorVar(
            EUserManagementGenericError.somethingWentWrong
          );
          Sentry.captureMessage(
            `Route "homes_users" returned unexpected error: ${JSON.stringify(
              (response && response.data) || message
            )}`
          );
        }
        dispatch({
          type: LOADER_STOP,
        });
        return true;
      }
    );
  };

export const cancelTenantInvite =
  ({
    emailAddress,
    propertyId,
  }: {
    emailAddress: string;
    propertyId: string;
  }) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: LOADER_START,
    });
    await deleteTenantInvitation(
      propertyId,
      emailAddress,
      dispatch,
      async () => {
        dispatch({
          type: LOADER_STOP,
        });
        toast("Invite Canceled");
        await Client.refetchQueries({
          include: [GET_HOME, GET_RESIDENT_INFO],
        });
      },
      (error) => {
        dispatch({
          type: LOADER_STOP,
        });
        const { response, message } = error as AxiosError<{
          error_id: EWebAPIError;
        }>;
        userManagementGenericErrorVar(
          EUserManagementGenericError.somethingWentWrong
        );
        Sentry.captureMessage(
          `Route "homes_cancel_invitations" returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
        return true;
      }
    );
  };

export const cancelMoveIn =
  ({
    propertyId,
    emailAddresses,
  }: {
    propertyId: string;
    emailAddresses: string[];
  }) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch({
      type: LOADER_START,
    });
    await deleteAllTenantInvitations(
      propertyId,
      emailAddresses,
      dispatch,
      async () => {
        dispatch({
          type: LOADER_STOP,
        });
        toast("Move In Canceled");
        await Client.refetchQueries({
          include: [GET_HOME, GET_RESIDENT_INFO],
        });
      },
      (error) => {
        dispatch({
          type: LOADER_STOP,
        });
        const { response, message } = error as AxiosError<{
          error_id: EWebAPIError;
        }>;
        userManagementGenericErrorVar(
          EUserManagementGenericError.somethingWentWrong
        );
        Sentry.captureMessage(
          `Route "cancel_move_in" returned unexpected error: ${JSON.stringify(
            (response && response.data) || message
          )}`
        );
        return true;
      }
    );
  };
