import { useQuery } from "@apollo/client";
import { connect } from "react-redux";

import {
  IAppState,
  ILoginData,
  IOrganizationParams,
} from "../../interfaces/interfaces";
import { GET_ORGANIZATION } from "../../api/gqlQueries";
import { ReactComponent as CloseIcon } from "../../img/Close.svg";
import { ReactComponent as Envelope } from "../../img/Envelope.svg";

import InviteManagerForm from "./InviteManagerForm";

interface IOrganizationData {
  organization: {
    name: string;
  };
}

const InviteManagerModal: React.FC<{
  closeModalFn: () => void;
  refetch: () => void;
  user: ILoginData;
}> = ({ closeModalFn, user, refetch }) => {
  const { loading, data } = useQuery<IOrganizationData, IOrganizationParams>(
    GET_ORGANIZATION,
    { variables: { organizationId: user.organizationId } }
  );
  if (loading) return null;
  if (!data) throw Error("No data was returned");

  return (
    <div className="popup-background">
      <div className="invite-manager-modal">
        <div className="invite-manager-modal-header">
          <div className="center-align-as-row">
            <Envelope title="Invite Manager" />
            <div className="invite-manager-modal-title">Invite a Manager</div>
          </div>
          <CloseIcon
            onClick={closeModalFn}
            className="changes-header-img"
            title="Close modal"
          />
        </div>
        <div className="invite-manager-modal-body">
          <div className="invite-manager-modal-section">
            <p>
              Please provide an e-mail of the Manager you would like to add to
              the organization <b>{data.organization.name}</b>.
            </p>
            <br />
            <p>
              The new manager will have the same capabilities on the Brilliant
              Command Center as you. The invite will remain active for 24 hours.
            </p>
          </div>
          <div className="invite-manager-modal-heading">Invite Details</div>
          <InviteManagerForm closeModalFn={closeModalFn} refetch={refetch} />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: IAppState) => {
  return { user: state.user };
};
export default connect(mapStateToProps)(InviteManagerModal);
