import { Dispatch } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";

import {
  IAppState,
  IVerificationFormCustomProps,
  IVerificationFormData,
} from "../../interfaces/interfaces";
import { sendVerificationEmail, verifyEmail } from "../../actions";

import VerificationForm from "./common/VerificationForm";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onSubmit = (values: IVerificationFormData, dispatch: Dispatch<any>) => {
  dispatch(verifyEmail(values));
};

const EmailVerificationForm = reduxForm<
  IVerificationFormData,
  IVerificationFormCustomProps
>({
  form: "emailVerification",
  onSubmit,
})(VerificationForm);

// This is being used to pass a custom prop to VerificationForm, as there does
// not seem to be another way to pass a custom prop to Redux Form.
const mapStateToProps = (state: IAppState) => ({
  registrationError: state.newUser.error,
  resendCodeAction: () => sendVerificationEmail({ force: true }),
  submitText: "Validate Code",
});

export default connect(mapStateToProps)(EmailVerificationForm);
