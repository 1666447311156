import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";

import {
  IAppState,
  INotificationSetting,
  IUpdateUserNotificationSettingResponse,
  IUpdateUserPropertyNotificationSettingRequest,
  IUserDataWithAccountInfo,
} from "../../../interfaces/interfaces";
import {
  GET_ALERT_NOTIFICATION_SETTINGS,
  UPDATE_USER_PROPERTY_NOTIFICATION_SETTING,
} from "../../../api/gqlQueries";

export interface IAlertPreferenceProps {
  alertType: string;
  alertName: string;
  alertDescription: string;
  smsPreference?: INotificationSetting;
  emailPreference?: INotificationSetting;
  userId: string;
  propertyId: string;
}

const AlertPreference: React.FC<IAlertPreferenceProps> = ({
  alertType,
  alertName,
  alertDescription,
  smsPreference,
  emailPreference,
  userId,
  propertyId,
}) => {
  const [updateUserNotificationSettings] = useMutation<
    IUpdateUserNotificationSettingResponse,
    IUpdateUserPropertyNotificationSettingRequest
  >(UPDATE_USER_PROPERTY_NOTIFICATION_SETTING);
  const emailPreferenceEnabled = emailPreference?.enabled ?? false;
  const smsPreferenceEnabled = smsPreference?.enabled ?? false;
  const [emailEnabled, setEmailEnabled] = useState<boolean>(
    emailPreferenceEnabled
  );
  useEffect(() => {
    setEmailEnabled(emailPreferenceEnabled);
  }, [emailPreferenceEnabled]);
  const [smsEnabled, setSmsEnabled] = useState<boolean>(smsPreferenceEnabled);
  useEffect(() => {
    setSmsEnabled(smsPreferenceEnabled);
  }, [smsPreferenceEnabled]);

  const updateUserNotificationSettingsMethod = ({
    notificationMethod,
    isEnabled,
  }: {
    notificationMethod: string;
    isEnabled: boolean;
  }) => {
    updateUserNotificationSettings({
      update: (cache, { data }) => {
        if (!data) {
          // No need to update cache if no data returned.
          return;
        }
        const cachedData = cache.readQuery<IUserDataWithAccountInfo>({
          query: GET_ALERT_NOTIFICATION_SETTINGS,
          variables: { propertyId, userId },
        });
        if (!cachedData) {
          return;
        }
        const updatedData = cloneDeep(cachedData);
        updatedData.user.alarmNotificationSettings =
          data.updateUserNotificationSetting.alarmNotificationSettings;
        cache.writeQuery({
          data: updatedData,
          query: GET_ALERT_NOTIFICATION_SETTINGS,
          variables: { propertyId, userId },
        });
      },
      variables: {
        alarmType: alertType,
        enabled: !isEnabled,
        notificationMethod,
        propertyId,
        userId,
      },
    });
  };
  return (
    <div className="grouped-preferences-alert-container">
      <div data-testid="alert-name" className="placeholder">
        {alertName}
      </div>
      <div className="grouped-preferences-description-container">
        <p>{alertDescription}</p>
        <div className="grouped-preferences-checkbox-container">
          <div className="grouped-preferences-checkbox-container-inner">
            <div className="text-label grouped-preferences-checkbox-label">
              Email
            </div>
            <input
              type="checkbox"
              className="grouped-preferences-checkbox"
              checked={emailEnabled}
              onChange={() => {
                setEmailEnabled(!emailEnabled);
                updateUserNotificationSettingsMethod({
                  isEnabled: emailEnabled,
                  notificationMethod: "EMAIL",
                });
              }}
            />
          </div>
          <div className="grouped-preferences-checkbox-container-inner">
            <div className="text-label grouped-preferences-checkbox-label">
              Text
            </div>
            <input
              type="checkbox"
              className="grouped-preferences-checkbox"
              checked={smsEnabled}
              onChange={() => {
                setSmsEnabled(!smsEnabled);
                updateUserNotificationSettingsMethod({
                  isEnabled: smsEnabled,
                  notificationMethod: "SMS",
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    userId: state.user.userId,
  };
};

export default connect(mapStateToProps)(AlertPreference);
