import { useDispatch } from "react-redux";
import { useReactiveVar } from "@apollo/client";

import { cancelTenantInvite } from "../../../actions";
import { userManagementGenericErrorVar } from "../../apollo/LocalState";
import { SelectedTableEntry } from "../../tables/UsersTables";
import { EUserManagementGenericError } from "../../../interfaces/interfaces";
import { userManagementGenericErrorMessages } from "../../../utils/errorMessages";

export interface RemoveInvitedTenantProps {
  closeRemoveTenantModal: () => void;
  decreaseCurrentStep: () => void;
  emailAddress: string;
  propertyId: string;
}

const RemoveInvitedTenant: React.FC<RemoveInvitedTenantProps> = ({
  closeRemoveTenantModal,
  decreaseCurrentStep,
  emailAddress,
  propertyId,
}) => {
  const dispatch = useDispatch();
  const userManagementGenericError = useReactiveVar(
    userManagementGenericErrorVar
  );
  return (
    <div className="tenants-show-body">
      <div
        className="tenants-show-description-container margin-left-right-small margin-top-small"
        data-testid="remove-invited-tenant-container"
      >
        Please confirm that you would like to cancel this resident&#39;s invite
        to join the unit&#39;s Brilliant home.
      </div>
      <div className="tenants-show-description-body">
        <SelectedTableEntry />
      </div>
      {userManagementGenericError !== EUserManagementGenericError.noError && (
        <div
          data-testid="generic-error"
          className="tenants-show-description-error alert"
        >
          {
            userManagementGenericErrorMessages[
              EUserManagementGenericError[userManagementGenericError]
            ]
          }
        </div>
      )}
      <div className="invite-tenant-modal-actions margin-top-small">
        <button
          type="button"
          className="tertiary-btn-small tertiary-btn-small-alert"
          onClick={() => {
            decreaseCurrentStep();
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="tertiary-btn-small tertiary-btn-small-main tertiary-btn-small-main-alert invite-tenant-modal-actions-submit"
          onClick={() => {
            dispatch(
              cancelTenantInvite({
                emailAddress,
                propertyId,
              })
            );
            closeRemoveTenantModal();
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default RemoveInvitedTenant;
