import { useReactiveVar } from "@apollo/client";
import { useEffect } from "react";

import { EReconfigurationError } from "../../../interfaces/interfaces";
import { reconfigurationErrorVar } from "../../apollo/LocalState";
import { reconfigurationErrorMessage } from "../../../utils/errorMessages";

import SplitScreenLayout from "./SplitScreenLayout";

export interface InputMFAPhoneNumberStepProps {
  form: React.ReactNode;
  isUser?: boolean;
}

const InputMFAPhoneNumberStep: React.FC<InputMFAPhoneNumberStepProps> = ({
  form,
  isUser,
}) => {
  const reconfigurationError = useReactiveVar(reconfigurationErrorVar);
  useEffect(() => {
    return () => {
      reconfigurationErrorVar(EReconfigurationError.noError);
    };
  }, []);
  const leftDescription = (
    <>
      For security purposes, 2-factor authentication will be enabled on your
      account to be used on future logins.
      <br />
      <br />
      This mobile phone number may also be used for any alerts or notifications.
    </>
  );
  return (
    <SplitScreenLayout
      form={form}
      leftTitle="Let's enroll your mobile device!"
      leftDescription={leftDescription}
      rightMessage="Please provide the mobile phone number you want to use for 2-factor
      authentication."
      isUser={isUser}
      reconfigurationError={
        reconfigurationErrorMessage[EReconfigurationError[reconfigurationError]]
      }
    />
  );
};

export default InputMFAPhoneNumberStep;
