/*
 * Enums
 */

export enum NotificationMethod {
  Sms = "SMS",
  Email = "EMAIL",
}

export const notificationTitle: { [key: string]: string } = {
  EMAIL: "Email",
  SMS: "SMS",
};
