import { IUnitDetailsDataHome } from "../../interfaces/interfaces";
import { getClimateAlertNames, getWarningNames } from "../../utils/alertUtils";

import UnitCard from "./UnitCard";

const renderFloorName = ({
  floorName,
  moreThanOneFloor,
}: {
  floorName: string;
  moreThanOneFloor: boolean;
}) => {
  if (floorName === "null" && moreThanOneFloor) {
    return <div className="floor-name menu">No floor assigned</div>;
  }
  if (floorName === "null") {
    return <></>;
  }
  return <div className="floor-name menu">Floor {floorName}</div>;
};

const Floor: React.FC<{
  data: IUnitDetailsDataHome[];
  floorName: string;
  moreThanOneFloor: boolean;
}> = ({ data, floorName, moreThanOneFloor }) => {
  return (
    <div className="floor-container">
      {renderFloorName({ floorName, moreThanOneFloor })}
      <div className="floor-units">
        {data.map((element: IUnitDetailsDataHome) => {
          const {
            propertyName,
            id,
            alerts,
            isHubless,
            numBrilliantControls,
            numBrilliantPlugs,
            numBrilliantSwitches,
            numIntegrations,
            numResidents,
            numInvitedResidents,
            reconfigurationState,
            warnings,
          } = element;
          const numBrilliants =
            numBrilliantControls + numBrilliantPlugs + numBrilliantSwitches;
          const alertNames = getClimateAlertNames(alerts);
          const warningNames = getWarningNames(warnings);
          return (
            <UnitCard
              key={propertyName}
              isHubless={isHubless}
              propertyId={id}
              alerts={alertNames}
              name={propertyName}
              numBrilliants={numBrilliants}
              numThirdpartyIntegrations={numIntegrations}
              numTenants={numResidents}
              numInvitedResidents={numInvitedResidents}
              reconfigurationState={reconfigurationState}
              warnings={warningNames}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Floor;
