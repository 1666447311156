import { connect } from "react-redux";

import { IAppState, ILoginData, IMeta } from "../../interfaces/interfaces";

export interface RenderInputFieldProps {
  input: Record<string, unknown>;
  meta: IMeta;
  user: ILoginData;
  placeholder: string;
  type: string;
  autoComplete: string;
  onChange: () => void;
  testId: string;
}

const RenderInputField: React.FC<RenderInputFieldProps> = ({
  input,
  meta,
  user,
  placeholder,
  type,
  autoComplete,
  testId,
}) => {
  return (
    <div>
      <input
        data-testid={testId}
        className={`login-form-input unit-description ${
          user.error ? "login-form-input-error" : ""
        }`}
        {...input}
        type={type}
        autoComplete={autoComplete}
        placeholder={placeholder}
      />
      {meta.touched && !meta.valid && (
        <p className="login-form-input-error-message unit-description-small">
          {meta.error}
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(RenderInputField);
