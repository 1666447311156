import { useState } from "react";
import { useMutation } from "@apollo/client";

import { GET_RESIDENT_INFO, RESET_INSTALLATION } from "../../api/gqlQueries";
import { ReactComponent as CloseIcon } from "../../img/Close.svg";
import { ReactComponent as Settings } from "../../img/settings.svg";
import RedAlertMessage from "../common/RedAlertMessage";
import {
  renderTransitionModalContent,
  renderUnitDetailsModalContent,
} from "../../utils/utils";
import LearnMoreButton from "../buttons/LearnMoreButton";
import { LEARN_MORE_RESET_INSTALLATION } from "../../utils/LearnMoreLinks";

export interface ResetInstallationModalWizardProps {
  closeResetInstallationModalWizardModal: () => void;
  propertyName: string;
  unitName: string;
  propertyId: string;
}

export enum ResetInstallationModalStep {
  RESET_INSTALLATION_STEP = 1,
  RESET_CONFIRMED = 2,
}

const renderHeader = (currentStep: ResetInstallationModalStep) => {
  switch (currentStep) {
    case ResetInstallationModalStep.RESET_INSTALLATION_STEP:
      return <b>Reset Installation</b>;
    case ResetInstallationModalStep.RESET_CONFIRMED:
      return <b>Reset Confirmed</b>;
    default:
      return <></>;
  }
};

const ResetInstallationStep: React.FC<{
  closeResetInstallationModalWizardModal: () => void;
  setCurrentStep: React.Dispatch<
    React.SetStateAction<ResetInstallationModalStep>
  >;
  unitName: string;
  propertyId: string;
}> = ({
  closeResetInstallationModalWizardModal,
  unitName,
  setCurrentStep,
  propertyId,
}) => {
  const [resetInstallation] = useMutation(RESET_INSTALLATION, {
    refetchQueries: [GET_RESIDENT_INFO],
  });
  const [unitInputValue, setUnitInputValue] = useState("");
  return (
    <div className="modal-wizard-body">
      <RedAlertMessage>
        {renderUnitDetailsModalContent({
          centerAlignHeaderForSmallScreen: true,
          descriptionList: [
            "If occupied, all residents will lose all access to the unit's Brilliant home and its devices.",
            "Brilliant will attempt to reset all of the unit's Brilliant devices and remove all settings that were previously applied. If any of the unit's Brilliant devices are offline, you will need to reset them manually.",
            "Before you can start another move in, you will need to set up all of this unit's devices again and mark the unit's installation complete using the Brilliant mobile app.",
          ],
          title:
            "Are you sure that you want to reset this unit's installation?",
        })}
      </RedAlertMessage>
      <form className="modal-wizard-body-form-container">
        <p>
          If you are sure that you want to reset this unit&#39;s installation,
          please type <b>“{unitName}”</b> in the space provided below to
          confirm.
        </p>
        <input
          type="text"
          data-testid="unit-input"
          className="modal-wizard-body-form-input"
          maxLength={50}
          value={unitInputValue}
          onChange={(e) => setUnitInputValue(e.target.value)}
        />
      </form>
      <div className="modal-wizard-body-form-buttons-container">
        <div className="modal-wizard-body-form-buttons-learn-more">
          <LearnMoreButton
            link={LEARN_MORE_RESET_INSTALLATION}
            text="Learn More"
          />
        </div>
        <div className="modal-wizard-body-form-buttons">
          <button
            type="button"
            className="tertiary-btn-small tertiary-btn-small-alert"
            onClick={() => {
              closeResetInstallationModalWizardModal();
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            data-testid="remove-registered-tenant-btn"
            disabled={unitInputValue !== unitName}
            className="tertiary-btn-small tertiary-btn-small-main tertiary-btn-small-main-alert invite-tenant-modal-actions-submit"
            onClick={() => {
              resetInstallation({
                variables: {
                  propertyId,
                },
              });
              setCurrentStep(ResetInstallationModalStep.RESET_CONFIRMED);
            }}
          >
            Confirm Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export const ResetConfirmedView: React.FC = () => {
  return (
    <div className="tenants-show-body">
      <div
        className="invitation-sent-container"
        data-testid="move-out-confirmed-container"
      >
        {renderTransitionModalContent({
          whatJustHappenedList: [
            "If occupied, all residents lost access to the unit's Brilliant home and its devices.",
            "The unit's Brilliant home is being deleted and an empty home is being created in its place.",
            "The unit's devices are being reset and all settings are being removed.",
          ],
          whatsNextList: [
            {
              bulletPoint: (
                <>
                  To set up this unit again, please select the unit while vacant
                  on the Brilliant mobile app and follow the instructions
                  provided under <b>Settings → Install Brilliant Device</b>.
                </>
              ),
            },
            {
              bulletPoint: (
                <>
                  After set up is complete, you will be able to invite residents
                  to access this unit&apos;s Brilliant home again.
                </>
              ),
            },
          ],
        })}
      </div>
      <div className="invitation-sent-learn-more-container">
        <LearnMoreButton link={LEARN_MORE_RESET_INSTALLATION} />
      </div>
    </div>
  );
};

const ResetInstallationModalWizard: React.FC<
  ResetInstallationModalWizardProps
> = ({
  closeResetInstallationModalWizardModal,
  unitName,
  propertyName,
  propertyId,
}) => {
  const [currentStep, setCurrentStep] = useState<ResetInstallationModalStep>(
    ResetInstallationModalStep.RESET_INSTALLATION_STEP
  );
  return (
    <div className="popup-background">
      <div className="tenants-show-container align-as-column">
        <div className="tenants-show-header">
          <div className="center-align-as-row">
            <Settings
              className="tenants-show-header-settings-icon"
              title="Settings"
            />
            <div
              className="tenants-show-header-title menu"
              data-testid="header-title-name"
            >
              {renderHeader(currentStep)}
              <div className="tenants-show-header-title-name">
                : {unitName}, {propertyName}
              </div>
            </div>
          </div>
          <CloseIcon
            onClick={() => {
              closeResetInstallationModalWizardModal();
            }}
            className="changes-header-img"
            title="close"
          />
        </div>
        {currentStep === ResetInstallationModalStep.RESET_INSTALLATION_STEP && (
          <ResetInstallationStep
            setCurrentStep={setCurrentStep}
            closeResetInstallationModalWizardModal={
              closeResetInstallationModalWizardModal
            }
            unitName={unitName}
            propertyId={propertyId}
          />
        )}
        {currentStep === ResetInstallationModalStep.RESET_CONFIRMED && (
          <ResetConfirmedView />
        )}
      </div>
    </div>
  );
};

export default ResetInstallationModalWizard;
