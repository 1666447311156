import { ReactiveVar, gql, makeVar } from "@apollo/client";

import {
  EReconfigurationError,
  EUserManagementGenericError,
  IInvitedUser,
  IRegisteredUser,
  IRemoteLockChangedHome,
} from "../../interfaces/interfaces";

interface IRemoteLockLargeUpdateModalVarArgs {
  isShowing: boolean;
  confirmFn: () => void;
}

export const isSideMenuVisibleVar = makeVar(false);
export const isAlertSideMenuVisibleVar = makeVar(false);

export const GET_SMALL_HEADER = gql`
  query GetSmallHeader {
    smallHeaderTitle @client
    smallHeaderDescription @client
    smallHeaderBackArrow @client
  }
`;
export const smallHeaderTitle = makeVar("");
export const smallHeaderDescription = makeVar("");
export const smallHeaderBackArrow = makeVar(false);

export const reconfigurationErrorVar = makeVar(EReconfigurationError.noError);

// Used to display unhandled error messages
export const userManagementGenericErrorVar = makeVar(
  EUserManagementGenericError.noError
);

/*
  This query is used to check whether we should close RemoveTenantModalWizard (if mobile view)
  or go back to ShowResident step (for desktop and tablet view)
*/
export const GET_REMOTE_LOCK_POLLING_STATE = gql`
  query GetRemoteLockPollingState {
    isRemoteLockPolling @client
  }
`;
export const isRemoteLockPolling = makeVar(false);

/*
  This variable is used to check whether EditAccessDevices Component is opened or not.
*/
export const isEditAccessDevicesInProgressVar = makeVar(false);

export const isRemoteLockTokenInvalidVar = makeVar(false);

export const conflictUnitsVar: ReactiveVar<string[]> = makeVar<string[]>([]);

export const isResendInvitationErrorVar = makeVar(false);

export const REMOTE_LOCK_LARGE_UPDATE_MODAL_NOT_SHOWING_ARGS: IRemoteLockLargeUpdateModalVarArgs =
  {
    confirmFn: () => {
      // do nothing
    },
    isShowing: false,
  };
export const showRemoteLockLargeUpdateModalVar = makeVar(
  REMOTE_LOCK_LARGE_UPDATE_MODAL_NOT_SHOWING_ARGS
);

/*
  This variable tracks the new state of RemoteLocks.
*/
export const currentChangedHomesVar: ReactiveVar<
  Map<string, IRemoteLockChangedHome[]>
> = makeVar(new Map());

export const showRemoteLockUpdateInProgressModalVar = makeVar(false);

export const completeAccountSelectedDateVar = makeVar("");

// Used to select user to show in selectedTableEntry
export const selectedUserToShowVar: ReactiveVar<
  IRegisteredUser | IInvitedUser | null
> = makeVar<IRegisteredUser | IInvitedUser | null>(null);
