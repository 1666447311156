import { ReactComponent as CloseIcon } from "../../../img/Close.svg";
import { ReactComponent as TenantsImg } from "../../../img/Tenants.svg";
import {
  MOVE_IN_STARTED_STEP,
  MOVE_IN_STEP,
  NEW_INVITE_RESIDENT_STEP,
} from "../TenantManagementSteps";
import {
  renderTransitionModalContent,
  renderUnitDetailsModalContent,
} from "../../../utils/utils";

import InviteTenantStepOne from "./InviteTenantStepOne";
import MoveInStartedStep from "./MoveInStartedStep";

export interface InviteTenantModalWizardProps {
  closeInviteTenantModal: () => void;
  propertyId: string;
  propertyName: string;
  unitName: string;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

const renderHeader = (currentStep: number) => {
  if (currentStep === MOVE_IN_STEP) {
    return "Start Move In";
  }
  if (currentStep === MOVE_IN_STARTED_STEP) {
    return "Move In Started";
  }
  return "Add Resident";
};

const InviteTenantModalWizard: React.FC<InviteTenantModalWizardProps> = ({
  closeInviteTenantModal,
  propertyId,
  propertyName,
  unitName,
  currentStep,
  setCurrentStep,
}) => {
  return (
    <div className="popup-background">
      <div className="tenants-show-container align-as-column">
        <div className="tenants-show-header">
          <div className="center-align-as-row">
            <TenantsImg title="Residents" />
            <div className="tenants-show-header-title unit-title">
              <b data-testid="invite-tenant-header">
                {renderHeader(currentStep)}
              </b>
              <div className="tenants-show-header-title-name">
                : {unitName}, {propertyName}
              </div>
            </div>
          </div>
          <CloseIcon
            onClick={() => {
              closeInviteTenantModal();
            }}
            className="changes-header-img"
            title="close"
          />
        </div>
        {currentStep === MOVE_IN_STEP && (
          <InviteTenantStepOne
            closeInviteTenantModal={closeInviteTenantModal}
            propertyId={propertyId}
            increaseCurrentStep={() => setCurrentStep(MOVE_IN_STARTED_STEP)}
          >
            <div className="tenants-show-description-container">
              <>
                To start a move in please provide the e-mail of the first
                resident you would like to invite to <b>{unitName}</b> in{" "}
                <b>{propertyName}</b>.
              </>
              <div className="margin-top-small" />
              {renderUnitDetailsModalContent({
                descriptionList: [
                  "The resident will be invited to access the home and its devices. The invite will remain active for 7 days.",
                ],
                title: "If you invite the first resident:",
              })}
              <br />
              {renderUnitDetailsModalContent({
                descriptionList: [
                  "The unit's state will change from vacant to occupied.",
                  "Any settings that were applied while the unit was vacant will be removed.",
                  "The unit's Brilliant home will be reconfigured with the default settings for occupied units.",
                  "The first resident will gain access to the unit's Brilliant home and its devices.",
                ],
                title: "When the first resident accepts the invite:",
              })}
            </div>
          </InviteTenantStepOne>
        )}
        {currentStep === NEW_INVITE_RESIDENT_STEP && (
          <InviteTenantStepOne
            closeInviteTenantModal={closeInviteTenantModal}
            propertyId={propertyId}
            increaseCurrentStep={closeInviteTenantModal}
          >
            <div className="tenants-show-description-container">
              <>
                Please provide an e-mail of the resident you would like to add
                to <b>{unitName}</b> in <b>{propertyName}</b>.
              </>
              <div className="margin-top-small" />
              {renderUnitDetailsModalContent({
                descriptionList: [
                  "The resident will be invited to access the home and its devices. The invite will remain active for 7 days.",
                ],
                title: "If you invite another resident:",
              })}
              <br />
              {renderUnitDetailsModalContent({
                descriptionList: [
                  "The resident will gain access to the unit's Brilliant home and its devices.",
                ],
                title: "After the resident accepts the invite:",
              })}
            </div>
          </InviteTenantStepOne>
        )}
        {currentStep === MOVE_IN_STARTED_STEP && (
          <MoveInStartedStep>
            <div className="invitation-sent-container">
              {renderTransitionModalContent({
                whatJustHappenedList: [
                  "The first resident was invited to access the home and its devices.",
                  "The invite will remain active for 7 days.",
                ],
                whatsNextList: [
                  {
                    bulletPoint: (
                      <>When the first resident accepts the invite:</>
                    ),
                    subBulletPoints: [
                      "The unit's state will change from vacant to occupied.",
                      "The unit's Brilliant devices will be reconfigured with the default settings for occupied units.",
                      "The first resident will gain access to the unit's Brilliant home and its devices.",
                    ],
                  },
                  {
                    bulletPoint: (
                      <>
                        You are now able to manage this unit&apos;s residents.
                      </>
                    ),
                  },
                ],
              })}
            </div>
          </MoveInStartedStep>
        )}
      </div>
    </div>
  );
};

export default InviteTenantModalWizard;
