import { Link } from "react-router-dom";

import BrilliantLogo from "../../img/logos/Brilliant.svg";
import { ReactComponent as Key } from "../../img/key.svg";

const Success: React.FC = () => {
  return (
    <div className="background-container">
      <div className="login-container">
        <div className="inner-container registration-success">
          <img
            src={BrilliantLogo}
            alt=""
            className="registration-success-brilliant-logo"
          />
          <Key className="registration-success-key-logo" />
          <h3 className="registration-success-heading">Success!</h3>
          <p>
            Your Brilliant account has been successfully created. Please go to
            the Brilliant Command Center to administer your Brilliant Smart
            Buildings.
          </p>
          <Link to="/" className="registration-success-button-link primary-btn">
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Success;
