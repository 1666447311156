import { Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { acceptTermsAndConditions } from "../../actions";
import SubmitButton from "../buttons/SubmitButton";
import { required } from "../../formValidation/FormValidation";

interface ITermsAndConditions {
  termsAndConditionsField: boolean;
}

const TermsAndConditionsForm: React.FC<
  InjectedFormProps<ITermsAndConditions>
> = ({ handleSubmit, invalid, submitting, pristine }) => {
  return (
    <form style={{ width: "70%" }} onSubmit={handleSubmit}>
      <div className="terms-conditions-field-container">
        <Field
          className="terms-conditions-checkbox"
          validate={[required]}
          id="termsAndConditionsField"
          name="termsAndConditionsField"
          component="input"
          type="checkbox"
        />
        <label
          htmlFor="termsAndConditionsField"
          className="terms-conditions-label"
        >
          I agree to the Terms of Use, including Brilliant’s Privacy Policy and
          Terms of Service.
        </label>
      </div>
      <div className="margin-bottom-medium" />
      <SubmitButton
        disabled={invalid || submitting || pristine}
        name="Continue"
        decorator="right-form-btn"
      />
    </form>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onSubmit = (values: ITermsAndConditions, dispatch: Dispatch<any>) => {
  dispatch(acceptTermsAndConditions());
};

const WrappedForm = reduxForm({
  form: "termsAndConditionsVerification",
  onSubmit,
})(TermsAndConditionsForm);

export default WrappedForm;
