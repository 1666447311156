import { useQuery } from "@apollo/client";

import { ReactComponent as Users } from "../../img/Tenants.svg";
import { GET_RESIDENT_INFO } from "../../api/gqlQueries";
import { UsersTables } from "../tables/UsersTables";
import {
  IIntegrationsNameAndLink,
  IInvitedUser,
  IPropertyIdVariable,
  IRegisteredUser,
  IShowResidentsData,
} from "../../interfaces/interfaces";
import Spinner from "../Spinner";
import { renderUnitDetailsMainBtn } from "../../utils/utils";
import ToolTipUpper from "../common/TooltipUpper";
import { NEW_INVITE_RESIDENT_STEP } from "../tenantManagement/TenantManagementSteps";
import { TitleBarButton } from "../common/SectionTitleBar";

export interface IViewResidentsProps {
  propertyId: string;
  hasIntegration: boolean;
  integrationNameLink: IIntegrationsNameAndLink;
  isInReconfigState: boolean;
  openEditResidentModal: () => void;
  setShowMoveOutModal: React.Dispatch<React.SetStateAction<boolean>>;
  setInviteTenantCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setShowInviteTenantModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCancelMoveInModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReconfigurationModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddResidentAction({
  setInviteTenantCurrentStep,
  setShowInviteTenantModal,
}: {
  setInviteTenantCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setShowInviteTenantModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  setShowInviteTenantModal(true);
  setInviteTenantCurrentStep(NEW_INVITE_RESIDENT_STEP);
}

const renderVacantTable = ({
  isOccupiedUnit,
  isInvitedUnit,
  hasIntegration,
  integrationNameLink,
  isInReconfigState,
  setInviteTenantCurrentStep,
  setShowInviteTenantModal,
  setShowCancelMoveInModal,
  setShowReconfigurationModal,
}: {
  isOccupiedUnit: boolean;
  isInvitedUnit: boolean;
  hasIntegration: boolean;
  isInReconfigState: boolean;
  integrationNameLink: IIntegrationsNameAndLink;
  setInviteTenantCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setShowInviteTenantModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCancelMoveInModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReconfigurationModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className="tenants-vacant-container">
      <div className="center-align-as-column">
        <Users title="key" className="tenants-vacant-img" />
        <p>
          <b data-testid="vacant-unit-title">This Unit is Vacant</b>
        </p>
        {hasIntegration ? (
          <>
            <p className="tenants-vacant-text">
              {`You have not added any residents for this unit in ${integrationNameLink.name}.`}
            </p>
            <TitleBarButton
              text={`Open ${integrationNameLink.name}`}
              url={integrationNameLink.landingURL}
              isExternal
            />
          </>
        ) : (
          <>
            <p className="tenants-vacant-text">
              To begin adding residents, start a move in for this unit.
            </p>
            {renderUnitDetailsMainBtn({
              hasIntegration,
              isInReconfigState,
              isInvitedUnit,
              isOccupiedUnit,
              isSmallScreen: false,
              isVacantUnit: true,
              setInviteTenantCurrentStep,
              setShowCancelMoveInModal,
              setShowMoveInModal: setShowInviteTenantModal,
              setShowReconfigurationModal,
            })}
          </>
        )}
      </div>
    </div>
  );
};

const renderMainBtnForTablets = ({
  isOccupiedUnit,
  isInvitedUnit,
  hasIntegration,
  isInReconfigState,
  setShowMoveOutModal,
  setShowCancelMoveInModal,
  setShowReconfigurationModal,
}: {
  isOccupiedUnit: boolean;
  isInvitedUnit: boolean;
  hasIntegration: boolean;
  isInReconfigState: boolean;
  setShowMoveOutModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCancelMoveInModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReconfigurationModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className="tenants-main-btn-tablet">
      {renderUnitDetailsMainBtn({
        hasIntegration,
        isInReconfigState,
        isInvitedUnit,
        isOccupiedUnit,
        isSmallScreen: false,
        isVacantUnit: false,
        setShowCancelMoveInModal,
        setShowMoveOutModal,
        setShowReconfigurationModal,
      })}
    </div>
  );
};

const renderBtnsForMobile = ({
  isOccupiedUnit,
  isInvitedUnit,
  dialogTest,
  disabledAddButton,
  hasIntegration,
  isInReconfigState,
  setShowMoveOutModal,
  setInviteTenantCurrentStep,
  setShowInviteTenantModal,
  setShowCancelMoveInModal,
  setShowReconfigurationModal,
}: {
  isOccupiedUnit: boolean;
  isInvitedUnit: boolean;
  dialogTest: string;
  disabledAddButton: boolean;
  hasIntegration: boolean;
  isInReconfigState: boolean;
  setShowMoveOutModal: React.Dispatch<React.SetStateAction<boolean>>;
  setInviteTenantCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setShowInviteTenantModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCancelMoveInModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReconfigurationModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className={`${hasIntegration && "tenants-add-btn-integrations"}`}>
      <div
        className={`tenants-add-btn-container-small ${
          hasIntegration && "tenants-add-btn-integrations"
        }`}
      >
        <div className="tooltip tenants-add-btn-container-full-width">
          <button
            disabled={disabledAddButton}
            type="button"
            className="primary-btn tenants-add-btn-primary"
            onClick={() =>
              AddResidentAction({
                setInviteTenantCurrentStep,
                setShowInviteTenantModal,
              })
            }
          >
            Add Resident
          </button>
          {disabledAddButton && <ToolTipUpper text={dialogTest} />}
        </div>
        {renderUnitDetailsMainBtn({
          hasIntegration,
          isInReconfigState,
          isInvitedUnit,
          isOccupiedUnit,
          isSmallScreen: true,
          isVacantUnit: false,
          setShowCancelMoveInModal,
          setShowMoveOutModal,
          setShowReconfigurationModal,
        })}
      </div>
    </div>
  );
};

const renderUsersTable = ({
  isOccupiedUnit,
  isInvitedUnit,
  isInReconfigState,
  residents,
  invitedResidents,
  hasIntegration,
  openEditResidentModal,
  setShowMoveOutModal,
  setInviteTenantCurrentStep,
  setShowInviteTenantModal,
  setShowCancelMoveInModal,
  setShowReconfigurationModal,
}: {
  isOccupiedUnit: boolean;
  isInvitedUnit: boolean;
  isInReconfigState: boolean;
  residents: IRegisteredUser[];
  invitedResidents: IInvitedUser[];
  hasIntegration: boolean;
  openEditResidentModal: () => void;
  setShowMoveOutModal: React.Dispatch<React.SetStateAction<boolean>>;
  setInviteTenantCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setShowInviteTenantModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCancelMoveInModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReconfigurationModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const totalNumOfTenants = residents.length + invitedResidents.length;
  const disabledAddButton = totalNumOfTenants >= 25;
  const dialogTest =
    "You have already invited the maximum number of residents to this unit (25).";
  return (
    <>
      <UsersTables
        invitedPortalUsers={invitedResidents}
        portalUsers={residents}
        shouldEmptyTableRender={false}
        openRemoveFlowModal={() => undefined}
        shouldTrashRender={false}
        shouldGearRender
        openEditResidentModal={openEditResidentModal}
      >
        <div
          className={`tenants-add-btn-container ${
            hasIntegration && "tenants-add-btn-integrations"
          }`}
        >
          <div className="tooltip">
            <button
              data-testid="table-add-resident-btn"
              disabled={disabledAddButton}
              className="tertiary-btn-small"
              type="button"
              onClick={() =>
                AddResidentAction({
                  setInviteTenantCurrentStep,
                  setShowInviteTenantModal,
                })
              }
            >
              + Add Resident
            </button>
            {disabledAddButton && <ToolTipUpper text={dialogTest} />}
          </div>
        </div>
      </UsersTables>
      {renderMainBtnForTablets({
        hasIntegration,
        isInReconfigState,
        isInvitedUnit,
        isOccupiedUnit,
        setShowCancelMoveInModal,
        setShowMoveOutModal,
        setShowReconfigurationModal,
      })}
      {renderBtnsForMobile({
        dialogTest,
        disabledAddButton,
        hasIntegration,
        isInReconfigState,
        isInvitedUnit,
        isOccupiedUnit,
        setInviteTenantCurrentStep,
        setShowCancelMoveInModal,
        setShowInviteTenantModal,
        setShowMoveOutModal,
        setShowReconfigurationModal,
      })}
    </>
  );
};

const ViewResidents: React.FC<IViewResidentsProps> = ({
  propertyId,
  hasIntegration,
  isInReconfigState,
  integrationNameLink,
  openEditResidentModal,
  setShowMoveOutModal,
  setInviteTenantCurrentStep,
  setShowInviteTenantModal,
  setShowCancelMoveInModal,
  setShowReconfigurationModal,
}) => {
  const { loading, data } = useQuery<IShowResidentsData, IPropertyIdVariable>(
    GET_RESIDENT_INFO,
    {
      variables: { propertyId },
    }
  );
  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    throw Error("No data was returned");
  }
  const { home } = data;
  const isOccupiedUnit = home.residents.length > 0;
  const isInvitedUnit = home.invitedResidents.length > 0;
  const shouldEmptyTableRender = !isOccupiedUnit && !isInvitedUnit;
  return (
    <>
      {shouldEmptyTableRender
        ? renderVacantTable({
            hasIntegration,
            integrationNameLink,
            isInReconfigState,
            isInvitedUnit,
            isOccupiedUnit,
            setInviteTenantCurrentStep,
            setShowCancelMoveInModal,
            setShowInviteTenantModal,
            setShowReconfigurationModal,
          })
        : renderUsersTable({
            hasIntegration,
            invitedResidents: home.invitedResidents,
            isInReconfigState,
            isInvitedUnit,
            isOccupiedUnit,
            openEditResidentModal,
            residents: home.residents,
            setInviteTenantCurrentStep,
            setShowCancelMoveInModal,
            setShowInviteTenantModal,
            setShowMoveOutModal,
            setShowReconfigurationModal,
          })}
    </>
  );
};

export default ViewResidents;
