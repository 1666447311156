import { AxiosResponse } from "axios";
import { Token } from "graphql";

import { brilliantUrl } from "./api";
import { ITokenResponse, ITokenWithUserIdResponse } from "./emails";

// Disable camelcase here since these are the raw values we expect from the API.
/* eslint-disable camelcase */
interface VerifyEmailPayloadProps {
  birthdate: string;
  family_name: string;
  given_name: string;
  token: Token;
  organization_id: string;
  organization_name: string;
  user_id: string;
}

export const postVerifyEmail = (
  email: string,
  token: string,
  code: string
): Promise<AxiosResponse<VerifyEmailPayloadProps>> =>
  brilliantUrl.post(`/admin-portal/${email}/verify-email`, { code, token });

export const postVerifyTenantEmail = (
  email: string,
  token: string,
  code: string
  // Disable camelcase here since these are the raw values we expect from the API.
  /* eslint-disable camelcase */
): Promise<AxiosResponse<ITokenWithUserIdResponse>> =>
  brilliantUrl.post(`/admin-portal/${email}/verify-tenant-email`, {
    code,
    token,
  });

export const postVerifyPhone = (
  email: string,
  token: string,
  code: string
): Promise<AxiosResponse<ITokenResponse>> =>
  brilliantUrl.post(`/admin-portal/verification/${email}/verify-phone`, {
    code,
    token,
  });

export const postSendVerificationText = (
  email: string,
  token: string,
  phoneNumber: string
): Promise<AxiosResponse<ITokenResponse>> =>
  brilliantUrl.post(`/admin-portal/verification/${email}/phone`, {
    phone_number: phoneNumber,
    token,
  });
