import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { IAppState, IRegistrationData } from "../../interfaces/interfaces";
import { addPhoneNumber, sendVerificationText } from "../../actions";

import SplitScreenLayout from "./common/SplitScreenLayout";
import PhoneVerificationForm from "./PhoneVerificationForm";

const PhoneVerificationStep: React.FC<{ newUser: IRegistrationData }> = ({
  newUser,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sendVerificationText());
  }, [dispatch]);

  const leftDescription = (
    <>
      For security purposes, 2-factor authentication will be enabled on your
      account to be used on future logins.
      <br />
      <br />
      This mobile phone number may also be used for any alerts or notifications.
    </>
  );
  const censoredPhoneNumber = `(XXX) XXX-${newUser.phoneNumber.slice(-4)}`;
  const rightMessage = (
    <>
      We sent an SMS to <b>{censoredPhoneNumber}</b> with a verification code.
      Please validate it here to enroll your mobile device.
    </>
  );
  const reinputMFAPhoneNumber = (
    <div className="right-message-gray">
      Wrong phone number?{" "}
      <button
        className="text-button"
        type="button"
        onClick={() => dispatch(addPhoneNumber(""))}
      >
        <u>Edit phone number</u>
      </button>
    </div>
  );

  return (
    <SplitScreenLayout
      form={<PhoneVerificationForm />}
      leftTitle="Let's enroll your mobile device!"
      leftDescription={leftDescription}
      rightMessage={rightMessage}
      messageAfterForm={reinputMFAPhoneNumber}
    />
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    newUser: state.newUser,
  };
};

export default connect(mapStateToProps)(PhoneVerificationStep);
