import { connect } from "react-redux";

import { IAppState } from "../../interfaces/interfaces";

export interface SubmitButtonProps {
  disabled: boolean;
  decorator?: string;
  name: string;
  loading: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  disabled,
  name,
  decorator,
  loading,
}) => {
  return (
    <button
      disabled={loading || disabled}
      data-testid="submit-btn"
      className={`primary-btn ${decorator}`}
      type="submit"
    >
      {name}
    </button>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    loading: state.loading,
  };
};
export default connect(mapStateToProps)(SubmitButton);
