import WarningSign from "../../img/WarningSign.svg";
import Footer from "../footer/Footer";

const ExpiredInvitation: React.FC = () => {
  return (
    <div className="registration-expired-invitation">
      <img
        src={WarningSign}
        alt=""
        className="registration-expired-invitation-img"
      />
      <h1>Your invitation has expired.</h1>
      <div className="menu-light menu">
        Please contact your organization&#39;s administrator to receive another
        invite.
      </div>
      <Footer containerClassName="registration-expired-invitation-footer" />
    </div>
  );
};

export default ExpiredInvitation;
