import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery, useReactiveVar } from "@apollo/client";

import { ReactComponent as CloseIcon } from "../../../img/Close.svg";
import { ReactComponent as TenantsImg } from "../../../img/Tenants.svg";
import {
  reconfigurationErrorVar,
  userManagementGenericErrorVar,
} from "../../apollo/LocalState";
import {
  EReconfigurationError,
  EUserManagementGenericError,
  IPropertyIdVariable,
  IUnitDetailsData,
} from "../../../interfaces/interfaces";
import { resetHome } from "../../../actions";
import {
  renderTransitionModalContent,
  renderUnitDetailsModalContent,
} from "../../../utils/utils";
import LearnMoreButton from "../../buttons/LearnMoreButton";
import { GET_HOME } from "../../../api/gqlQueries";
import {
  renderInstallIncompleteAlert,
  renderTenantModalGenericError,
  renderTenantModalReconfigurationError,
} from "../common/tenant_modal_common_views";
import { LEARN_MORE_MOVE_OUT } from "../../../utils/LearnMoreLinks";

export interface MoveOutModalWizardProps {
  closeMoveOutModal: () => void;
  propertyName: string;
  unitName: string;
  propertyId: string;
  // currentMoveOutStep is mainly for storybook. When we upgrade storybook to use the "play"
  // feature, we can probably remove this.
  currentMoveOutStep?: MoveOutModalStep;
}

export enum MoveOutModalStep {
  MOVE_OUT = 1,
  MOVE_OUT_CONFIRMED = 2,
}

const renderHeader = (currentStep: MoveOutModalStep) => {
  switch (currentStep) {
    case MoveOutModalStep.MOVE_OUT:
      return <b>Start Move Out</b>;
    case MoveOutModalStep.MOVE_OUT_CONFIRMED:
      return <b>Move Out Confirmed</b>;
    default:
      return <></>;
  }
};

const MoveOutView: React.FC<{
  closeMoveOutModal: () => void;
  propertyId: string;
  setCurrentStep: React.Dispatch<React.SetStateAction<MoveOutModalStep>>;
}> = ({ closeMoveOutModal, propertyId, setCurrentStep }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      reconfigurationErrorVar(EReconfigurationError.noError);
      userManagementGenericErrorVar(EUserManagementGenericError.noError);
    };
  }, []);
  const reconfigurationError = useReactiveVar(reconfigurationErrorVar);
  const genericError = useReactiveVar(userManagementGenericErrorVar);
  const { data } = useQuery<IUnitDetailsData, IPropertyIdVariable>(GET_HOME, {
    variables: { propertyId },
  });
  const isInstallationIncomplete = data?.home.isInstallIncomplete ?? false;
  return (
    <div className="tenants-show-body">
      <div
        className="tenants-show-description-container"
        data-testid="move-out-container"
      >
        {renderUnitDetailsModalContent({
          descriptionList: [
            "All residents will lose access to the unit's Brilliant home and its devices.",
            "The residents will be notified of the move out by email.",
            "The unit's state will change to vacant and all resident information will be removed from the home.",
            "The unit's Brilliant home will be reconfigured with the default settings for vacant units.",
          ],
          title: "If you confirm a move out:",
        })}
      </div>
      {isInstallationIncomplete &&
        renderInstallIncompleteAlert({ isMoveIn: false })}
      {reconfigurationError !== EReconfigurationError.noError &&
        renderTenantModalReconfigurationError({ reconfigurationError })}
      {genericError !== EUserManagementGenericError.noError &&
        renderTenantModalGenericError({ genericError })}
      <div className="invite-tenant-modal-actions margin-top-small">
        <button
          type="button"
          className="tertiary-btn-small tertiary-btn-small-alert"
          onClick={() => {
            closeMoveOutModal();
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          data-testid="remove-registered-tenant-btn"
          className="tertiary-btn-small tertiary-btn-small-main tertiary-btn-small-main-alert invite-tenant-modal-actions-submit"
          onClick={() => {
            dispatch(
              resetHome({
                increaseCurrentStep: () => {
                  setCurrentStep(MoveOutModalStep.MOVE_OUT_CONFIRMED);
                },
                propertyId,
              })
            );
          }}
        >
          Confirm Move Out
        </button>
      </div>
    </div>
  );
};

export const MoveOutConfirmedView: React.FC = () => {
  return (
    <div className="tenants-show-body">
      <div
        className="invitation-sent-container"
        data-testid="move-out-confirmed-container"
      >
        {renderTransitionModalContent({
          whatJustHappenedList: [
            "The unit's state has changed to vacant.",
            "All residents have lost access to the unit's Brilliant home and its devices.",
            "All resident information has been removed from the units Brilliant home.",
          ],
          whatsNextList: [
            {
              bulletPoint: (
                <>
                  The unit&apos;s Brilliant home is automatically being
                  reconfigured with the default settings for vacant units.
                </>
              ),
            },
            {
              bulletPoint: (
                <>
                  Home reconfiguration should take about 5 minutes, but in some
                  cases may take longer.
                </>
              ),
            },
            {
              bulletPoint: (
                <>
                  You will be able to invite new residents after reconfiguration
                  successfully completes.
                </>
              ),
            },
          ],
        })}
      </div>
      <div className="invitation-sent-learn-more-container">
        <LearnMoreButton link={LEARN_MORE_MOVE_OUT} />
      </div>
    </div>
  );
};

const MoveOutModalWizard: React.FC<MoveOutModalWizardProps> = ({
  closeMoveOutModal,
  propertyName,
  unitName,
  propertyId,
  currentMoveOutStep = MoveOutModalStep.MOVE_OUT,
}) => {
  const [currentStep, setCurrentStep] =
    useState<MoveOutModalStep>(currentMoveOutStep);
  return (
    <div className="popup-background">
      <div className="tenants-show-container align-as-column">
        <div className="tenants-show-header">
          <div className="center-align-as-row">
            <TenantsImg title="Residents" />
            <div className="tenants-show-header-title menu">
              {renderHeader(currentStep)}
              <div className="tenants-show-header-title-name">
                : {unitName}, {propertyName}
              </div>
            </div>
          </div>
          <CloseIcon
            onClick={() => {
              closeMoveOutModal();
            }}
            className="changes-header-img"
            title="close"
          />
        </div>
        {currentStep === MoveOutModalStep.MOVE_OUT && (
          <MoveOutView
            closeMoveOutModal={closeMoveOutModal}
            propertyId={propertyId}
            setCurrentStep={setCurrentStep}
          />
        )}
        {currentStep === MoveOutModalStep.MOVE_OUT_CONFIRMED && (
          <MoveOutConfirmedView />
        )}
      </div>
    </div>
  );
};

export default MoveOutModalWizard;
