import { IRegisteredUser } from "../interfaces/interfaces";
import { ReactComponent as Trash } from "../img/Trash.svg";
import { ReactComponent as Gear } from "../img/settings.svg";
import { dateTimeTimeZoneString } from "../utils/utils";
import { selectedUserToShowVar } from "../components/apollo/LocalState";

const RegisteredUserCard: React.FC<{
  registeredUser: IRegisteredUser;
  openRemoveFlowModal: (user: IRegisteredUser) => void;
  renderDeleteButtonAsIcon?: boolean;
  shouldGearRender: boolean;
  openEditResidentModal?: () => void;
}> = ({
  registeredUser,
  openRemoveFlowModal = () => undefined,
  renderDeleteButtonAsIcon = true,
  shouldGearRender,
  openEditResidentModal = () => undefined,
}) => {
  return (
    <div className="user-card-container">
      {!registeredUser.deletedDatetime && (
        <div className="user-card-entry user-card-entry-container">
          <div>
            <div className="user-card-entry-header text-label">Name</div>
            <div className="user-card-entry-body placeholder">
              {registeredUser.givenName} {registeredUser.familyName}
            </div>
          </div>

          <button
            type="button"
            className="table-cell-trash"
            onClick={() => {
              openRemoveFlowModal(registeredUser);
            }}
          >
            {renderDeleteButtonAsIcon && (
              <Trash title="Remove user" data-testid="trash-icon" />
            )}
            {shouldGearRender && (
              <Gear
                onClick={() => {
                  openEditResidentModal();
                  selectedUserToShowVar(registeredUser);
                }}
                title="Edit user"
                data-testid="gear-icon"
              />
            )}
          </button>
        </div>
      )}
      {!registeredUser.deletedDatetime && (
        <div className="user-card-entry">
          <div className="user-card-entry-header text-label">Email</div>
          <div className="user-card-entry-body placeholder">
            {registeredUser.emailAddress}
          </div>
        </div>
      )}
      <div className="user-card-entry user-card-entry-container">
        <div>
          <div className="user-card-entry-header text-label">Status</div>
          {registeredUser.deletedDatetime ? (
            <div className="user-card-entry-body placeholder alert">
              Brilliant Account deleted on{" "}
              {dateTimeTimeZoneString(registeredUser.deletedDatetime)}
            </div>
          ) : (
            <div className="user-card-entry-body placeholder">
              Joined on{" "}
              {dateTimeTimeZoneString(registeredUser.joinedPropertyDatetime)}
            </div>
          )}
        </div>
        {registeredUser.deletedDatetime && (
          <button
            type="button"
            className="table-cell-trash"
            onClick={() => {
              openRemoveFlowModal(registeredUser);
            }}
          >
            {shouldGearRender && (
              <Gear title="Edit user" data-testid="gear-icon" />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default RegisteredUserCard;
