import SplitScreenLayout from "./SplitScreenLayout";

export interface EmailVerificationStepProps {
  email: string;
  form: React.ReactNode;
  isUser?: boolean;
}

const EmailVerificationStep: React.FC<EmailVerificationStepProps> = ({
  email,
  form,
  isUser,
}) => {
  const rightMessage = (
    <>
      We sent you an email to <b>{email}</b> with a verification code. Please
      validate it here.
    </>
  );

  return (
    <SplitScreenLayout
      form={form}
      leftTitle="We take security seriously."
      leftDescription="Before we create your account, please verify your email address.
      This is to confirm you did not receive the registration link via any insecure
      methods (like a forwarded email)."
      rightMessage={rightMessage}
      isUser={isUser}
    />
  );
};

export default EmailVerificationStep;
