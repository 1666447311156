import { ReactComponent as Alert } from "../../../img/Alert.svg";
import BaseModal, { EModalButtonType } from "../../modals/BaseModal";

export interface RemoteLockLargeUpdateModalProps {
  confirmModalFn: () => void;
  closeModalFn: () => void;
}

const RemoteLockLargeUpdateModal: React.FC<RemoteLockLargeUpdateModalProps> = ({
  confirmModalFn,
  closeModalFn,
}) => {
  return (
    <BaseModal
      actionButtonFn={confirmModalFn}
      actionButtonText="Confirm"
      closeModalFn={closeModalFn}
      headerText="Before You Proceed"
      buttonType={EModalButtonType.BlueButton}
    >
      <Alert title="Alert" />

      <div className="menu menu-light modal-body-text-container">
        You are attempting to make a large number of access assignment updates.
        <br />
        <br />
        It may take a while for these updates to be fully applied to the
        selected unit&#40;s&#41;.
        <br />
        <br />
        You will not be able to make any additional changes to access for the
        selected units while these updates are being processed.
        <br />
        <br />
        Would you like to proceed?
      </div>
    </BaseModal>
  );
};
export default RemoteLockLargeUpdateModal;
