import momentTimeZone from "moment-timezone";
import moment from "moment";

import Tag from "../components/common/Tag";
import DialogTag from "../components/common/DialogTags";
import { IInvitedUser, IWhatNextList } from "../interfaces/interfaces";
import { MOVE_IN_STEP } from "../components/tenantManagement/TenantManagementSteps";

export const oneSecInMs = 1000;
export const oneMinInMs = 60 * oneSecInMs;
export const oneHourInMs = 60 * oneMinInMs;

export const SMART_CARD_LOCK_TYPE = "connector_lock";

export const TermsOfService =
  "https://www.brilliant.tech/pages/terms-of-service";
export const PrivacyPolicy =
  "https://www.brilliant.tech/pages/privacy-statement-for-brilliant-products-and-services";

export const quantityDisplay = (
  amount: number,
  singularWord: string
): string => {
  if (amount === 1) {
    return singularWord;
  }
  return `${singularWord}s`;
};

export const isDateMoreThanThirtyMinutesOld = (date: string) => {
  const currentDate = new Date();
  const dateString = new Date(date);
  return currentDate.valueOf() - dateString.valueOf() > 1800000;
};

export const getReadableTime = (date: string) => {
  const dateString = new Date(date);
  const options = {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    month: "short",
    year: "numeric",
  } as const;
  return dateString.toLocaleDateString(undefined, options);
};

export const dateTimeTimeZoneString = (dateTime: string | null): string => {
  const date = moment(dateTime).format("L");
  const time = moment(dateTime).format("LT");
  const browserTimeZone = moment.tz.guess();
  const timeZone = momentTimeZone.tz(dateTime, browserTimeZone).zoneAbbr();

  return `${date} ${time} ${timeZone}`;
};

export enum TagTypes {
  Alert = "unit-status-alert",
  Warning = "unit-status-warning",
}

export const renderMainTag = ({
  listOfAllTagNames,
  mainTagName,
  numberOfTags,
  tagType,
}: {
  listOfAllTagNames: string[];
  mainTagName: string;
  numberOfTags: number;
  tagType: string;
}): JSX.Element => {
  return (
    <div className="tooltip">
      <Tag
        name={`${numberOfTags} ${quantityDisplay(numberOfTags, mainTagName)}`}
        tagType={tagType}
      />
      <div className="dialog-box">
        <DialogTag names={listOfAllTagNames} tagsType={tagType} />
      </div>
    </div>
  );
};

export const getUniqueTags = (TagElements: string[]): string[] => {
  const uniqueChars: string[] = [];
  TagElements.forEach((element) => {
    if (!uniqueChars.includes(element)) {
      uniqueChars.push(element);
    }
  });
  return uniqueChars;
};

interface IconProps {
  title?: string;
}

export const RenderEmptyHomeView: React.FC<{
  icon: React.FC<React.SVGProps<SVGSVGElement> & IconProps>;
  iconTitle: string;
  iconClassName?: string;
  componentTitle: string;
  componentDescription: string;
}> = ({
  icon: Icon,
  iconTitle,
  iconClassName = "",
  componentTitle,
  componentDescription,
}): JSX.Element => {
  return (
    <div className="empty-container">
      <Icon className={iconClassName} title={iconTitle} />
      <div className="placeholder empty-title">{componentTitle}</div>
      <p>{componentDescription}</p>
    </div>
  );
};

export const renderTags = ({
  alerts,
  warnings,
}: {
  alerts: string[];
  warnings: string[];
}): JSX.Element => {
  const numberOfAlerts = alerts.length;
  const hasAlerts = alerts.length > 0;
  const hasWarnings = warnings.length > 0;
  return (
    <>
      {hasAlerts &&
        renderMainTag({
          listOfAllTagNames: alerts,
          mainTagName: "Alert",
          numberOfTags: numberOfAlerts,
          tagType: TagTypes.Alert,
        })}
      {hasWarnings &&
        renderMainTag({
          listOfAllTagNames: warnings,
          mainTagName: "Warning",
          numberOfTags: warnings.length,
          tagType: TagTypes.Warning,
        })}
    </>
  );
};

export const renderSideLine = ({
  hasAlerts,
  lineClassName,
  hasWarnings,
}: {
  hasAlerts: boolean;
  lineClassName: string;
  hasWarnings: boolean | undefined;
}): JSX.Element => {
  if (hasAlerts) {
    return <div className={`${lineClassName} unit-line-red`} />;
  }
  if (hasWarnings) {
    return <div className={`${lineClassName} unit-line-yellow`} />;
  }
  return <></>;
};

export const renderUnitDetailsMainBtn = ({
  isInvitedUnit,
  isOccupiedUnit,
  isSmallScreen,
  isVacantUnit,
  hasIntegration,
  setShowMoveOutModal,
  setShowMoveInModal,
  setShowReconfigurationModal,
  setInviteTenantCurrentStep,
  setShowCancelMoveInModal,
  isInReconfigState,
}: {
  isInvitedUnit: boolean;
  isOccupiedUnit: boolean;
  isSmallScreen: boolean;
  isVacantUnit: boolean;
  hasIntegration: boolean;
  isInReconfigState: boolean;
  setShowMoveOutModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowMoveInModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowReconfigurationModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setInviteTenantCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
  setShowCancelMoveInModal?: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  if (isOccupiedUnit) {
    return (
      <button
        className={`tertiary-btn-small ${
          hasIntegration && "tenants-add-btn-integrations"
        } ${
          isSmallScreen &&
          "tenants-add-btn-primary tenants-add-btn-primary-main"
        }`}
        type="button"
        onClick={() => {
          setShowReconfigurationModal?.(isInReconfigState);
          setShowMoveOutModal?.(!isInReconfigState);
        }}
      >
        Start Move Out
      </button>
    );
  }
  if (isInvitedUnit) {
    return (
      <button
        className={`tertiary-btn-small ${
          hasIntegration && "tenants-add-btn-integrations"
        } ${
          isSmallScreen &&
          "tenants-add-btn-primary tenants-add-btn-primary-main"
        }`}
        type="button"
        onClick={() => {
          setShowCancelMoveInModal?.(true);
        }}
      >
        Cancel Move In
      </button>
    );
  }
  return (
    <button
      className={`tertiary-btn-small ${
        hasIntegration && "tenants-add-btn-integrations"
      } ${
        isSmallScreen && "tenants-add-btn-primary tenants-add-btn-primary-main"
      } ${isVacantUnit && "tenants-add-btn-vacant"}`}
      type="button"
      onClick={() => {
        setShowMoveInModal?.(!isInReconfigState);
        setShowReconfigurationModal?.(isInReconfigState);
        setInviteTenantCurrentStep?.(MOVE_IN_STEP);
      }}
    >
      Start Move In
    </button>
  );
};

export function renderUnitDetailsModalContent({
  descriptionList,
  title,
  centerAlignHeaderForSmallScreen,
}: {
  descriptionList: (string | JSX.Element)[];
  title: string;
  centerAlignHeaderForSmallScreen?: boolean;
}): JSX.Element {
  return (
    <>
      <p
        className={`tenants-show-description-text-p ${
          centerAlignHeaderForSmallScreen &&
          "tenants-show-description-alert-title"
        }`}
      >
        {title}
      </p>
      <ul className="tenants-show-ul">
        {descriptionList.map((description) => {
          return <li className="tenants-show-li">{description}</li>;
        })}
      </ul>
    </>
  );
}

export function renderTransitionModalContent({
  whatJustHappenedList,
  whatsNextList,
}: {
  whatJustHappenedList: string[];
  whatsNextList: IWhatNextList[];
}): JSX.Element {
  return (
    <>
      <div className="invitation-sent-column-container invitation-sent-column-container-left">
        <p className="tenants-show-description-text-p">What just happened:</p>
        <ul className="tenants-show-ul invitation-sent-checkmark-ul">
          {whatJustHappenedList.map((description) => {
            return <li className="tenants-show-li">{description}</li>;
          })}
        </ul>
      </div>
      <div className="invitation-sent-column-container">
        <p className="tenants-show-description-text-p">What&apos;s next:</p>
        <ul className="tenants-show-ul invitation-sent-arrow-ul">
          {whatsNextList.map(({ bulletPoint, subBulletPoints }) => {
            return (
              <>
                <li className="tenants-show-li">{bulletPoint}</li>
                {subBulletPoints && (
                  <ul className="tenants-show-ul">
                    {subBulletPoints.map((subBulletPoint) => {
                      return (
                        <li className="tenants-show-li">{subBulletPoint}</li>
                      );
                    })}
                  </ul>
                )}
              </>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export const isInviteExpired = ({
  invitedUser,
}: {
  invitedUser: IInvitedUser;
}): boolean => {
  const expirationDatetime = new Date(invitedUser.expirationDatetime);
  return expirationDatetime.getTime() <= Date.now();
};

export const renderTermsAndConditionsAndPrivacyPolicy = (): JSX.Element => {
  return (
    <div className="right-field-info right-field-info-container">
      By creating an account, you agree to our{" "}
      <a
        className="right-field-info-link"
        target="_blank"
        rel="noopener noreferrer"
        href={TermsOfService}
      >
        Terms of Service
      </a>{" "}
      and acknowledge that you understand our{" "}
      <a
        className="right-field-info-link"
        target="_blank"
        rel="noopener noreferrer"
        href={PrivacyPolicy}
      >
        Privacy Policy
      </a>
      .
    </div>
  );
};
