const LoginResultModal: React.FC<{
  children: React.ReactNode;
  logo: JSX.Element;
  title: string;
  actionBtnTitle?: string;
  actionBtnAction?: () => void;
}> = ({ children, logo, title, actionBtnTitle, actionBtnAction }) => {
  return (
    <div className="background-container">
      <div className="login-result-modal-container">
        <div>{logo}</div>
        <h3
          data-testid="login-result-modal-title"
          className="login-result-modal-title-container"
        >
          {title}
        </h3>
        <p className="login-result-modal-body-container">{children}</p>
        {actionBtnTitle && (
          <button
            type="button"
            onClick={actionBtnAction}
            className="primary-btn login-result-modal-button"
          >
            {actionBtnTitle}
          </button>
        )}
      </div>
    </div>
  );
};

export default LoginResultModal;
