import { EUnitDetailsFilter } from "../../../interfaces/interfaces";
import { renderUnitDetailsElementAmount } from "../common/CommonUnitDetailsComponents";

export interface UnitDetailsMainInfoForSmallScreenProps {
  currentUnitDetailsFilter: EUnitDetailsFilter;
  totalTenants: number;
  isHubless: boolean;
  numBrilliantDevices: number;
  numIntegrations: number;
  setUnitDetailsFilter: React.Dispatch<
    React.SetStateAction<EUnitDetailsFilter>
  >;
}

const UnitDetailsMainInfoForSmallScreen: React.FC<
  UnitDetailsMainInfoForSmallScreenProps
> = ({
  currentUnitDetailsFilter,
  setUnitDetailsFilter,
  isHubless,
  totalTenants,
  numBrilliantDevices,
  numIntegrations,
}) => {
  return (
    <div className="unit-main-container-small">
      <div className="unit-details-container unit-description-small">
        <button
          data-testid="brilliants-amount"
          className={`unit-description-small-info unit-details ${
            currentUnitDetailsFilter === EUnitDetailsFilter.brilliants
              ? "unit-details-selected"
              : ""
          }`}
          type="button"
          onClick={() => setUnitDetailsFilter(EUnitDetailsFilter.brilliants)}
        >
          {isHubless ? (
            <>
              <span style={{ opacity: 0 }} className="unit-details-number menu">
                0
              </span>
              Settings
            </>
          ) : (
            renderUnitDetailsElementAmount({
              nameOfSingleDevice: "Brilliant",
              numDevices: numBrilliantDevices,
            })
          )}
        </button>
        <button
          data-testid="integrations-amount"
          className={`unit-description-small-info unit-details ${
            currentUnitDetailsFilter === EUnitDetailsFilter.integrations
              ? "unit-details-selected"
              : ""
          }`}
          type="button"
          onClick={() => setUnitDetailsFilter(EUnitDetailsFilter.integrations)}
        >
          {renderUnitDetailsElementAmount({
            nameOfSingleDevice: "Integration",
            numDevices: numIntegrations,
          })}
        </button>
        <button
          data-testid="residents-amount"
          className={`unit-description-small-info unit-details ${
            currentUnitDetailsFilter === EUnitDetailsFilter.residents
              ? "unit-details-selected"
              : ""
          }`}
          type="button"
          onClick={() => setUnitDetailsFilter(EUnitDetailsFilter.residents)}
        >
          {renderUnitDetailsElementAmount({
            nameOfSingleDevice: "Resident",
            numDevices: totalTenants,
          })}
        </button>
      </div>
    </div>
  );
};

export default UnitDetailsMainInfoForSmallScreen;
