import {
  AUTHENTICATION_CODE_FAILURE,
  AUTHENTICATION_FAILURE,
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_USER_RATE_LIMITED,
  INVALID_TOKEN,
  LOGIN_USER_ERROR,
  LOGIN_USER_FAILURE,
  LOGIN_USER_FORBIDDEN,
  LOGIN_USER_RATE_LIMITED,
  LOGIN_USER_SUCCESS,
  REMOVE_LOGIN_ERROR_ON_CHANGE,
  SAVE_EMAIL_ADDRESS,
  SAVE_USER_ID,
  USER_SIGNED_OUT,
} from "../../actions/types";
import {
  ELoginError,
  IActionLogin,
  ILoginData,
} from "../../interfaces/interfaces";

export default (
  state = {
    email: "",
    error: ELoginError.noError,
    isAuthenticated: false,
    isSecondStep: false,
    mfaToken: "",
    organizationId: "",
    phoneNumber: "",
    userId: "",
  },
  action: IActionLogin
): ILoginData => {
  switch (action.type) {
    case REMOVE_LOGIN_ERROR_ON_CHANGE:
      return {
        ...state,
        error: ELoginError.noError,
      };
    case LOGIN_USER_RATE_LIMITED:
      return {
        ...state,
        error: ELoginError.isLimit,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        email: action.payload.email,
        error: ELoginError.noError,
        isAuthenticated: false,
        isSecondStep: true,
        mfaToken: action.payload.mfaToken,
        phoneNumber: action.payload.phoneNumber,
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        error: ELoginError.isWrong,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        error: ELoginError.isError,
      };
    case SAVE_USER_ID:
      return {
        ...state,
        userId: action.payload.userId,
      };
    case SAVE_EMAIL_ADDRESS:
      return {
        ...state,
        email: action.payload.email,
      };
    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        error: ELoginError.noError,
        isAuthenticated: true,
        isSecondStep: false,
        organizationId: action.payload.organizationId,
        userId: action.payload.userId,
      };
    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        error: ELoginError.isError,
        isAuthenticated: false,
      };
    case AUTHENTICATION_CODE_FAILURE:
      return {
        ...state,
        error: ELoginError.isWrongCode,
        isAuthenticated: false,
      };
    case AUTHENTICATION_USER_RATE_LIMITED:
      return {
        ...state,
        error: ELoginError.isLimit,
        isAuthenticated: false,
      };
    case INVALID_TOKEN:
      return {
        ...state,
        error: ELoginError.isInvalidToken,
        isAuthenticated: false,
        isSecondStep: false,
      };
    case LOGIN_USER_FORBIDDEN:
      return {
        ...state,
        error: ELoginError.isForbidden,
        isAuthenticated: false,
      };
    case USER_SIGNED_OUT:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
