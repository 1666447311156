import { Dispatch } from "redux";

import { TOKEN_KEY } from "../actions/constants";
import { logOutUser } from "../actions/actionCreators/userSession";

export const getToken = (): string | null => {
  return window.localStorage.getItem(TOKEN_KEY);
};

export const setToken = (token: string): void => {
  window.localStorage.setItem(TOKEN_KEY, token);
};

export const removeToken = (): void => {
  window.localStorage.removeItem(TOKEN_KEY);
};

export const checkToken = (dispatch: Dispatch, token: string | null): void => {
  if (!token) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch<any>(logOutUser());
    throw new Error("Missing token to make request!");
  }
};
