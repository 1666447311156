import _ from "lodash";
import { useReactiveVar } from "@apollo/client";

import { dateTimeTimeZoneString, isInviteExpired } from "../../utils/utils";
import { IInvitedUser, IRegisteredUser } from "../../interfaces/interfaces";
import { ReactComponent as Trash } from "../../img/Trash.svg";
import { ReactComponent as Gear } from "../../img/settings.svg";
import InvitedUserCard from "../../tableCards/InvitedUserCard";
import RegisteredUserCard from "../../tableCards/RegisteredUserCard";
import { selectedUserToShowVar } from "../apollo/LocalState";

export interface IUserTableProps {
  shouldEmptyTableRender: boolean;
  users: IRegisteredUser[];
  invitedUsers: IInvitedUser[];
  children?: React.ReactNode;
  shouldTrashRender?: boolean;
  shouldGearRender?: boolean;
  openRemoveFlowModal?: (user: IRegisteredUser | IInvitedUser | null) => void;
  openEditResidentModal?: () => void;
}

function TrashIconRender(
  shouldTrashRender: boolean,
  openRemoveFlowModal: (user: IRegisteredUser | IInvitedUser | null) => void,
  userToRemove: IRegisteredUser | IInvitedUser | null
) {
  if (shouldTrashRender) {
    return (
      <div className="flex-end-align table-cell table-cell-very-small">
        <button
          type="button"
          className="table-cell-trash"
          onClick={() => {
            openRemoveFlowModal(userToRemove);
          }}
        >
          <Trash title="trash" />
        </button>
      </div>
    );
  }
}

function GearIconRender({
  shouldGearRender,
  openEditResidentModal,
  userToShow,
}: {
  shouldGearRender: boolean;
  openEditResidentModal: () => void;
  userToShow: IInvitedUser | IRegisteredUser;
}) {
  if (shouldGearRender) {
    return (
      <div className="flex-end-align table-cell table-cell-very-small">
        <button type="button" className="table-cell-trash">
          <Gear
            onClick={() => {
              openEditResidentModal();
              selectedUserToShowVar(userToShow);
            }}
            title="edit"
          />
        </button>
      </div>
    );
  }
}

// TODO: Remove this function and `table-row-body` class if management decides to remove the empty table row
function RenderEmptyTable(shouldEmptyTableRender: boolean) {
  if (shouldEmptyTableRender) {
    return (
      <div className="table-row table-row-body">
        <div className="table-cell">None</div>
      </div>
    );
  }
}

export const UsersTable: React.FC<IUserTableProps> = ({
  shouldEmptyTableRender,
  users,
  invitedUsers,
  children,
  shouldTrashRender = false,
  shouldGearRender = false,
  openRemoveFlowModal = () => undefined,
  openEditResidentModal = () => undefined,
}) => {
  return (
    <div className="table-container table-responsive">
      <div className="table-row table-row-header">
        <div className="table-cell text-label">Email</div>
        <div className="table-cell text-label">Name</div>
        <div className="table-cell text-label">Status</div>
      </div>
      {RenderEmptyTable(shouldEmptyTableRender)}
      {users.map((element: IRegisteredUser) => {
        return (
          <div key={element.id} className="table-row table-responsive">
            <div className="table-cell placeholder">{element.emailAddress}</div>
            <div className="table-cell placeholder">
              {element.givenName} {element.familyName}
            </div>
            <div className="table-cell placeholder">
              {element.deletedDatetime ? (
                <div className="alert">
                  Brilliant Account deleted on{" "}
                  {dateTimeTimeZoneString(element.deletedDatetime)}
                </div>
              ) : (
                <div>
                  Joined on{" "}
                  {dateTimeTimeZoneString(element.joinedPropertyDatetime)}
                </div>
              )}
            </div>
            {TrashIconRender(shouldTrashRender, openRemoveFlowModal, element)}
            {GearIconRender({
              openEditResidentModal,
              shouldGearRender,
              userToShow: element,
            })}
          </div>
        );
      })}
      {invitedUsers.map((element: IInvitedUser) => {
        const invitationExpired = isInviteExpired({ invitedUser: element });
        return (
          <div
            key={element.id}
            className="table-row table-row-body table-responsive"
          >
            <div className="table-cell placeholder">{element.emailAddress}</div>
            <div className="table-cell placeholder" />
            {invitationExpired ? (
              <div
                className="table-cell table-row-body-invite alert placeholder"
                data-testid="expired-invitation"
              >
                Invite expired{" "}
                {dateTimeTimeZoneString(element.expirationDatetime)}
              </div>
            ) : (
              <div className="table-cell table-row-body-invite placeholder">
                Invite expires{" "}
                {dateTimeTimeZoneString(element.expirationDatetime)}
              </div>
            )}
            {TrashIconRender(shouldTrashRender, openRemoveFlowModal, element)}
            {GearIconRender({
              openEditResidentModal,
              shouldGearRender,
              userToShow: element,
            })}
          </div>
        );
      })}
      {children}
    </div>
  );
};

UsersTable.defaultProps = {
  openRemoveFlowModal: () => undefined,
  shouldTrashRender: false,
};

export const UsersTables: React.FC<{
  invitedPortalUsers: IInvitedUser[];
  portalUsers: IRegisteredUser[];
  shouldEmptyTableRender: boolean;
  shouldTrashRender: boolean;
  shouldGearRender: boolean;
  openRemoveFlowModal: (user: IInvitedUser | IRegisteredUser | null) => void;
  openEditResidentModal?: () => void;
  children?: React.ReactNode;
}> = ({
  invitedPortalUsers,
  portalUsers,
  shouldEmptyTableRender,
  shouldTrashRender,
  shouldGearRender,
  openRemoveFlowModal,
  openEditResidentModal,
  children,
}) => {
  const activeInvitedUsers = invitedPortalUsers.filter(
    (user) => !isInviteExpired({ invitedUser: user })
  );
  const expiredInvitedUsers = invitedPortalUsers.filter((user) =>
    isInviteExpired({ invitedUser: user })
  );
  const invitedUsers = _.sortBy(
    activeInvitedUsers,
    "expirationDatetime"
  ).concat(_.sortBy(expiredInvitedUsers, "expirationDatetime"));

  return (
    <>
      <div className="bcc-table">
        <UsersTable
          shouldEmptyTableRender={shouldEmptyTableRender}
          users={portalUsers}
          invitedUsers={invitedUsers}
          shouldTrashRender={shouldTrashRender}
          openRemoveFlowModal={openRemoveFlowModal}
          shouldGearRender={shouldGearRender}
          openEditResidentModal={openEditResidentModal}
        >
          {children}
        </UsersTable>
      </div>
      {portalUsers.map((portalUser: IRegisteredUser) => {
        return (
          <RegisteredUserCard
            key={portalUser.id}
            registeredUser={portalUser}
            openRemoveFlowModal={openRemoveFlowModal}
            renderDeleteButtonAsIcon={shouldTrashRender}
            shouldGearRender={shouldGearRender}
            openEditResidentModal={openEditResidentModal}
          />
        );
      })}
      {invitedUsers.map((invitedPortalUser: IInvitedUser) => {
        return (
          <InvitedUserCard
            key={invitedPortalUser.id}
            invitedUser={invitedPortalUser}
            openRemoveFlowModal={openRemoveFlowModal}
            renderDeleteButtonAsIcon={shouldTrashRender}
            shouldGearRender={shouldGearRender}
            openEditResidentModal={openEditResidentModal}
          />
        );
      })}
    </>
  );
};

const renderRegisteredUserEntry = ({
  userToShow,
}: {
  userToShow: IRegisteredUser;
}) => {
  return (
    <div className="table-row">
      <div
        className={`${
          userToShow.deletedDatetime && "table-cell-hidden-small-screens"
        }`}
      >
        <div className="table-row-header table-cell text-label">Email</div>
        <div className="table-cell placeholder">{userToShow.emailAddress}</div>
      </div>
      <div
        className={`${
          userToShow.deletedDatetime && "table-cell-hidden-small-screens"
        }`}
      >
        <div className="table-row-header table-cell text-label">Name</div>
        <div className="table-cell placeholder">
          {userToShow.givenName} {userToShow.familyName}
        </div>
      </div>
      <div className="table-cell-date">
        <div className="table-row-header table-cell text-label">Status</div>
        <div className="table-cell-date table-cell table-cell-entry placeholder">
          {userToShow.deletedDatetime ? (
            <div className="alert">
              Brilliant Account deleted on{" "}
              {dateTimeTimeZoneString(userToShow.deletedDatetime)}
            </div>
          ) : (
            <div>
              Joined on{" "}
              {dateTimeTimeZoneString(userToShow.joinedPropertyDatetime)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const renderInvitedUserEntry = ({
  userToShow,
}: {
  userToShow: IInvitedUser;
}) => {
  const invitationExpired = isInviteExpired({ invitedUser: userToShow });
  return (
    <div className="table-row">
      <div>
        <div className="table-row-header table-cell text-label">Email</div>
        <div className="table-cell placeholder">{userToShow.emailAddress}</div>
      </div>
      <div className="table-row-invited-name">
        <div className="table-row-header table-cell text-label">Name</div>
        <div className="table-cell placeholder" />
      </div>
      <div className="table-cell-date">
        <div className="table-row-header table-cell text-label">Status</div>
        <div className="table-cell-date table-cell-entry table-cell-date table-row-body-invite placeholder">
          {invitationExpired ? (
            <div className="alert" data-testid="expired-invitation">
              Invite expired{" "}
              {dateTimeTimeZoneString(userToShow.expirationDatetime)}
            </div>
          ) : (
            <div className="table-row-body-invite">
              Invite expires{" "}
              {dateTimeTimeZoneString(userToShow.expirationDatetime)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const SelectedTableEntry: React.FC = () => {
  const selectedUserToShow = useReactiveVar(selectedUserToShowVar);
  if (!selectedUserToShow) {
    return <></>;
  }
  const isRegisteredUser = "givenName" in selectedUserToShow;
  return (
    <div className="table-container">
      {isRegisteredUser
        ? renderRegisteredUserEntry({ userToShow: selectedUserToShow })
        : renderInvitedUserEntry({ userToShow: selectedUserToShow })}
    </div>
  );
};
