enum EWebAPIError {
  INVALID_TOKEN = 3,
  INVALID_EMAIL = 4,
  TOO_MANY_REQUESTS = 7,
  BIRTHDATE_INVALID = 101,
  PASSWORD_INVALID = 102,
  BIRTHDATE_FORMAT_INVALID = 103,
  FAMILY_NAME_INVALID = 104,
  GIVEN_NAME_INVALID = 105,
  WRONG_CODE = 201,
  UPSTREAM_FAILED_ERROR = 304,
  WRONG_CREDENTIALS = 800,
  UNAUTHORIZED_LOGIN = 801,
  USER_ALREADY_EXISTS_FOR_PROPERTY = 903,
  USER_IS_ALREADY_ADMIN = 904,
  INVITATION_NOT_FOUND = 906,
  PROPERTY_RECONFIGURATION_IN_PROGRESS = 908,
  PROPERTY_RECONFIGURATION_FAILED = 909,
  REMOTELOCK_CARD_NUMBER_ALREADY_TAKEN = 1100,
  REMOTELOCK_CARD_NUMBER_IS_NOT_VALID = 1101,
  REMOTELOCK_SMART_CARD_ALREADY_TAKEN = 1104,
  REMOTELOCK_SMART_CARD_DOES_NOT_EXIST = 1105,
}

export default EWebAPIError;
