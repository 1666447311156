import { useState } from "react";

import {
  EFilterColorMethod,
  EPropertyFilter,
  IObjectToIFiltersData,
  IObjectToIUnitDetailsDataHomeAmount,
} from "../../interfaces/interfaces";
import { ReactComponent as Arrow } from "../../img/Arrow.svg";

import FilterItem from "./FilterItem";

export interface IFilterListSelect {
  amount: IObjectToIUnitDetailsDataHomeAmount;
  currentFilterEnum: EPropertyFilter;
  setFilterData: React.Dispatch<React.SetStateAction<EPropertyFilter>>;
  FilterItemsData: IObjectToIFiltersData;
}
const FilterListSelect: React.FC<IFilterListSelect> = ({
  amount,
  setFilterData,
  currentFilterEnum,
  FilterItemsData,
}) => {
  const [focusedFilter, setFocusedFilter] = useState(true);
  const TestSVG: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  > = FilterItemsData[currentFilterEnum].icon;
  const { cssColorMethod } = FilterItemsData[currentFilterEnum];
  const cssSelectedClass =
    cssColorMethod === EFilterColorMethod.STROKE
      ? "icon-selected-stroke"
      : "icon-selected-fill";
  return (
    <div className="filter-small">
      {focusedFilter ? (
        <button
          className="filter-container"
          onClick={() => {
            setFocusedFilter(false);
          }}
          type="button"
        >
          <div className="flex-with-space-between">
            <div className="center-align-as-row">
              <TestSVG
                title={FilterItemsData[currentFilterEnum].title}
                className={`icon icon-selected ${cssSelectedClass}`}
              />
              <div className="sub-menu filter-name-selected sub-menu-selected">
                {FilterItemsData[currentFilterEnum].name}
              </div>
            </div>
            <div className="center-align-as-row">
              <div className="menu-light menu filter-number filter-number-selected">
                {amount[currentFilterEnum]}
              </div>
              <Arrow title="Expand" className="arrow-down" />
            </div>
          </div>
        </button>
      ) : (
        <div>
          {Object.keys(FilterItemsData).map((key) => {
            return (
              <FilterItem
                key={FilterItemsData[key].name}
                selected={
                  currentFilterEnum === FilterItemsData[key].filterTypeEnum
                }
                amount={amount[key]}
                name={FilterItemsData[key].name}
                setFilterData={setFilterData}
                Icon={FilterItemsData[key].icon}
                title={FilterItemsData[key].title}
                filterMethod={FilterItemsData[key].filterTypeEnum}
                focusedFilter={focusedFilter}
                setFocusedFilter={setFocusedFilter}
                cssColorMethod={FilterItemsData[key].cssColorMethod}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FilterListSelect;
