import {
  ERegistrationError,
  IActionRegistration,
  IRegistrationJWT,
  IRegistrationQueryParams,
  IRegistrationTenantAccountFormData,
  IUserRegistrationData,
} from "../../interfaces/interfaces";
import {
  BIRTHDATE_FORMAT_INVALID,
  BIRTHDATE_INVALID,
  CLEAR_REGISTRATION_ERROR,
  CREATE_ACCOUNT_SUCCESS,
  CREATE_USER_SUCCESS,
  EMAIL_VERIFICATION_SENT,
  EMAIL_VERIFICATION_SUCCESS,
  EXPIRED_INVITATION,
  FAMILY_NAME_INVALID,
  GET_QUERY_PARAMS,
  GIVEN_NAME_INVALID,
  INVITATION_NOT_FOUND,
  PASSWORD_INVALID,
  PASSWORD_MISMATCH_REGISTRATION_ERROR,
  VERIFICATION_CODE_FAILURE,
  VERIFICATION_RATE_LIMITED,
} from "../../actions/constants";

export default (
  state = {
    accountCompleted: false,
    birthdate: "",
    buildingName: "",
    configHomeName: "",
    email: "",
    emailJWT: "",
    emailVerified: false,
    error: ERegistrationError.noError,
    familyName: "",
    givenName: "",
    jwt: "",
    propertyId: "",
    propertyName: "",
    userId: "",
  },
  action: IActionRegistration
): IUserRegistrationData => {
  switch (action.type) {
    case CLEAR_REGISTRATION_ERROR: {
      return {
        ...state,
        error: ERegistrationError.noError,
      };
    }
    case PASSWORD_MISMATCH_REGISTRATION_ERROR: {
      return {
        ...state,
        error: ERegistrationError.passwordMismatch,
      };
    }
    case EMAIL_VERIFICATION_SENT: {
      const payload = action.payload as IRegistrationJWT;

      return {
        ...state,
        jwt: payload.jwt,
      };
    }
    case EMAIL_VERIFICATION_SUCCESS: {
      const payload = action.payload as IUserRegistrationData;

      return {
        ...state,
        emailVerified: true,
        jwt: payload.jwt,
        userId: payload.userId,
      };
    }
    case CREATE_ACCOUNT_SUCCESS: {
      const payload = action.payload as IUserRegistrationData;
      return {
        ...state,
        accountCompleted: true,
        buildingName: payload.buildingName,
        configHomeName: payload.configHomeName,
        propertyName: payload.propertyName,
      };
    }
    case GET_QUERY_PARAMS: {
      const payload = action.payload as IRegistrationQueryParams;

      return {
        ...state,
        email: payload.email,
        emailJWT: payload.jwt,
        propertyId: payload.propertyId,
      };
    }
    case CREATE_USER_SUCCESS: {
      const payload = action.payload as IRegistrationTenantAccountFormData;
      let accountCompleted = false;
      if (!payload.jwt) {
        accountCompleted = true;
      }

      return {
        ...state,
        accountCompleted,
        birthdate: payload.birthdate,
        error: ERegistrationError.noError,
        familyName: payload.familyName,
        givenName: payload.givenName,
        jwt: payload.jwt,
        userId: payload.userId,
      };
    }
    case EXPIRED_INVITATION: {
      return {
        ...state,
        error: ERegistrationError.expiredInvitation,
      };
    }
    case INVITATION_NOT_FOUND: {
      return {
        ...state,
        error: ERegistrationError.invalidInvitation,
      };
    }
    case VERIFICATION_CODE_FAILURE: {
      return {
        ...state,
        error: ERegistrationError.isWrongTenantCode,
      };
    }
    case VERIFICATION_RATE_LIMITED: {
      return {
        ...state,
        error: ERegistrationError.verificationRateLimited,
      };
    }
    case BIRTHDATE_FORMAT_INVALID: {
      return {
        ...state,
        error: ERegistrationError.birthdateFormatInvalid,
      };
    }
    case BIRTHDATE_INVALID: {
      return {
        ...state,
        error: ERegistrationError.birthdateInvalid,
      };
    }
    case FAMILY_NAME_INVALID: {
      return {
        ...state,
        error: ERegistrationError.familyNameInvalid,
      };
    }
    case GIVEN_NAME_INVALID: {
      return {
        ...state,
        error: ERegistrationError.givenNameInvalid,
      };
    }
    case PASSWORD_INVALID: {
      return {
        ...state,
        error: ERegistrationError.passwordInvalid,
      };
    }
    default:
      return state;
  }
};
