import { Link } from "react-router-dom";

import { ReactComponent as Enter } from "../../img/Enter.svg";
import { IPropertyData } from "../../interfaces/interfaces";

const BuildingCard: React.FC<{ property: IPropertyData }> = ({ property }) => {
  return (
    <Link to={`/buildings/${property.id}`} className="property-container">
      <div key={property.id} className="property-container-layout">
        <div className="property-container-text">
          <div className="unit-title hidden-name">{property.propertyName}</div>
          <div className="unit-description">
            {`${property.addressLine1}, ${property.city}, ${property.state} ${property.zipcode}`}
          </div>
        </div>
        <Enter title="Enter" className="property-enter" />
      </div>
    </Link>
  );
};

export default BuildingCard;
