import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { ReactComponent as RemoteLock } from "../../img/logos/RemoteLock.svg";
import { isRemoteLockPolling } from "../apollo/LocalState";
import { IAppState } from "../../interfaces/interfaces";
import { openExternalPopup } from "../../utils/integrationUtils";
import BaseModal from "../modals/BaseModal";

export interface RemoteLockLogInModalProps {
  closeLogInModal: () => void;
  setAttemptingLoginFn: (isAttemptingLogin: boolean) => void;
  startPolling: (pollInterval: number) => void;
  organizationId: string;
}

const RemoteLockLogInModal: React.FC<RemoteLockLogInModalProps> = ({
  closeLogInModal,
  setAttemptingLoginFn,
  startPolling,
  organizationId,
}) => {
  const [externalPopup, setExternalPopup] = useState<any>(null);

  useEffect(() => {
    if (!externalPopup) {
      return;
    }
    const timer = setInterval(() => {
      if (!externalPopup) {
        clearInterval(timer);
        return;
      }
      // When external popup is closed we close LoginModal component and start polling
      // on GetIntegrationsForOrganization every second by setting isRemoteLockPolling to true.
      if (externalPopup.closed) {
        closeLogInModal();
        isRemoteLockPolling(true);
        setExternalPopup(null);
        clearInterval(timer);
        startPolling(500);
      }
    }, 500);
  }, [externalPopup]);

  const startRemoteLockLogin = () => {
    openExternalPopup(organizationId, setExternalPopup);
    setAttemptingLoginFn(true);
  };

  return (
    <BaseModal
      actionButtonFn={startRemoteLockLogin}
      actionButtonText="Log in"
      closeModalFn={closeLogInModal}
      headerText="Add Integration"
    >
      <RemoteLock title="RemoteLock" className="remote-lock-logo" />
      <div className="menu menu-light remote-lock-login-body-text">
        Log in to your RemoteLock account to link your access devices to
        Brilliant.
      </div>
    </BaseModal>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    organizationId: state.user.organizationId,
  };
};

export default connect(mapStateToProps)(RemoteLockLogInModal);
