import { useDispatch } from "react-redux";
import { useQuery, useReactiveVar } from "@apollo/client";
import { useEffect } from "react";

import { removeResident, resetHome } from "../../../actions";
import {
  EReconfigurationError,
  EUserManagementGenericError,
  IPropertyIdVariable,
  IShowResidentsData,
  IUnitDetailsData,
} from "../../../interfaces/interfaces";
import {
  reconfigurationErrorVar,
  selectedUserToShowVar,
  userManagementGenericErrorVar,
} from "../../apollo/LocalState";
import { GET_HOME, GET_RESIDENT_INFO } from "../../../api/gqlQueries";
import { renderUnitDetailsModalContent } from "../../../utils/utils";
import { SelectedTableEntry } from "../../tables/UsersTables";
import Spinner from "../../Spinner";
import {
  renderInstallIncompleteAlert,
  renderTenantModalGenericError,
  renderTenantModalReconfigurationError,
} from "../common/tenant_modal_common_views";
import RedAlertMessage from "../../common/RedAlertMessage";

export interface TenantMoveOutProps {
  decreaseCurrentStep: () => void;
  closeRemoveTenantModal: () => void;
  increaseCurrentStep: () => void;
  propertyId: string;
  homeId: string;
}

const renderActionBtnText = ({
  isLastResident,
}: {
  isLastResident: boolean;
}) => {
  if (isLastResident) {
    return "Confirm Move Out & Removal";
  }
  return "Confirm Removal";
};

const renderNewBody = (
  isLastResident: boolean,
  isInstallationIncomplete: boolean
) => {
  return (
    <>
      <div
        className="tenants-show-description-container"
        data-testid="remove-tenant-step-one-container"
      >
        {renderUnitDetailsModalContent({
          descriptionList: [
            "The resident will lose access to the unit's Brilliant home and its devices.",
            "The resident will be notified that they have been removed by email.",
          ],
          title: "If you remove the following resident:",
        })}
      </div>
      <div className="tenants-show-description-body">
        <SelectedTableEntry />
      </div>
      {isInstallationIncomplete &&
        renderInstallIncompleteAlert({ isMoveIn: false })}
      {isLastResident && (
        <RedAlertMessage>
          {renderUnitDetailsModalContent({
            centerAlignHeaderForSmallScreen: true,
            descriptionList: [
              "The unit's state will change to vacant and all resident information will be removed from the home.",
              "The unit's Brilliant home will be reconfigured with the default settings for vacant units.",
            ],
            title: "Removing the only resident will also start a move out.",
          })}
        </RedAlertMessage>
      )}
    </>
  );
};

const TenantMoveOut: React.FC<TenantMoveOutProps> = ({
  decreaseCurrentStep,
  increaseCurrentStep,
  closeRemoveTenantModal,
  propertyId,
  homeId,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      reconfigurationErrorVar(EReconfigurationError.noError);
    };
  }, []);
  const reconfigurationError = useReactiveVar(reconfigurationErrorVar);
  const selectedUserToShow = useReactiveVar(selectedUserToShowVar);
  const genericError = useReactiveVar(userManagementGenericErrorVar);
  const { loading, data } = useQuery<IShowResidentsData, IPropertyIdVariable>(
    GET_RESIDENT_INFO,
    {
      variables: { propertyId },
    }
  );
  // By this point, the home query should have been made so we just need to read from cache.
  const { data: homeData } = useQuery<IUnitDetailsData, IPropertyIdVariable>(
    GET_HOME,
    {
      variables: { propertyId },
    }
  );
  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    throw Error("No data was returned");
  }
  const isLastResident = data.home.residents.length === 1;
  const isInstallationIncomplete = homeData?.home.isInstallIncomplete ?? false;
  return (
    <div className="tenants-show-body">
      {renderNewBody(isLastResident, isInstallationIncomplete)}
      {reconfigurationError !== EReconfigurationError.noError &&
        renderTenantModalReconfigurationError({ reconfigurationError })}
      {genericError !== EUserManagementGenericError.noError &&
        renderTenantModalGenericError({ genericError })}
      <div className="invite-tenant-modal-actions margin-top-small">
        <button
          type="button"
          className="tertiary-btn-small tertiary-btn-small-alert"
          onClick={() => {
            decreaseCurrentStep();
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          data-testid="remove-registered-tenant-btn"
          className="tertiary-btn-small tertiary-btn-small-main tertiary-btn-small-main-alert invite-tenant-modal-actions-submit"
          onClick={() => {
            if (isLastResident) {
              dispatch(
                resetHome({
                  increaseCurrentStep,
                  propertyId,
                })
              );
            } else if (selectedUserToShow) {
              dispatch(
                removeResident({
                  closeRemoveTenantModal,
                  homeId,
                  userId: selectedUserToShow.id,
                })
              );
            }
          }}
        >
          {renderActionBtnText({
            isLastResident,
          })}
        </button>
      </div>
    </div>
  );
};

export default TenantMoveOut;
