import { AxiosResponse } from "axios";

import { brilliantUrl } from "./api";

export interface ITokenResponse {
  token: string;
}
// Disable camelcase here since these are the raw values we expect from the API.
/* eslint-disable camelcase */
export interface ITokenWithUserIdResponse {
  user_id: string;
  token: string;
}

export const postSendVerificationEmail = (
  email: string,
  token: string,
  force = false
): Promise<AxiosResponse<ITokenResponse>> =>
  brilliantUrl.post(`/emails/${email}/verify-admin-email`, { force, token });

export const postTenantVerification = (
  email: string,
  token: string,
  force = false
): Promise<AxiosResponse<ITokenResponse>> => {
  const params = { force, token };
  return brilliantUrl.post(
    `/emails/${email}/send-tenant-verification-email`,
    params
  );
};

export const postUserVerification = (
  email: string,
  token: string,
  force = false
): Promise<AxiosResponse<ITokenResponse>> => {
  const params = { force, token };
  return brilliantUrl.post(
    `/emails/${email}/send-user-verification-email`,
    params
  );
};

export const postVerifyUserEmail = (
  email: string,
  token: string,
  code: string
): Promise<AxiosResponse<ITokenWithUserIdResponse>> => {
  const params = { code, token };
  return brilliantUrl.post(`/emails/${email}/verify-user-email`, params);
};
