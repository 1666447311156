import { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { submit } from "redux-form";

import ConfigurationsButton from "../configurations/ConfigurationsButton";
import { ReactComponent as Close } from "../../img/Close.svg";

import Scheduler from "./Scheduler";

const MakeChangesModal: React.FC<{
  closeMakeChangesModal: () => void;
  propertyName: string;
  unitName: string;
  homeId: string;
  refetch: () => void;
}> = ({ closeMakeChangesModal, propertyName, unitName, homeId, refetch }) => {
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  return (
    <div className="popup-background">
      <div className="changes-container align-as-column">
        <div className="changes-header">
          <div className="unit-title">Make Changes</div>
          <Close
            onClick={() => closeMakeChangesModal()}
            className="changes-header-img"
            title="close"
          />
        </div>
        <Scheduler
          setDisabled={setDisabled}
          homeId={homeId}
          closeMakeChangesModal={closeMakeChangesModal}
          refetch={refetch}
        />
        <div className="changes-address-container menu">
          <div>To&nbsp;</div>
          <ConfigurationsButton buttonText={propertyName} canModify={false} />
          &nbsp;
          <ConfigurationsButton buttonText={unitName} canModify={false} />
        </div>
        <button
          type="button"
          disabled={disabled}
          className="primary-btn changes-btn"
          onClick={() => {
            dispatch(submit("selectTime"));
          }}
        >
          Run
        </button>
      </div>
    </div>
  );
};

export default connect()(MakeChangesModal);
