import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useState } from "react";

import { ReactComponent as ShowPassword } from "../../img/ShowPassword.svg";
import { ReactComponent as HidePassword } from "../../img/HidePassword.svg";
import Spinner from "../Spinner";
import { GET_HOME, UPDATE_WIFI_CREDENTIALS } from "../../api/gqlQueries";
import {
  IBackupWifiNetworkModal,
  INetworkTypeData,
} from "../../interfaces/interfaces";

const DropDownSelect: React.FC<{
  id: string;
  input: string;
  options: INetworkTypeData[];
  updateFieldFn: (value: string) => void;
}> = ({ id, input, options, updateFieldFn }) => {
  const renderSelectOptions = (option: INetworkTypeData) => (
    <option
      className="scheduler-select-option menu"
      key={option.id}
      value={option.id}
    >
      {option.title}
    </option>
  );
  return (
    <div className="network-type-select-container">
      <select
        onChange={(e) => updateFieldFn(e.target.value)}
        className="network-type-select menu tool-tip"
        value={input}
        data-testid={id}
      >
        {options.map(renderSelectOptions)}
      </select>
    </div>
  );
};

interface BackupWifiNetworkFormProps {
  closeModalFn: () => void;
  inputData: IBackupWifiNetworkModal;
}

const BackupWifiNetworkForm: React.FC<BackupWifiNetworkFormProps> = ({
  closeModalFn,
  inputData,
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      networkName: inputData.networkName,
      networkType: inputData.networkType,
      password: inputData.password,
      securityType: inputData.securityType,
    },
  });

  const [networkType, setWifiNetworkType] = useState(inputData.networkType);
  const [securityType, setWifiSecurityType] = useState(inputData.securityType);

  const [updateWifiCredentials, { loading }] = useMutation(
    UPDATE_WIFI_CREDENTIALS,
    {
      refetchQueries: [GET_HOME],
    }
  );

  const { propertyId } = inputData;
  const [showPassword, setPasswordShown] = useState(false);
  const [password, setPassword] = useState(inputData.password);
  const [networkName, setNetworkName] = useState(inputData.networkName);

  if (loading) return <Spinner />;

  return (
    <form
      onSubmit={handleSubmit((data) => {
        updateWifiCredentials({
          variables: {
            networkName: data.networkName,
            networkType,
            password: data.password,
            propertyId,
            securityType,
          },
        });
        closeModalFn();
      })}
    >
      <div className="backup-wifi-network-modal-section">
        <label htmlFor="networkNameField">Network Name</label>
        <input
          id="networkNameField"
          {...register("networkName", {
            onChange: (e) => {
              setNetworkName(e.target.value);
            },
          })}
          defaultValue={inputData.networkName}
          className="right-form-input"
        />
        <label htmlFor="passwordField">Password</label>
        <div className="show-password-container">
          <input
            id="passwordField"
            {...register("password", {
              onChange: (e) => {
                setPassword(e.target.value);
              },
            })}
            className="right-form-input"
            type={showPassword ? "text" : "password"}
            autoComplete="new-password"
          />
          <div className="show-password-button">
            {showPassword ? (
              <HidePassword
                onClick={() => setPasswordShown(false)}
                data-testid="hide_password_button"
              />
            ) : (
              <ShowPassword
                onClick={() => setPasswordShown(true)}
                data-testid="show_password_button"
              />
            )}
          </div>
        </div>
        <div className="network-type-fields">
          <div className="network-type-selection">
            <label htmlFor="networkTypeField">Network Type</label>
            <DropDownSelect
              id="networkTypeSelect"
              input={networkType}
              options={[
                {
                  description: "Visible Network",
                  id: "visible",
                  title: "Visible",
                },
                {
                  description: "Hidden Network",
                  id: "hidden",
                  title: "Hidden",
                },
              ]}
              updateFieldFn={(value) => {
                if (value === "hidden") {
                  setWifiSecurityType(inputData.securityType || "wpa/wpa2");
                }
                setWifiNetworkType(value);
              }}
            />
          </div>
          <div
            className="network-type-selection"
            hidden={networkType !== "hidden"}
          >
            <label htmlFor="securityTypeField">Security Type</label>
            <DropDownSelect
              id="securityTypeSelect"
              input={securityType}
              options={[
                {
                  description: "WPA/WPA2",
                  id: "wpa/wpa2",
                  title: "WPA / WPA2",
                },
                {
                  description: "WEP",
                  id: "wep",
                  title: "WEP",
                },
                {
                  description: "None",
                  id: "none",
                  title: "None",
                },
              ]}
              updateFieldFn={setWifiSecurityType}
            />
          </div>
        </div>
      </div>
      <div className="invite-tenant-modal-actions">
        <button
          type="button"
          className="invite-tenant-modal-cancel-btn tertiary-btn-small"
          onClick={closeModalFn}
        >
          Cancel
        </button>
        <button
          disabled={
            (password === inputData.password &&
              networkName === inputData.networkName &&
              securityType === inputData.securityType &&
              networkType === inputData.networkType) ||
            password === "" ||
            networkName === ""
          }
          data-testid="submit-btn"
          className="primary-btn tertiary-btn-small tertiary-btn-small-main invite-tenant-modal-actions-submit"
          type="submit"
        >
          Save Updates
        </button>
      </div>
    </form>
  );
};

export default BackupWifiNetworkForm;
