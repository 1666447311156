import { useState } from "react";
import { connect } from "react-redux";

import { IAppState } from "../../interfaces/interfaces";

import { renderRulesPlaceholder } from "./ConfigurationsDetails";
import ConfigurationsHeader from "./ConfigurationsHeader";
import ConfigurationsButton from "./ConfigurationsButton";
import ConfirmWindow from "./ConfirmWindow";

export interface UnitDetailsViewProps {
  homeId: string;
  title: string;
  createdDate: string;
  date: string;
  time: string;
  buildingName: string;
  unitName: string;
}

const UnitDetailsView: React.FC<UnitDetailsViewProps> = ({
  homeId,
  title,
  createdDate,
  date,
  time,
  buildingName,
  unitName,
}) => {
  const [showDeletionWindow, setDeletionWindow] = useState(false);
  return (
    <>
      <div className="popup-background">
        <div className="center-align-as-column">
          <div className="configuration-details-container">
            <ConfigurationsHeader
              popup
              title={title}
              createdDate={createdDate}
            />
            <div className="configuration-details-rules-popup-container">
              {renderRulesPlaceholder(title, true)}
              <div className="configuration-details-rules-name menu margin-top-small">
                When
              </div>
              <div className="configuration-rule-container configuration-rule-container-popup center-align-as-row menu menu-light">
                Should start on&nbsp;
                <ConfigurationsButton buttonText={date} canModify={false} />
                &nbsp;at&nbsp;
                <ConfigurationsButton buttonText={time} canModify={false} />
              </div>
              <div className="configuration-details-rules-name menu margin-top-small">
                Where
              </div>
              <div className="configuration-rule-container configuration-rule-container-popup center-align-as-row menu menu-light">
                To the&nbsp;
                <ConfigurationsButton
                  buttonText={buildingName}
                  canModify={false}
                />
                &nbsp;at&nbsp;
                <ConfigurationsButton buttonText={unitName} canModify={false} />
              </div>
              <div className="configuration-popup-btn-container">
                <button
                  onClick={() => setDeletionWindow(true)}
                  type="button"
                  className="configuration-popup-btn secondary-btn"
                >
                  Cancel Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showDeletionWindow && (
        <ConfirmWindow
          homeId={homeId}
          buildingName={buildingName}
          unitName={unitName}
          cancelDeletion={() => setDeletionWindow(false)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    createdDate: state.selectedScheduledConfiguration.createdDate,
    date: state.selectedScheduledConfiguration.date,
    time: state.selectedScheduledConfiguration.time,
    title: state.selectedScheduledConfiguration.title,
  };
};

export default connect(mapStateToProps)(UnitDetailsView);
