import { IObjectIndexType } from "../interfaces/interfaces";

export const reconfigurationErrorMessage: IObjectIndexType = {
  reconfigurationFailed:
    "A pending reconfiguration has failed for this unit. You must contact Brilliant to resolve this issue before continuing.",
  reconfigurationInProgress:
    "This unit is currently being reconfigured. Please wait for the reconfiguration to complete and try again.",
};

export const userManagementGenericErrorMessages: IObjectIndexType = {
  invalidDomain:
    "Invite could not be sent because the domain does not exist.  Please try again with a valid domain.",
  somethingWentWrong:
    "Something went wrong, please try again later. If the issue persists, contact Brilliant.",
};
