import Tag from "./Tag";

export interface DialogTagsProps {
  names: string[];
  tagsType: string;
}

const DialogTags: React.FC<DialogTagsProps> = ({ names, tagsType }) => {
  return (
    <div className="dialog-tags-container">
      <div className="dialog-tags-container-inner">
        {names.map((name) => {
          return (
            <div key={name} className="dialog-tags">
              <Tag name={name} tagType={tagsType} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DialogTags;
