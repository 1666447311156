interface IconProps {
  title?: string;
}

const Menu: React.FC<{
  icon: React.FC<React.SVGProps<SVGSVGElement> & IconProps>;
  amount: number;
  name: string;
  singular: string;
}> = ({ icon: Icon, amount, name, singular }) => {
  return (
    <div className="dashboard-setting">
      <Icon className="dashboard-setting-img" title={name} />
      <div className="dashboard-setting-details">
        <div className={`unit-title ${amount === 0 && "alert"}`}>{amount}</div>
        <div className="unit-description">{amount === 1 ? singular : name}</div>
      </div>
    </div>
  );
};

export default Menu;
